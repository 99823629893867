import { IntuUserList } from '../../components/admin/IntuUserList';
import { useTranslation } from 'react-i18next';
import IntuPage from '../../components/layout/IntuPage';

const IntuUsers = () => {
  const { t } = useTranslation();
  return (
    <IntuPage
      pageTitle={t('pages.admin.intuUsers.title')}
      pageDescription={t('pages.admin.intuUsers.description')}
      hCentered
    >
      <IntuUserList />
    </IntuPage>
  );
};

export default IntuUsers;
