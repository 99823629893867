import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import Globe from 'react-globe.gl';
import countryJSON from './countries.geojson';
import cityJSON from './cities.geojson';

const originCities = [
  [9.1824, 45.4685],
  [2.1686, 41.3874],
  [4.4777, 51.9244],
  [11.582, 48.1351],
];

const destinationCities = [
  [-74.006, 40.7128, 'na'],
  [-118.2426, 34.0549, 'na'],
  [-84.512, 39.1031, 'na'],
  [-95.3701, 29.7601, 'na'],
  [-104.9903, 39.7392, 'na'],
  [-122.3328, 47.6061, 'na'],
  [-97.5164, 35.4676, 'na'],
  [-104.6404, 35.0844, 'na'],
  [-79.3832, 43.6523, 'na'],
  [-113.4937, 53.5461, 'na'],
  [-99.1332, 19.4326, 'na'],
  [139.65, 35.6764, 'japan'],
  [126.9918, 37.5519, 'korea'],
  [121.5654, 25.033, 'taiwan'],
  [114.0596, 22.5429, 'china'],
  [55.2708, 25.2048, 'me'],
  [-46.6396, -23.5558, 'sa'],
  [-58.3816, -34.6037, 'sa'],
  [-70.6693, -33.4489, 'sa'],
  [72.8777, 19.076, 'india'],
  [103.8198, 1.3521, 'sea'],
];

function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

export const GlobeHero = ({ children }) => {
  const globeRef = useRef(null);
  const globeContainerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [countries, setCountries] = useState({ features: [] });
  const [places, setPlaces] = useState([]);

  const shuffledCities = useMemo(
    () => shuffleArray(destinationCities),
    [destinationCities],
  );

  // Fetch country and city data
  useEffect(() => {
    fetch(countryJSON)
      .then((res) => res.json())
      .then(setCountries);

    fetch(cityJSON)
      .then((res) => res.json())
      .then(({ features }) => setPlaces(features));
  }, []);

  // Define arcs connecting cities
  const arcs = useMemo(() => {
    const colors = ['#E06A58', '#588AE0', '#57C9B1', '#C9B157'];
    let cityIndex = 0;
    const allArcs = [];

    originCities.forEach((city, index) => {
      for (let i = 0; i < 6; i++) {
        const targetCityIndex = cityIndex % shuffledCities.length;
        allArcs.push({
          startLat: city[1],
          startLng: city[0],
          endLat: shuffledCities[targetCityIndex][1],
          endLng: shuffledCities[targetCityIndex][0],
          color: colors[index % colors.length],
          dashAnimateTime: 2000,
          initialDashGap: 0,
        });
        cityIndex++;
      }
    });
    return allArcs;
  }, [shuffledCities]);

  // Update dimensions and camera on window resize/orientation change
  useEffect(() => {
    const updateDimensions = () => {
      const { offsetWidth, offsetHeight } = globeContainerRef.current;
      setDimensions({ width: offsetWidth, height: offsetHeight });

      const camera = globeRef.current.camera();
      camera.aspect = offsetWidth / offsetHeight;
      camera.updateProjectionMatrix();

      const renderer = globeRef.current.renderer();
      renderer.setSize(offsetWidth, offsetHeight);
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('orientationchange', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('orientationchange', updateDimensions);
    };
  }, []);

  // Initial globe settings and camera POV
  useEffect(() => {
    const controls = globeRef.current.controls();
    controls.autoRotate = true;
    controls.enableZoom = false;

    globeRef.current.pointOfView(
      {
        lat: 30,
        lng: -40,
        altitude: 2.5,
      },
      0,
    );
  }, []);

  return (
    <div
      ref={globeContainerRef}
      style={{
        width: '100%',
        height: '100vh',
        alignContent: 'center',
      }}
    >
      <Globe
        ref={globeRef}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
        arcsData={arcs}
        arcColor="color"
        arcDashAnimateTime={(d) => d.dashAnimateTime}
        arcTransitionDuration={2000}
        arcDashLength={2}
        arcDashGap={3.5}
        arcAltitudeAutoScale={0.5}
        arcStroke={0.3}
        arcDashInitialGap={(d) => d.initialDashGap}
        hexPolygonsData={countries.features}
        hexPolygonGeoJsonGeometry={(d) => d.geometry}
        hexPolygonColor={useCallback(() => '#A8C957', [])}
        hexPolygonResolution={useCallback(() => 3, [])}
        hexPolygonMargin={useCallback(() => 0.7, [])}
        hexPolygonUseDots={true}
        labelsData={places}
        labelLat={(d) => d.geometry.coordinates[1]}
        labelLng={(d) => d.geometry.coordinates[0]}
        labelText={(d) => d.properties.name}
        labelSize={0}
        labelDotRadius={0.5}
        labelColor={() => '#A857C9'}
        labelAltitude={0.0075}
        labelIncludeDot={true}
        width={dimensions.width}
        height={dimensions.height}
        rendererConfig={{ antialias: false }}
      />
      {children}
    </div>
  );
};
