import { useEffect, useContext } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { LayoutBodyContext } from '../../context/providers/LayoutContextProvider';

function DashboardLayoutComponent({
  dashboardTitle, // dashboardTitle
  dashboardDescription, // Page Description
  hCentered, // Horizontal center true/false (Boolean)
  vCentered, // Vertical center true/false (Boolean)
  children, // Actual Content as chidlren (React.Element)
}) {
  const { setLayoutTitle, setLayoutDescription } =
    useContext(LayoutBodyContext);

  // Set the Page Title
  useEffect(() => {
    setLayoutTitle(dashboardTitle);
    setLayoutDescription(dashboardDescription);
  }, [dashboardTitle, dashboardDescription]);

  const alignItems = hCentered ? 'center' : 'flex-start';
  const alignText = hCentered ? 'center' : 'left';
  const justifyContent = vCentered ? 'center' : 'flex-start';

  return (
    <Container maxWidth="xxl" sx={{ margin: '10rem 0 0 0', p: 0 }}>
      <Grid
        container
        direction="column"
        alignItems={alignItems}
        justifyContent={justifyContent}
        sx={{ minHeight: '95vh', width: '100%' }}
      >
        <Grid item className="title" xs={12} pb="3rem">
          {dashboardTitle && (
            <Typography variant="h1" textAlign={alignText}>
              {dashboardTitle}{' '}
            </Typography>
          )}
          {dashboardDescription && (
            <Typography variant="h5" textAlign={alignText}>
              {dashboardDescription}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ width: '100%' }}
          container
          direction="column-reverse"
          justifyContent={justifyContent}
          alignItems={alignItems}
        >
          {children}
        </Grid>
      </Grid>
    </Container>
  );
}

export default DashboardLayoutComponent;
