import { useRef, useContext, useState } from 'react';
import { Formik, Form } from 'formik';

// Materail UI
import { Stack, FormControl, Grid, Button } from '@mui/material';
import { classes } from '../../settings/theme.js';

// Import User Context
import useAuth from '../../hooks/useAuth.js';
import { useTranslation } from 'react-i18next';

import ProductTypeField from '../../components/product-type/ProductTypeField.jsx';
import ApplicationsField from '../../components/applications/ApplicationsField.jsx';
import IndividualIntroField from '../../components/account/individual/IndividualIntroField.jsx';
import { createUserProfileValidationSchema } from './ValidationSchema.js';
import { UserProfileContext } from '../providers/UserProfileProvider.jsx';
import EmailField from '../../components/account/fields/email/EmailField.jsx';
import IndividualFirstNameField from '../../components/account/individual/IndividualFirstNameField.jsx';
import IndividualLastNameField from '../../components/account/individual/IndividualLastNameField.jsx';
import AccountTypeField from '../../components/account/fields/type/AccountTypeField.jsx';
import IndividualCompanyNameField from '../../components/account/individual/IndividualCompanyNameField.jsx';
import IndividualDOBField from '../../components/account/individual/IndividualDOBField.jsx';
import IndividualMobileNumberField from '../../components/account/individual/IndividualMobileNumberField.jsx';
import ChangeUserEmailDialog from '../../components/account/changeEmail/ChangeUserEmailDialog.jsx';
import ChageUserPasswordDialog from '../../components/account/changePassword/ChangeUserPasswordDialog.jsx';
import UserProfilePicture from '../../components/account/individual/IndividualProfilePicture.jsx';
import TwoFactorVerificationField from '../../components/account/two-factor-authentication/TwoFactorVerificationField.jsx';
import { TwoFactorVerificationContext } from '../providers/TwoFactorVerificationContextProvider.jsx';
import IndividualProfileField from '../../components/account/individual/IndividualProfileField.jsx';
import IndividualLanguageField from '../../components/account/individual/IndividualLanguageField.jsx';
import AddressField from '../../components/address/AddressField.jsx';
import PhoneField from '../../components/account/PhoneField .jsx';
import { NewCompanyFormDialog } from '../company/newCompany/NewCompanyFormDialog.jsx';
import { useNavigate } from 'react-router-dom';

// FORM CONTENT
const UserProfileForm = () => {
  const { setTwoFactorVerificationDialog } = useContext(
    TwoFactorVerificationContext,
  );

  // Translator
  const { t } = useTranslation();
  const { t: transTypes } = useTranslation('types');
  const { auth } = useAuth();
  const navigate = useNavigate();

  const {
    userProfile,
    updateUserProfile,
    setChangeEmailDialog,
    setChangePasswordDialog,
  } = useContext(UserProfileContext);

  const userProfileValidationSchema = createUserProfileValidationSchema(t);

  const [isReinitialize, setIsReinitialize] = useState(true);

  // Set Up Form refs
  const formRef = useRef(null);

  return (
    <Grid item className="content" xs={12} sx={{ marginBottom: '8rem' }}>
      <Formik
        values={userProfile}
        initialValues={userProfile}
        validationSchema={userProfileValidationSchema}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={isReinitialize}
        innerRef={formRef}
      >
        {(formik) => {
          const {
            initialValues,
            values,
            setFieldValue,
            resetForm,
            setTouched,
            isValid,
            isSubmitting,
            errors,
            touched,
          } = formik;
          return (
            <Form className="form-horizontal intu__form" role="form">
              <FormControl fullWidth>
                <Stack
                  alignItems="stretch"
                  spacing={4}
                  style={classes.root}
                  sx={{ paddingBottom: '30px' }}
                >
                  <Grid
                    container
                    variant="mobileWidthFix"
                    spacing={{ xs: 10, sm: 8, md: 1 }}
                    justifyContent="center"
                    direction={{
                      xs: 'column',
                      sm: 'column',
                      md: 'row',
                    }}
                  >
                    <Grid
                      variant="mobileAlignFix"
                      container
                      item
                      xs={3}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingLeft: 0 }}
                    >
                      {/* Profile Picture  */}
                      <UserProfilePicture
                        src={userProfile?.profile_picture_view_url}
                        initials={userProfile?.initials}
                        setReinitialize={setIsReinitialize}
                      />
                    </Grid>
                    <Grid variant="mobileAlignFix" item sm={12} md={9}>
                      <Stack
                        spacing={{ xs: 3, sm: 3, md: 3 }}
                        direction={{ xs: 'column' }}
                        style={classes.root}
                        alignItems="stretch"
                      >
                        {/* Personal Details */}
                        <Stack
                          spacing={{ xs: 3, sm: 3, md: 1 }}
                          direction={{
                            xs: 'column',
                            sm: 'column',
                            md: 'row',
                          }}
                          style={classes.root}
                          alignItems="stretch"
                        >
                          {/* First Name */}
                          <IndividualFirstNameField
                            required={true}
                            fieldID="first_name"
                          />

                          {/* Last Name */}
                          <IndividualLastNameField
                            required={true}
                            fieldID="last_name"
                          />
                        </Stack>

                        {/* DOB */}
                        <IndividualDOBField required={true} fieldID="dob" />

                        <Stack
                          spacing={{ xs: 3, sm: 3, md: 1 }}
                          direction={{
                            xs: 'column',
                            sm: 'column',
                            md: 'row',
                          }}
                          style={classes.root}
                          alignItems="stretch"
                        >
                          <Button
                            onClick={() => {
                              if (
                                auth?.user_info?.auth_method?.provider &&
                                auth?.user_info?.auth_method?.provider !==
                                'basic'
                              ) {
                                // Open User Change Dialog for Basic Authentication
                                setChangePasswordDialog(true);
                              }
                              // Redirect User to his her Autnentication Provider's Profile
                              // Check if it's an external URL
                              else if (
                                auth?.user_info?.auth_method.url.startsWith(
                                  'http',
                                )
                              ) {
                                window.open(
                                  auth?.user_info?.auth_method.url,
                                  '_blank',
                                );
                              }
                              // If it's an internal route, use navigate or Link
                              else {
                                navigate(auth?.user_info?.auth_method.url);
                              }
                            }}
                            color="error"
                          >
                            {t('buttons.change_pw')}
                          </Button>

                          {/* Change User Password */}
                          <ChageUserPasswordDialog />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>

                  {/* About Me */}
                  <IndividualIntroField
                    fieldTitle={t(
                      'context.users.UserProfileForm.form.intro.title',
                      { type: t('') },
                    )}
                    fieldDescription={t(
                      'context.users.UserProfileForm.form.intro.description',
                      { type: transTypes('yourself') },
                    )}
                    showIntroAssistant={!isSubmitting}
                  />

                  {/* Personal Address */}
                  <AddressField
                    fieldTitle={t(
                      'context.users.UserProfileForm.form.address.title',
                    )}
                    fieldDescription={t(
                      'context.users.UserProfileForm.form.address.description',
                    )}
                    fieldID="address"
                    autocompleteID="address"
                    transNS="fields"
                    transPrefix="AddressField"
                    required={true}
                  />

                  {/* Personal Phone Number */}
                  <PhoneField
                    fieldTitle={t(
                      'context.users.UserProfileForm.form.phone.title',
                    )}
                    fieldDescription={t(
                      'context.users.UserProfileForm.form.phone.description',
                    )}
                    fieldID="phone"
                    transNS="fields"
                    transPrefix="account.individual.PhoneField"
                    required={true}
                  />

                  {/* Mobile Phone */}
                  <IndividualMobileNumberField
                    fieldTitle={t(
                      'context.users.UserProfileForm.form.mobile.title',
                    )}
                    fieldDescription={t(
                      'context.users.UserProfileForm.form.mobile.description',
                    )}
                    fieldID="mobile"
                    disabled={true}
                  />

                  <Grid item xs={4}>
                    <Button
                      onClick={() => {
                        setFieldValue('mobile_verified.accepted', false);
                        setTwoFactorVerificationDialog(true);
                      }}
                      color="warning"
                    >
                      {t('context.users.UserProfileForm.buttons.change_number')}
                    </Button>
                  </Grid>

                  <TwoFactorVerificationField
                    dialog={true}
                    fieldTitle={t(
                      'context.users.UserProfileForm.form.2fa.title',
                    )}
                    fieldDescription={t(
                      'context.users.UserProfileForm.form.2fa.description',
                    )}
                    fieldID="mobile"
                    transNS="fields"
                    transPrefix="TwoFactorauthenticationField"
                    required={true}
                  />

                  {/* User Email */}
                  <EmailField
                    fieldTitle={t(
                      'context.users.UserProfileForm.form.email.title',
                    )}
                    fieldDescription={t(
                      'context.users.UserProfileForm.form.email.description',
                    )}
                    fieldID="email"
                    required={true}
                    disabled={true}
                    transNS="fields"
                    transPrefix="account.individual.EmailField"
                  />

                  <Grid item xs={4}>
                    <Button
                      onClick={() => {
                        setChangeEmailDialog(true);
                      }}
                      color="warning"
                    >
                      {t('context.users.UserProfileForm.buttons.change_email')}
                    </Button>
                  </Grid>

                  {/* User Profile Link */}
                  <IndividualProfileField
                    fieldTitle={t(
                      'context.users.UserProfileForm.form.profile.title',
                    )}
                    fieldDescription={t(
                      'context.users.UserProfileForm.form.profile.description',
                    )}
                    fieldID="profile_link"
                    transNS="fields"
                    required={true}
                    disabled={false}
                    showLinkedInButton={true}
                  />

                  {/* Language Field */}
                  <IndividualLanguageField
                    fieldTitle={t(
                      'context.users.UserProfileForm.form.languages.title',
                    )}
                    fieldDescription={t(
                      'context.users.UserProfileForm.form.languages.description',
                    )}
                    fieldID="languages"
                    required={true}
                    showInitialErrors={true}
                  />

                  {/* Change User Email */}
                  <ChangeUserEmailDialog />

                  {/* User Company Association */}
                  <IndividualCompanyNameField
                    fieldTitle={t(
                      'context.users.UserProfileForm.form.company.title',
                    )}
                    fieldDescription={t(
                      'context.users.UserProfileForm.form.company.description',
                    )}
                    required={auth?.user_info?.account_type === 'manufacturer'}
                    disabled={true}
                  />

                  {/* Hide Profile Settings from IN2TEC Employees */}
                  {values?.account_type !== 'intutec' && (
                    <>
                      {/* Applications*/}
                      <ApplicationsField
                        fieldTitle={t(
                          'context.users.UserProfileForm.form.applications.title',
                        )}
                        fieldDescription={t(
                          'context.users.UserProfileForm.form.applications.description',
                        )}
                        required={true}
                      />

                      {/* Products */}
                      <ProductTypeField
                        fieldTitle={t(
                          'context.users.UserProfileForm.form.products.title',
                        )}
                        fieldDescription={t(
                          'context.users.UserProfileForm.form.products.description',
                        )}
                        required={true}
                      />
                    </>
                  )}
                  {/* Account Type */}
                  {auth?.user_info?.account_type && (
                    <AccountTypeField
                      fieldTitle={t(
                        'context.users.UserProfileForm.form.account_type.title',
                      )}
                      fieldDescription={t(
                        'context.users.UserProfileForm.form.account_type.description',
                      )}
                      required={true}
                      disabled={true}
                    />
                  )}
                </Stack>

                {/* Submit Form */}
                {Object.keys(touched).length ? (
                  <Grid container>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="warning"
                        disabled={touched.length === 0 ? true : false}
                        onClick={(e) => {
                          resetForm();
                        }}
                        sx={{ mr: 1 }}
                      >
                        {t('context.users.UserProfileForm.buttons.cancel')}
                      </Button>
                    </Grid>

                    <Grid item sx={{ marginLeft: 'auto' }}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        disabled={!isValid}
                        onClick={() => {
                          const update = updateUserProfile(values);
                          if (update.status === 'error ') {
                            resetForm();
                          } else {
                            setTouched({}, false);
                          }
                        }}
                      >
                        {t('context.users.UserProfileForm.buttons.update')}
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
                <NewCompanyFormDialog
                  info={{ name: values?.individual?.company?.name }}
                />
              </FormControl>

              {/* <h1>Initial Values:</h1>
                {JSON.stringify(initialValues)} */}

              {/* <h1>Values:</h1>
                {JSON.stringify(values)} */}

              {/* <h1>Touched:</h1>
                {JSON.stringify(touched)} */}

              {/* <h1>Errors:</h1>
              {JSON.stringify(errors)} */}

              {/* <h1>Valid:</h1>
                {JSON.stringify(isValid)} */}

              {/* <h1>User Info</h1>
                {JSON.stringify(userProfile)} */}

              {/* <h1>Company Info:</h1>
                {JSON.stringify(companyInfo)} */}
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export { UserProfileForm };
