import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  getProductById,
  getProducRepresentativesById,
} from '../../routes/productsRoutes';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { grey } from '@mui/material/colors';
import FaceIcon from '@mui/icons-material/Face';
import MessageIcon from '@mui/icons-material/Message';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import useAuth from '../../hooks/useAuth';
import { Formik } from 'formik';
import IndividualFirstNameField from '../../components/account/individual/IndividualFirstNameField';
import IndividualLastNameField from '../../components/account/individual/IndividualLastNameField';
import BusinessNameTextField from '../../components/account/business/BusinessNameTextField';
import EmailField from '../../components/account/fields/email/EmailField';
import PhoneField from '../../components/account/PhoneField ';
import MessageFieldRegular from '../../components/account/MessageFieldRegular';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const CarouselItem = ({ image }) => {
  // if (image.endsWith('.stp')) return null;
  // if (image.endsWith('.gltf')) {
  //   return <CanvasContainer image={image} />;
  // }
  return (
    <Paper
      elevation={0}
      square
      sx={{
        background: grey[200],
        display: 'flex',
        justifyContent: 'center',
        height: '30vh',
      }}
    >
      <Box
        width="80%"
        height="80%"
        mt={4}
        sx={{ objectFit: 'contain' }}
        component="img"
        src={image}
      />
    </Paper>
  );
};

const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const MessageDialog = ({ isOpen, handleClose, selectedRep, handleSend }) => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [message, setMessage] = useState('');
  const [newUserInfo, setNewUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    message: '',
    phone: {
      value: '',
      info: {
        countryCallingCode: null,
        countryCode: null,
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    },
    ip: '',
    sessionID: '',
    captcha: '',
  });
  const [emailError, setEmailError] = useState(false);

  const formRef = useRef(null);
  const initialValues = { ...newUserInfo };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        t('validation.name.first_matches'),
      )
      .required(t('validation.name.first_required')),
    last_name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        t('validation.name.last_matches'),
      )
      .required(t('validation.name.last_required')),
    email: Yup.string()
      .required(t('validation.email.contact_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('validation.email.invalid'),
      ),
    phone: Yup.object({
      value: Yup.string().required(t('validation.phone.required_alt')),
      info: Yup.object({
        countryCallingCode: Yup.number(
          t('validation.phone.country_code.invalid'),
        )
          .required(t('validation.phone.country_code.required'))
          .typeError(t('validation.phone.country_code.error')),
        nationalNumber: Yup.string()
          .required(t('validation.phone.required'))
          .min(8, t('validation.phone.min'))
          .max(15, t('validation.phone.max')),
      }),
    }),
    captcha: Yup.string().required(t('validation.captcha_required')),
    message: Yup.string()
      .required(t('validation.message_required'))
      .min(8, t('validation.message_min')),
  });

  const handleChange = (e) => {
    setNewUserInfo((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleEmailBlur = (e) => {
    setEmailError(!validateEmail(e.target.value));
  };

  const isDisabled = useMemo(() => {
    if (!message) return true;
    if (
      (!auth || !auth.user_info.account_complete) &&
      (!newUserInfo.first_name ||
        !newUserInfo.last_name ||
        !newUserInfo.email ||
        emailError)
    ) {
      return true;
    }
    return false;
  }, [message, auth, newUserInfo, emailError]);

  const submitForm = async (values, actions) => {
    console.log(values, actions);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Send Message to {selectedRep?.first_name} {selectedRep?.last_name}
      </DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        {!auth || !auth.user_info.account_complete ? (
          <Box>
            <Box display="flex" gap={2}>
              <TextField
                label="First Name"
                type="text"
                value={newUserInfo.first_name}
                name="first_name"
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Last Name"
                type="text"
                value={newUserInfo.last_name}
                name="last_name"
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
            </Box>
            <TextField
              label="Email"
              type="email"
              name="email"
              value={newUserInfo.email}
              onChange={handleChange}
              error={emailError}
              helperText={
                emailError ? 'Please enter a valid email address' : ''
              }
              onBlur={handleEmailBlur}
              sx={{ mb: 2 }}
            />
          </Box>
        ) : null}
        <FormControl fullWidth>
          <TextField
            type="text"
            multiline
            rows={4}
            label="Message"
            variant="outlined"
            size="small"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </FormControl>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => submitForm(values, actions)}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
          innerRef={formRef}
        >
          <IndividualFirstNameField required fieldID="first_name" />
          <IndividualLastNameField required fieldID="last_name" />
          <BusinessNameTextField />
          <EmailField transTypeID="your" required />
          <PhoneField fieldID="phone" transTypeID="your" required />
          <MessageFieldRegular />
        </Formik>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button onClick={handleClose} color="secondary" variant="contained">
          {/* {t('buttons.close')} */}
          Cancel
        </Button>
        <Button
          onClick={() => handleSend(message, newUserInfo)}
          color="primary"
          type="submit"
          disabled={isDisabled}
          variant="contained"
        >
          {/* {t('buttons.confirm')} */}
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getImage = (user) => {
  let img = '';
  if (user.profile_picture) {
    img = `data:${user.profile_picture.mimetype};base64,${user.profile_picture.buffer.toString('base64')}`;
  }
  return img;
};

const ProductRepresentatives = ({ id, handleMessage }) => {
  const [productRepresentatives, setProductRepresentatives] = useState([]);

  const getProductRepresentatives = async () => {
    const response = await getProducRepresentativesById(id);
    setProductRepresentatives(response);
  };

  useEffect(() => {
    getProductRepresentatives();
  }, []);

  return (
    <Box display="flex" justifyContent="flex-end">
      {/* <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}> */}
      <Box display="flex" flexDirection="column" flex={0.3}>
        <Typography mb={2}>Available Sales Engineers:</Typography>
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.default',
            border: '1px solid',
            borderColor: 'primary.main',
            borderRadius: '5px',
          }}
        >
          {productRepresentatives?.map((rep) => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar src={getImage(rep)} alt="profile picture">
                    <FaceIcon sx={{ color: 'primary.dark' }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${rep.first_name} ${rep.last_name}`}
                  secondary={`${rep.address?.city}, ${rep.address?.state.toUpperCase()}`}
                />
                <IntuIconButton
                  size="small"
                  type="add"
                  variant="outlined"
                  tooltipTitle={'Message'}
                  onClick={() => handleMessage(rep)}
                  IconComponent={MessageIcon}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

const PublicProductPage = () => {
  const { id } = useParams();
  const { auth } = useAuth();
  const [product, setProduct] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);

  const getProduct = async () => {
    const response = await getProductById(id);
    setProduct(response);
  };

  useEffect(() => {
    getProduct();
  }, []);

  // console.log('product', product);

  const handleMessage = (rep) => {
    setSelectedRep(rep);
    setIsMessageDialogOpen(true);
  };

  const handleClose = () => {
    setSelectedRep(null);
    setIsMessageDialogOpen(false);
  };

  const handleSend = (message, newUserInfo) => {
    if (!auth || !auth.account_complete) {
      // use new user info
      console.log(message, newUserInfo);
    } else {
      // logged in user
      console.log('send', auth, selectedRep, message);
    }
    setIsMessageDialogOpen(false);
  };

  if (!product) return <Skeleton height="60vh" />;
  return (
    <Box mt={15} px={2}>
      <Typography variant="h1">{product.product_name}</Typography>
      <Carousel
        style={{ flex: 1 }}
        // index={activeIndex}
        animation="slide"
        navButtonsAlwaysVisible={product.images.length > 1}
        autoPlay={false}
        swipe={false}
        // IndicatorIcon={imageIndicators}
        indicatorContainerProps={
          {
            // style: {
            //   background: productImages.length > 0 ? 'white' : 'initial',
            //   margin: isShort ? '180px auto 0' : '80px auto 0',
            //   width: '40%',
            //   borderRadius: '5px',
            //   padding: '10px 0',
            // },
          }
        }
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosIcon />}
        navButtonsProps={{
          style: {
            backgroundColor: 'transparent',
            color: 'black',
            // display: productImages.length > 1 ? 'initial' : 'none',
          },
        }}
        width={{ xs: '60vw', md: '100vw' }}
        height="30vh"
        // onChange={(now) => setActiveIndex(now)}
      >
        {product.images.map((image, i) => {
          return <CarouselItem key={i} image={image} />;
        })}
      </Carousel>
      <ProductRepresentatives id={id} handleMessage={handleMessage} />
      <MessageDialog
        isOpen={isMessageDialogOpen}
        selectedRep={selectedRep}
        handleClose={handleClose}
        handleSend={handleSend}
      />
    </Box>
  );
};

export default PublicProductPage;
