import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import FooterComponent from '../footer/Footer';

const NonAuthLayout = () => {
  return (
    <>
      <Container
        maxWidth="xxl"
        component="main"
        disableGutters
        sx={{
          flex: '1 auto',
        }}
      >
        <Outlet />
      </Container>
      <FooterComponent />
    </>
  );
};

export default NonAuthLayout;
