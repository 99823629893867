import { useContext } from 'react';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import FormikFieldWrapper from '../formik/FormikFieldWrapper.jsx';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import { getNestedValue } from '../../helpers/getNestedValuesIn.js';
import { IntuCompanyContext } from '../../context/providers/IntuCompanyInfoProvider.jsx';

const AssociateSelectFieldContent = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'associate', // Define the Fields Name and ID
  autocompleteID = 'associate', // Define the Fields Name and ID
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'AssociateSelectField', // KeyPrefix which holds label and placeholder
  transition = true, // Transition true = fade in, false = fade out
  disabled = false, // Disable the Field
  required = false, // Field Required
  variant = 'outlined', // Set the Variant type standard or outlined
  options = [], //Hand in the selection options
}) => {
  const { values, setFieldValue, handleBlur, touched, errors } =
    useFormikContext();

  const { isLoading } = useProcessingHandler();

  const { employeeOptions } = useContext(IntuCompanyContext);

  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <>
      <FormikFieldWrapper
        fieldTitle={fieldTitle}
        fieldDescription={fieldDescription}
        transition={transition}
      >
        <Autocomplete
          value={getNestedValue(values, autocompleteID)}
          required={required}
          disabled={disabled || isLoading?.status ? true : false}
          id={fieldID}
          name={fieldID}
          disableClearable
          options={options?.sort((a, b) => -b.name.localeCompare(a.name))}
          getOptionLabel={(option) => {
            return option?._id ? `${option.name} (${option.email})` : '';
          }}
          isOptionEqualToValue={(option, value) =>
            option?.email === value?.email || ''
          }
          className="form-select-field"
          onChange={(e, options, reason, element) => {
            if (reason === 'selectOption') {
              setFieldValue(fieldID, element.option);
            } else if (reason === 'clear') {
              setFieldValue(fieldID, {
                name: '',
                email: '',
                _id: '',
              });
            }
          }}
          onBlur={handleBlur}
          sx={{ paddingBottom: '50px' }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              variant={variant}
              label={!disabled ? t('label') : null}
              error={errors[fieldID] && touched[fieldID] ? true : false}
              helperText={
                errors[fieldID] && touched[fieldID] ? errors[fieldID] : null
              }
            />
          )}
        />
      </FormikFieldWrapper>
    </>
  );
};

const AssociateSelectField = (props) => {
  return <AssociateSelectFieldContent {...props} />;
};

export default AssociateSelectField;
