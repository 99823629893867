import { axiosPrivate } from "../hooks/axios/axios";
import { t } from 'i18next'

async function getPaymentTermsList() {
    const url = 'api/payment/terms/list';

    try {
        const response = await axiosPrivate.get(url, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        const { data, status } = response;

        if (status === 200) {
            return {
                status: data.statusCode,
                message: data.message,
                data: data.data,
            }
        } else {
            return {
                status: data.status,
                message: data.message,
            }
        }
    } catch (error) {
        console.error(error)
        return error
    }
}

async function addPaymentTerms(payload) {
    const req_fields = [];
    console.log(payload)
    !payload && req_fields.push(t('routes.req_fields.payload'))

    if (req_fields.length > 0) {
        throw new Error(
            t('routes.responses.req_fields', { requiredFields: req_fields }),
        );
    }

    const formattedPayload = {
        data: payload
    }

    const url = 'api/payment/terms/add';

    try {
        const response = await axiosPrivate.post(url, JSON.stringify(formattedPayload), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });
        console.log(response)
        const { data, status } = response;

        if (status === 200) {
            return {
                status: data.status,
                message: data.message,
                data: data.data,
            };
        } else {
            return {
                status: data.status,
                message: data.message,
            };
        }
    } catch (error) {
        console.error('error', error);
        return error;
    }
}

export {
    getPaymentTermsList,
    addPaymentTerms
}