import { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Grid, Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import './styles.css';

export const ScrollingCards = ({ contentArr, header, footer }) => {
  // Ref of entire component
  const scrollCardsRef = useRef(null);
  // Ref of sub container for header opacity
  const { scrollYProgress } = useScroll({
    target: scrollCardsRef,
    offset: ['start start', 'end start'],
  });

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: '15vh',
      }}
      className="scrollingCardsContainer"
    >
      <Box ref={scrollCardsRef} className="scrollingCardsSubContainer">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            sx={{
              padding: '0 1rem',
              position: 'sticky',
              top: '15%',
            }}
          >
            <HeaderContent header={header} scrollProgress={scrollYProgress} />
          </Grid>
          {contentArr.map((content, i, array) => {
            const targetScale = 1 - (array.length - i) * 0.1;
            return (
              <Card
                content={content}
                key={i}
                i={i}
                scrollProgress={scrollYProgress}
                range={[i * (1 / array.length), 1]}
                targetScale={targetScale}
                header={header}
                limit={array.length}
              />
            );
          })}
        </Grid>
        <Grid item>
          <FooterContent footer={footer} />
        </Grid>
      </Box>
    </Box>
  );
};

const Card = ({ content, i, scrollProgress, range, targetScale, limit }) => {
  const scale = useTransform(scrollProgress, range, [1, targetScale]);
  const opacity = useTransform(scrollProgress, range, [0, 0.6]);

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <motion.div
      style={{ top: `calc(25% + ${i * 7}vh)`, scale }}
      className="cardContainer"
      height="100vh"
    >
      <Paper
        className="scrollingCards"
        square={false}
        elevation={12}
        sx={{
          width: '100%',
          height: small ? '20rem' : '15rem',
          borderRadius: '50px',
          padding: '0 1rem',
        }}
      >
        {content}
        {limit - 1 !== i && (
          <motion.div
            className="darkOverlay"
            style={{
              opacity,
            }}
          />
        )}
      </Paper>
    </motion.div>
  );
};

const HeaderContent = ({ header, scrollProgress }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const opacity = useTransform(
    scrollProgress,
    small ? [0, 0.83] : [0.95, 1],
    [1, 0],
  );
  return (
    <motion.div
      className="headerContainer"
      style={{
        height: '10vh',
        opacity,
        zIndex: 2,
        marginBottom: '5rem',
      }}
    >
      {header}
    </motion.div>
  );
};

const FooterContent = ({ footer }) => {
  return <div className="footerContainer">{footer}</div>;
};
