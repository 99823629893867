import { useContext, useMemo } from 'react';
import {
    Grid,
    Divider,
    Button,
    Typography,
    Box,
    useTheme,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useTranslation } from 'react-i18next';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';
import { ProfileChips } from './ProfileChipsComponent';
import UserContext from '../../context/providers/UserInfoProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledAvatar } from '../image/fields/StyledAvatar';

export const ProfileHeaderComponent = ({
    profileInfo,
    miscInfo,
    setIsDialogOpen,
    type,
}) => {
    const theme = useTheme();
    const { mode } = useContext(IntuThemeContext);
    const { t } = useTranslation('translation', {
        keyPrefix: 'components.ProfileHeaderComponent',
    });
    const { t: transButtons } = useTranslation('buttons');
    const { conversations } = useContext(UserContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const hasConversationWithUser = useMemo(() => {
        return !!conversations.find((c) => c.sender._id === id);
    }, [conversations, id]);

    const handleMessageClick = () => {
        if (hasConversationWithUser) {
            navigate('/app/chat');
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: theme.palette.profile[mode],
                borderRadius: '25px',
            }}
        >
            <Grid
                item
                container
                flexDirection={{ xs: 'column', md: 'row' }}
                md={12}
                pt={4}
                pb={4}
            >
                <Grid
                    container
                    item
                    xs={11}
                    md={3}
                    lg={2}
                    ml={2}
                    mr={2}
                    pb={{ sm: 2, md: 0 }}
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                >
                    <Grid item container xs={8} sm={6} md={12} justifyContent='center'>
                        <StyledAvatar
                            alt="Profile Picture"
                            src={profileInfo?.profile_picture_view_url}
                            sx={{
                                width: '15rem',
                                height: '15rem',
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    flexDirection="column"
                    xs={12}
                    md={8.5}
                    lg={9.5}
                    sx={{ height: '100%', boxSizing: 'border-box' }}
                >
                    <Grid
                        item
                        container
                        flexDirection={{ xs: 'column', md: 'row' }}
                        justifyContent={{ xs: 'center', lg: 'space-between' }}
                        xs={12}
                        pb={{ sm: 2, md: 0 }}
                    >
                        <Grid item xs={12} md={7}>
                            {/* Name */}
                            <Typography
                                variant="h3"
                                sx={{ textAlign: { xs: 'center', md: 'left' } }}
                            >
                                {profileInfo?.name}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            mp={2}
                            xs={12}
                            md={5}
                            justifyContent={{ xs: 'center', md: 'flex-end' }}
                        >
                            <Grid mr={4}>
                                <Button
                                    variant="contained"
                                    startIcon={<EmailIcon />}
                                    onClick={handleMessageClick}
                                >
                                    {transButtons('message')}
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    startIcon={<VerifiedUserIcon />}
                                    onClick={() => setIsDialogOpen(true)}
                                    disabled={hasConversationWithUser}
                                >
                                    {type === 'manufacturer'
                                        ? transButtons('apply')
                                        : transButtons('recruit')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        item
                        flexDirection={{ xs: 'column', md: 'row' }}
                        mt={4}
                        justifyContent={{ xs: 'center', md: 'space-between' }}
                        xs={12}
                        sx={{ display: 'flex' }}
                    >
                        <Grid
                            item
                            container
                            flexDirection="column"
                            xs={12}
                            md={5}
                            lg={4}
                            sx={{ maxHeight: { xs: '50vh', md: '5vh' }, flex: 1 }}
                        >
                            {type === 'manufacturer' && (
                                <Grid
                                    item
                                    container
                                    flexDirection="row"
                                    xs={12}
                                    spacing={2}
                                    ml={{ xs: 0, sm: 4, md: 2 }}
                                >
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        flexDirection="row"
                                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                                        spacing={4}
                                    >
                                        <Grid item xs={6} md={5}>
                                            <Typography>{t('launch_date')}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={7}>
                                            <Typography>{miscInfo?.launch_date}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        flexDirection="row"
                                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                                        spacing={4}
                                    >
                                        <Grid item xs={6} md={5}>
                                            <Typography>{t('sales_reps')}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={7}>
                                            <Typography>{miscInfo?.salesreps}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        flexDirection="row"
                                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                                        spacing={4}
                                    >
                                        <Grid item xs={6} md={5}>
                                            <Typography>{t('sales_yoy')}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={7}>
                                            <Typography>{'$' + miscInfo?.sales_yoy}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        flexDirection="row"
                                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                                        spacing={4}
                                    >
                                        <Grid item xs={6} md={5}>
                                            <Typography>{t('hq')}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={7}>
                                            <Typography>
                                                {profileInfo?.address?.city},{' '}
                                                {profileInfo?.address?.state}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {type === 'sales' && (
                                <Grid
                                    item
                                    container
                                    flexDirection="row"
                                    xs={12}
                                    spacing={2}
                                    ml={{ xs: 0, sm: 4, md: 2 }}
                                >
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        flexDirection="row"
                                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                                    >
                                        <Grid item xs={4} md={5}>
                                            <Typography>{t('member_since')}</Typography>
                                        </Grid>
                                        <Grid item xs={4} md={7}>
                                            <Typography>{miscInfo?.member_since}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        flexDirection="row"
                                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                                    >
                                        <Grid item xs={4} md={5}>
                                            <Typography>{t('located')}</Typography>
                                        </Grid>
                                        <Grid item xs={4} md={7}>
                                            {miscInfo?.location?.city}, {miscInfo?.location?.state}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        flexDirection="row"
                                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                                    >
                                        <Grid item xs={4} md={5}>
                                            <Typography>{t('brands')}</Typography>
                                        </Grid>
                                        <Grid item xs={4} md={7}>
                                            <Typography>{miscInfo?.brands}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        flexDirection="row"
                                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                                    >
                                        <Grid item xs={4} md={5}>
                                            <Typography>{t('sales_yoy')}</Typography>
                                        </Grid>
                                        <Grid item xs={4} md={7}>
                                            <Typography>
                                                <Typography>{miscInfo?.sales_yoy}</Typography>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            flexDirection="column"
                            xs={11}
                            md={6.5}
                            lg={7.5}
                            sx={{ display: 'block' }}
                            mt={{ xs: 2, md: -1 }}
                            ml={{ xs: -1, sm: 3, md: 2 }}
                            spacing={1}
                        >
                            {/* Industries */}
                            <Grid
                                item
                                container
                                xs={12}
                                flexDirection={{ xs: 'column', md: 'row' }}
                                justifyContent={{ xs: 'center' }}
                                ml={{ xs: 2, md: 0 }}
                            >
                                <Grid item xs={12} md={3}>
                                    <Typography>{t('applications')}</Typography>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <ProfileChips
                                        itemArray={profileInfo.applications}
                                        type="application"
                                    />
                                </Grid>
                            </Grid>
                            {/* Products */}
                            <Grid
                                item
                                container
                                xs={12}
                                flexDirection={{ xs: 'column', md: 'row' }}
                                justifyContent={{ xs: 'center' }}
                                ml={{ xs: 2, md: 0 }}
                                mt={{ xs: 2, md: 0, lg: 2 }}
                            >
                                <Grid item xs={12} md={3}>
                                    <Typography>{t('products')}</Typography>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <ProfileChips
                                        itemArray={profileInfo.product_types}
                                        type="product_type"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
