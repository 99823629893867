import { useEffect, useRef } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';

const RiveWrapper = ({
  src,
  stateMachines,
  width,
  height,
  marginBottom,
  customLabels,
}) => {
  const { rive, RiveComponent } = useRive({
    src,
    stateMachines,
    // layout: new Layout({
    //   fit: Fit.Contain, // Use Fit.Contain for more flexibility with text fitting
    //   alignment: Alignment.Center, // Center the animation
    // }),
    autoplay: false,
  });

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  let minWidthHeight;
  if (!width && !height) {
    minWidthHeight = small ? '200px' : '300px';
  }

  const riveContainerRef = useRef(null);

  // UseEffect to handle customLabels updates
  useEffect(() => {
    if (
      rive &&
      typeof customLabels === 'object' &&
      customLabels !== null &&
      Object.keys(customLabels).length > 0
    ) {
      // console.log('Custom Labels:', customLabels);

      // Iterate through customLabels and update Rive instance values
      Object.entries(customLabels).forEach(([key, value]) => {
        if (
          typeof rive.getTextRunValue === 'function' &&
          typeof rive.setTextRunValue === 'function'
        ) {
          // Get the current value and log it
          const currentText = rive.getTextRunValue(key);
          // console.log(`Current value for ${key}:`, currentText);

          // Set the new value and log it
          rive.setTextRunValue(key, value);
          // console.log(`New value for ${key}:`, rive.getTextRunValue(key));
        }
      });
    }
  }, [rive, customLabels]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (rive) {
          if (entry.isIntersecting) {
            rive.play();
          } else {
            rive.pause();
          }
        }
      },
      {
        root: null,
        threshold: 0.1,
      },
    );

    if (riveContainerRef.current) {
      observer.observe(riveContainerRef.current);
    }

    return () => {
      if (riveContainerRef.current) {
        observer.unobserve(riveContainerRef.current);
      }
    };
  }, [rive]);

  return (
    <div
      ref={riveContainerRef}
      style={{
        minWidth: minWidthHeight,
        minHeight: minWidthHeight,
        width: width,
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <RiveComponent
        style={{
          width: '100%',
          height: '100%',
          marginBottom: small ? marginBottom : '0',
        }}
      />
    </div>
  );
};

export const RiveAnimationComponent = ({
  src = 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/404_page.riv',
  stateMachines = 'SM_ComingSoon',
  width = '250px',
  height = '250px',
  hAlign = 'center',
  vAlign = 'center',
  sx = {},
  marginBottom = '0',
  customLabels = {},
  ...rest
}) => {
  // console.log('Received Custom Labels => ', customLabels);
  return (
    <Grid
      container
      justifyContent={hAlign === 'center' ? 'center' : 'flex-start'}
      alignItems={vAlign === 'center' ? 'center' : 'flex-start'}
      sx={{ ...sx }}
      {...rest}
    >
      <Grid item>
        <RiveWrapper
          src={src}
          stateMachines={stateMachines}
          width={width}
          height={height}
          marginBottom={marginBottom}
          customLabels={customLabels} // Pass customLabels down to RiveWrapper
        />
      </Grid>
    </Grid>
  );
};
