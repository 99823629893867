import React, { useEffect } from 'react';
import chartDataValidator from '../helpers/chartDataValidator';

// HTML Parser
import ReactHtmlParser from 'react-html-parser';

// AM5
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5Themes_IntuTheme from './theme';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import { Grid, Typography } from '@mui/material';

// theme mode
import useIntuTheme from '../hooks/useIntuTheme';

const PieChartContext = ({
  chartId,
  description: chartDescription = '',
  title: chartTitle = '',
  descriptionColor: chartDescriptionColor = '',
  data = [],
  legend = true,
  displayValueAsPercentage = true,
  displayValueAsLabel,
  marginBottom = '30px',
  centered,
  hideLabels,
}) => {
  // Get Theme mode Context
  const { mode } = useIntuTheme();

  useEffect(() => {
    // Guard Clause: Ensure the chartId exists and the element is available in the DOM
    if (!chartId || !document.getElementById(chartId)) {
      return;
    }

    // SetUp Chart
    let root = am5.Root.new(chartId);

    // Set themes based on the current mode
    const responsive = am5themes_Responsive.new(root);

    if (mode === 'dark') {
      root.setThemes([
        am5themes_Animated.new(root),
        responsive,
        am5Themes_IntuTheme.new(root),
        am5themes_Dark.new(root),
      ]);
    } else {
      root.setThemes([
        am5themes_Animated.new(root),
        responsive,
        am5Themes_IntuTheme.new(root),
      ]);
    }

    // Colors for Data
    const intuLightGrey = '#4D4D4D';
    const intuMiddleGrey = '#6F7978';
    const intuGreen = '#A8C957';
    const intuDarkGreen = '#8AA43B';
    const intuLightGreen = '#C3DF7F';
    const intuPurple = '#A10976';
    const intuLightPurple = '#C13B94';
    const intuOrange = '#d93e16';
    const intuBlue = '#57A8C9';
    const intuDarkBlue = '#79BCD4';
    const intuLightBlue = '#3E7F9A';

    // Create chart
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        endAngle: 270,
        innerRadius: am5.percent(60),
      }),
    );

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        alignLabels: false,
      }),
    );

    // Adjust label text based on displayValueAsPercentage prop
    if (displayValueAsPercentage === false && displayValueAsLabel) {
      series.labels.template.setAll({
        text: '{category}: {value} ' + displayValueAsLabel,
      });
    }

    // Set Slice colors
    series.slices.template.setAll({
      strokeOpacity: 0,
      cornerRadius: 4,
      stroke: am5.color(intuMiddleGrey),
      templateField: 'sliceSettings',
    });

    series.labels.template.setAll({
      centerX: 0,
      centerY: 0,
      maxWidth: 150,
      oversizedBehavior: "wrap"
    });

    if (hideLabels) {
      series.labels.template.set('forceHidden', true);
    }

    const noDataModal = chartDataValidator(series, root);

    // Set slice color by applying color to series
    series
      .get('colors')
      .set('colors', [
        am5.color(intuGreen),
        am5.color(intuBlue),
        am5.color(intuPurple),
        am5.color(intuLightGrey),
        am5.color(intuLightBlue),
        am5.color(intuMiddleGrey),
        am5.color(intuOrange),
        am5.color(intuLightGreen),
        am5.color(intuDarkBlue),
        am5.color(intuMiddleGrey),
        am5.color(intuLightPurple),
        am5.color(intuDarkGreen),
      ]);

    // Set data
    series.data.setAll(data);

    // Create legend
    if (legend === true) {
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          marginTop: 15,
          marginBottom: 15,
        }),
      );

      legend.data.setAll(series.dataItems);
    }

    // Play initial series animation
    series.appear(1000, 100);

    // Clean up function
    return () => {
      root.dispose();
    };
  }, [chartId, data, mode]); // Only run effect when chartId, data, or mode changes

  return (
    // Chart Component
    <>
      <Grid
        item
        xs={12}
        sx={{ marginBottom: marginBottom }}
        justifyContent={centered ? 'center' : 'left'}
      >
        <Typography
          variant="h5"
          color={chartDescriptionColor ? chartDescriptionColor : null}
        >
          {ReactHtmlParser(chartTitle)}
        </Typography>
      </Grid>
      {chartDescription && (
        <Grid
          container
          item
          xs={12}
          sx={{ marginBottom: marginBottom }}
          justifyContent={centered ? 'center' : 'left'}
        >
          <Typography
            variant="body"
            color={chartDescriptionColor ? chartDescriptionColor : null}
          >
            {ReactHtmlParser(chartDescription)}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        id={chartId}
        sx={{
          width: '100%',
          height: { xs: 350, md: 500 },
          // margin: '0 1rem 0 0',
        }}
      />
    </>
  );
};

export { PieChartContext };
