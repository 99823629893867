import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonHoverGradiant } from '../../../components/buttons/ButtonHoverGradiant.jsx';

export const CardHeader = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.home.SalesHome.CardHeader',
  });
  return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h1">{t('title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body" align="center" px={2}>
          {t('description')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const CardFooter = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.home.SalesHome.CardFooter',
  });
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-around"
      mt={25}
    >
      <Grid item xs={10} md={12}>
        <Typography variant="h3">{t('title')}</Typography>
        <Typography variant="body" align="center">
          {t('description')}
        </Typography>
        <ButtonHoverGradiant
          label="Join Now"
          onClick={() => navigate('/account/signup')}
        />
      </Grid>
    </Grid>
  );
};
