import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useMotionValueEvent,
} from 'framer-motion';
import { RiveAnimationComponent } from '../animations/RiveAnimationComponent';
import { VideoPlayer } from '../../plugins/VideoPlayer.js';

const SingleZoomInOutComponent = ({
  src,
  type,
  stateMachines,
  autoplay = true,
  title = '',
  description = '',
  footerContent = null,
  stickyval = 0.5,
  unstickyval = 1,
  scaleThreshold = 0.85,
  progress = [0.5, 0.8, 1],
  zoom = [0, 0.1, 1],
  titleMT = '2rem',
  titleMB = '2rem',
  height,
  width,
  customLabels,
  ...props
}) => {
  const headerRef = useRef(null);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  });
  const footerScrollProgress = useTransform(scrollYProgress, progress, zoom);

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  // Smooth transitions for opacity and position based on scroll
  const opacity = useSpring(useTransform(scrollYProgress, [0.3, 0.7], [0, 1]), {
    stiffness: 100,
    damping: 20,
  });

  // Updated translateY to smoothly scroll the title out
  const translateY = useSpring(
    useTransform(scrollYProgress, [stickyval, unstickyval], ['0%', '-100%']),
    { stiffness: 100, damping: 20 },
  );

  // Convert isSticky to a state variable
  const isSticky = useTransform(
    scrollYProgress,
    [scaleThreshold, 1],
    [true, false],
  );
  const [isStickyState, setIsStickyState] = useState(true);

  useMotionValueEvent(scrollYProgress, 'change', () => {
    console.log('Scrolling Process -> ', scrollYProgress);
    console.log('Sticky State -> ', isStickyState);
  });

  useEffect(() => {
    // Sync isSticky's value with the state
    return isSticky.onChange((value) => setIsStickyState(value));
  }, [isSticky]);

  return (
    <Grid container sx={{ padding: '0 2rem 0 2rem' }} {...props}>
      {/* Title */}
      <Grid
        item
        textAlign="center"
        sx={{
          position: isStickyState ? 'sticky' : 'relative',
          top: titleMT,
          marginTop: titleMT,
          marginBottom: titleMB,
          zIndex: 2,
          width: '100%',
          padding: 0,
        }}
      >
        <motion.div
          ref={headerRef}
          style={{
            opacity,
            y: translateY,
            transition: 'opacity 0.3s ease-in-out',
            padding: 0,
            marginTop: '10vh',
            width: '100%',
          }}
        >
          <Typography variant="h1" sx={{ padding: 0, mb: '2rem' }}>
            {title}
          </Typography>
          <Typography variant="body" sx={{ padding: 0, margin: 0 }}>
            {description}
          </Typography>
        </motion.div>
      </Grid>
      {/* Content */}
      <Grid
        item
        sx={{
          width: '100%',
          maxWidth: type === 'video' && !small ? '90%' : '100%',
          padding: 0,
          margin: 0,
        }}
      >
        <motion.div
          ref={ref}
          style={{ scale: scrollYProgress, opacity, padding: 0, margin: 0 }}
        >
          {type === 'animation' ? (
            <RiveAnimationComponent
              key={src}
              src={src}
              stateMachines={stateMachines}
              autoPlay={autoplay}
              width={width}
              height={height}
              customLabels={customLabels}
            />
          ) : type === 'image' ? (
            <Box
              component="img"
              sx={{ width: '100%', padding: 0, margin: 0 }}
              alt={title}
              src={src}
            />
          ) : (
            <VideoPlayer videoSource={src} controls={true} />
          )}
        </motion.div>
      </Grid>
      {/* Footer */}
      {footerContent && (
        <Grid item xs={12} sx={{ padding: 0 }}>
          <motion.div
            style={{
              opacity: footerScrollProgress,
              marginTop: '3rem',
              padding: 0,
              width: '100%',
            }}
          >
            <Grid item xs={12} sx={{ padding: 0 }}>
              {footerContent}
            </Grid>
          </motion.div>
        </Grid>
      )}
    </Grid>
  );
};

export const SimpleZoomInOutcomponent = ({ srcArray, ...props }) => {
  return srcArray.map((element, index) => (
    <SingleZoomInOutComponent key={index} {...element} {...props} />
  ));
};
