import { useState, useRef, useContext } from 'react';

import {
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  FormControl,
  MenuItem,
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Container,
  InputAdornment,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import ClearIcon from '@mui/icons-material/Clear';

// Action Icons
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// Formik
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';

import useResponseHandling from '../../../hooks/useResponseHandler.js';

// @React-page
import '@in2tec/page-editor/lib/index.css';
import { IntuPageBuilder } from '../../pageEditor/IntuPageBuilder.jsx';

//  Blog Header & Footer
import PostHeaderContainer from '../containers/PostHeaderContainer.jsx';

import { ObjectId } from 'bson';

// Get Context
import { PostContext } from '../../../context/providers/PostContextProvider.jsx';

// Translator
import { useTranslation } from 'react-i18next';

// Routes
import { searchPosts, searchPostTags } from '../../../routes/blogRoutes.js';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker.jsx';
import { useNavigate } from 'react-router-dom';
import {
  useAxiosPrivate,
  useAxiosPrivateData,
} from '../../../hooks/axios/useAxiosPrivate.js';
import useAuth from '../../../hooks/useAuth.js';

// Get Host
const host_url = window.location.host;

const supported_file_formats = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result.split(',')[1]);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

const PostEditForm = () => {
  const {
    postInfo,
    setPostInfo,
    postImage,
    setPostImage,
    handleEditPost,
    dialogOpen,
    dialogMsg,
    dialogTitle,
    setDialogMsg,
    setDialogOpen,
    setDialogTitle,
  } = useContext(PostContext);
  // Loading Spinner Status
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isSearchingLink, setIsSearchingLink] = useState(false);
  const formRef = useRef();
  const { gaEventTracker } = useAnalyticsEventTracker();

  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();
  const { auth } = useAuth;

  // Initial Values
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    ...postInfo,
    link_options: [],
    tag_options: [],
    tag_search: '',
    duplicate_link: '',
    description: postInfo.description ? postInfo.description : '',
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .min(20, ({ value, min }) => {
        let characterCount = value.trim().length;
        // return `Your description is with ${characterCount} characters too short!. Make sure to have at least ${min} characters.`
        return t('validation.post.min', {
          field: 'description',
          characterCount,
          min,
        });
      })
      .max(1000, ({ value, min }) => {
        let characterCount = value.trim().length;
        // return `Your description is with ${characterCount} characters too long!. Make sure to stay below ${min} characters.`
        return t('validation.post.max', {
          field: 'description',
          characterCount,
          min,
        });
      })
      .required(t('validation.post.desc_required')),
    title: Yup.string()
      .min(20, ({ value, min }) => {
        let characterCount = value.trim().length;
        // return `Your title is with ${characterCount} characters too short!. Make sure to have at least ${min} characters.`
        return t('validation.post.min', {
          field: 'title',
          characterCount,
          min,
        });
      })
      .max(90, ({ value, min }) => {
        let characterCount = value.trim().length;
        // return `Your title is with ${characterCount} characters too long!. Make sure to stay below ${min} characters.`
        return t('validation.post.max', {
          field: 'title',
          characterCount,
          min,
        });
      })
      .matches(
        /^[ A-Za-z0-9_@./!%^*$()#&+-]*$/,
        t('validation.post.title_matches'),
      )
      .required(t('validation.post.title_required')),
    tags: Yup.array()
      .min(1, t('validation.post.tags_min'))
      .required(t('validation.post.tags_required')),
    status: Yup.string().required(t('validation.post.status_required')),
    link: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .required(t('validation.post.link_required'))
      .notOneOf(
        [Yup.ref('duplicate_link'), null],
        t('validation.post.link_duplicate'),
      )
      .matches(/^[ A-Za-z0-9_@./#&+-]*$/, t('validation.post.link_matches')),
    content: Yup.object({
      rows: Yup.array()
        .required(t('validation.post.content_required'))
        .min(1, t('validation.post.content_min')),
    }),
    image: Yup.mixed().when('image_url', {
      is: (image_url) => Boolean(!image_url),
      then: (schema) =>
        schema
          .required(t('validation.file.image_required'))
          .test(
            'fileSize',
            t('validation.file.file_size'),
            (value) => value && value.size <= 5000000,
          )
          .test(
            'fileFormat',
            t('validation.file.format'),
            (value) => value && supported_file_formats.includes(value.type),
          ),
    }),
  });

  // handle Error Dialog
  function handleConfirmError() {
    setDialogTitle('');
    setDialogMsg('');
    setDialogOpen(false);
  }

  async function updatePost(values, formik) {
    // Guard Cause for Publishing

    if (values.status === 'published' && formik.isValid !== true) {
      const errors = formik.errors;
      const errorFields = Object.keys(errors).join(', ');

      setDialogTitle(t('context.blog.post.dialog.publish.title'));
      setDialogMsg(
        t('context.blog.post.dialog.publish.msg', { errors: errorFields }),
      );
      setDialogOpen(true);
      return;
    }

    try {
      const newUrl = values.new_url;
      const full_url = `https://${host_url}/blog/${values.link}`;

      // Construct payload
      let payload = {
        title: values.title,
        description: values.description,
        tags: values.tags,
        author: values.author,
        status: values.status,
        content: values.content,
        link: values.link,
        full_url,
      };

      // If fileURL is available, add it to payload
      if (values?.image_url) {
        payload.image_url = values?.image_url;
      }

      // Check for post id and assign one if missing
      if (!values._id) {
        payload = { ...payload, _id: new ObjectId() };
      } else {
        payload = { ...payload, _id: values._id };
      }

      const url = '/api/blog/posts/update';
      const response = await axios.put(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });

      const { data, status } = response;

      if (status === 200) {
        gaEventTracker({
          category: 'Blog Management',
          action: 'Event',
          label: 'Post Updated',
        });
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });

        // If an image is available, fetch and convert it
        if (data.image_url) {
          const controller = new AbortController();
          const { signal } = controller;
          const imageResponse = await fetch(data.image_url, { signal });
          if (!imageResponse.ok) {
            throw new Error(
              'Network response was not ok' + imageResponse.statusText,
            );
          }
          const imageFile = await imageResponse.blob();
          const reader = new FileReader();

          reader.onloadend = () => {
            let imageBlob = reader.result;

            setPostImage(imageBlob);
          };

          reader.readAsDataURL(imageFile);
        }

        // Update Post to avoid data loss
        setPostInfo({
          ...data.data,
          inEditMode: true,
        });

        if (newUrl) {
          window.location.href = data.full_url;
        }

        navigate(-1);
        return;
        // Update Link id URL has changed
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  const [openCloseDialog, setCloseDialogOpen] = useState(false);
  const CloseDialog = () => {
    function handleConfirm() {
      setCloseDialogOpen(false);
      navigate(-1);
    }

    return (
      <Dialog
        open={openCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Discard your edits?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('context.blog.post.publish.discard')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="warning" onClick={handleConfirm}>
            {t('buttons.discard_edit')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCloseDialogOpen(false)}
          >
            {t('buttons.continue_edit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Formik
      // values={formik.values}
      initialValues={formik.initialValues}
      errors={formik.errors}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          setValues,
          setTouched,
          resetForm,
        } = formik;
        return (
          <Container maxWidth="auto" disableGutters>
            {/* Header Container */}
            <PostHeaderContainer
              content={values}
              states={{
                inEditMode: postInfo.inEditMode,
                setDialogMsg,
                setDialogOpen,
                setDialogTitle,
                setPostInfo,
              }}
            />
            <Container component="main" maxWidth="xxl" className="intu__page">
              {/* Blogs Overview Container */}
              <Grid container className="content">
                <Grid item className="title content-centered" xs={12}>
                  <Typography variant="h1"></Typography>
                </Grid>
                <Grid item className="content" xs={12}>
                  <Form className="form-horizontal intu__form" role="form">
                    {/* Error Dialog */}
                    <Dialog
                      open={dialogOpen}
                      onClose={handleConfirmError}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {dialogTitle}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {dialogMsg}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleConfirmError} autoFocus>
                          {transButtons('ok')}
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <FormControl fullWidth>
                      <Typography variant="h4">
                        {t('context.blog.post.edit_title')}
                      </Typography>
                      <Grid container direction="column">
                        <Grid
                          item
                          container
                          xs={12}
                          direction="row"
                          spacing={2}
                        >
                          {/* row 1 - Column 1 */}
                          <Grid item xs={12} md={8}>
                            {/* Title */}
                            <Typography variant="h5">
                              {t('context.blog.post.detail_title')}
                            </Typography>
                            <Stack direction="column" spacing={2}>
                              <TextField
                                required
                                id="title"
                                name="title"
                                aria-invalid={errors.title ? 'true' : 'false'}
                                aria-describedby="uidnote"
                                className="form-select-field"
                                variant="outlined"
                                label={t('form.post.details.title_label')}
                                type="text"
                                placeholder={t(
                                  'form.post.details.title_placeholder',
                                )}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values?.title}
                                error={
                                  errors?.title && touched?.title ? true : false
                                }
                                helperText={
                                  touched?.title && errors?.title
                                    ? errors?.title
                                    : null
                                }
                              />

                              {/* Link */}
                              <TextField
                                id="link"
                                name="link"
                                className="form-select-field"
                                variant="outlined"
                                type="text"
                                label={t('form.post.details.link_label')}
                                InputLabelProps={{ shrink: true }}
                                placeholder={t(
                                  'form.post.details.link_placeholder',
                                )}
                                onBlur={(e) => {
                                  if (formik.values?.link_changed > '') {
                                    let input = e.target.value;
                                    setIsSearchingLink(true);
                                    let urlSearch = setTimeout(async () => {
                                      try {
                                        const response =
                                          await searchPosts(input);
                                        const { data, status } = response;
                                        if (status === 'success') {
                                          // Check if current post is the one being found
                                          if (data[0]._id === values._id) {
                                            setValues({
                                              ...formik.values,
                                              duplicate_link: '',
                                              new_url: true,
                                            });
                                          } else {
                                            setValues({
                                              ...formik.values,
                                              duplicate_link: data[0].link,
                                              new_url: false,
                                            });
                                          }
                                        } else {
                                          setValues({
                                            ...formik.values,
                                            duplicate_link: '',
                                            new_url: true,
                                          });
                                        }
                                      } catch (err) {
                                        console.log(err);
                                      } finally {
                                        setIsSearchingLink(false);
                                      }
                                      clearTimeout(urlSearch);
                                    }, 100);
                                  }
                                  handleBlur(e);
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...formik.values,
                                    link: e.target.value,
                                    link_changed: true,
                                  });
                                }}
                                InputProps={{
                                  type: 'text',
                                  autoComplete: 'off',
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {isSearchingLink ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                    </InputAdornment>
                                  ),
                                }}
                                value={values.link}
                                error={
                                  touched?.link && errors?.link ? true : false
                                }
                                helperText={
                                  touched?.link && errors?.link
                                    ? errors?.link
                                    : null
                                }
                              />

                              {/* Description */}
                              <TextField
                                required
                                multiline
                                aria-invalid={
                                  errors.description ? 'true' : 'false'
                                }
                                aria-describedby="uidnote"
                                id="description"
                                name="description"
                                className="form-select-field"
                                variant="outlined"
                                placeholder={t(
                                  'form.post.details.desc_placeholder',
                                )}
                                type="input"
                                label={t('form.post.details.desc_label')}
                                minRows={8}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                inputProps={{ style: { resize: 'vertical' } }}
                                onBlur={handleBlur}
                                value={values?.description}
                                error={
                                  errors?.description && touched?.description
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors?.description && touched?.description
                                    ? errors.description
                                    : null
                                }
                              />

                              {/* Tags */}
                              <Autocomplete
                                freeSolo
                                autoComplete
                                multiple
                                required
                                loading={isLoadingTags || false}
                                loadingText={t(
                                  'form.post.details.tags_loading',
                                )}
                                filterSelectedOptions
                                handleHomeEndKeys
                                id="tags"
                                name="tags"
                                value={values?.tags}
                                clearIcon={
                                  <ClearIcon
                                    sx={{ color: 'var(--intu-lightGrey)' }}
                                  />
                                }
                                options={values?.tag_options}
                                className="form-select-field"
                                onChange={(e, options) => {
                                  setFieldValue('tags', options);
                                }}
                                onBlur={handleBlur}
                                pb="50px"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'text',
                                      autoComplete: 'off',
                                      endAdornment: (
                                        <>
                                          {isLoadingTags ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                    value={values?.tag_options}
                                    onChange={async (e) => {
                                      let input = e.target.value;
                                      setFieldValue('tag_search', input);

                                      if (input.length > 2) {
                                        setIsLoadingTags(true);
                                        let tagSearch = setTimeout(async () => {
                                          try {
                                            const response =
                                              await searchPostTags(input, auth);
                                            const { data, status } = response;
                                            if (status === 'success') {
                                              setFieldValue(
                                                'tag_options',
                                                data,
                                              );
                                            }
                                            setIsLoadingTags(false);
                                          } catch (err) {
                                            setIsLoadingTags(false);
                                          }
                                          clearTimeout(tagSearch);
                                        }, 100);
                                      }
                                    }}
                                    placeholder={t(
                                      'form.post.details.tags_placeholder',
                                    )}
                                    label={t('form.post.details.tags_label')}
                                    error={
                                      touched?.tags && errors?.tags
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched?.tags && errors?.tags
                                        ? errors?.tags
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Stack>
                          </Grid>
                          {/* row 1 - Column 2 */}
                          <Grid item xs={12} md={4}>
                            <Typography variant="h5">
                              {t('context.blog.post.settings_title')}
                            </Typography>
                            <Stack direction="column" spacing={2}>
                              {/* Action Buttons and Status */}
                              <Stack direction="column" spacing={2}>
                                {/* Status */}
                                <TextField
                                  required
                                  select
                                  type="text"
                                  id="status"
                                  label={t('form.post.settings.status_label')}
                                  name="status"
                                  variant="outlined"
                                  value={values?.status ? values.status : ''}
                                  onChange={(e) => {
                                    if (formik.isValid === true) {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    } else if (e.target.value === 'published') {
                                      const errorFields =
                                        Object.keys(errors).join(', ');

                                      setDialogTitle(
                                        t('form.post.settings.dialog.title'),
                                      );
                                      setDialogMsg(
                                        t('form.post.settings.dialog.msg', {
                                          errors: errorFields,
                                        }),
                                      );
                                      setDialogOpen(true);
                                      setFieldValue('status', 'draft');
                                    } else {
                                      setFieldValue('status', e.target.value);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    errors.status && touched.status
                                      ? true
                                      : false
                                  }
                                >
                                  <MenuItem key={0} value="" disabled>
                                    {t('form.post.settings.status_select')}
                                  </MenuItem>
                                  <MenuItem key={1} value="draft">
                                    {t('form.post.settings.status_draft')}
                                  </MenuItem>
                                  <MenuItem key={2} value="published">
                                    {t('form.post.settings.status_published')}
                                  </MenuItem>
                                  <MenuItem key={3} value="archived">
                                    {t('form.post.settings.status_archive')}
                                  </MenuItem>
                                </TextField>
                                {/* Action Buttons */}
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const update = updatePost(values, formik);
                                    if (update.status === 'error ') {
                                      resetForm();
                                    } else {
                                      setTouched({}, false);
                                    }
                                  }}
                                  endIcon={<UploadIcon />}
                                >
                                  {transButtons('update', {
                                    type: transTypes('post'),
                                  })}
                                </Button>
                                {/* Preview Post */}
                                <Button
                                  variant="contained"
                                  disabled={
                                    !values.title || !values.description
                                  }
                                  color="info"
                                  onClick={() =>
                                    handleEditPost(false, formik.values)
                                  }
                                  endIcon={<RemoveRedEyeIcon />}
                                >
                                  {transButtons('preview')}
                                </Button>
                                {/* Close Edit Mode */}
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => {
                                    const hasKeys =
                                      !!Object.keys(touched).length;
                                    if (hasKeys) {
                                      setCloseDialogOpen(true);
                                    } else {
                                      navigate(-1);
                                    }
                                  }}
                                  endIcon={<CancelIcon />}
                                >
                                  {t('buttons.close')}
                                </Button>
                                <CloseDialog />
                              </Stack>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* Editor Component */}
                      <Divider
                        sx={{ margin: '20px 0 20px 0' }}
                        color="#a8c957"
                      />
                      <Typography variant="h5">
                        {t('context.blog.post.content')}
                      </Typography>
                      <IntuPageBuilder />
                      {/* Errors:
                      {JSON.stringify(formik.errors)} */}
                    </FormControl>
                  </Form>
                </Grid>
              </Grid>
            </Container>
          </Container>
        );
      }}
    </Formik>
  );
};

export { PostEditForm };
