import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import SignUpPicture from '../../assets/how-it-works/HowItWorksSignUp.png';
import { useTranslation, Trans } from 'react-i18next';
import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LeadContext } from '../../context/providers/leads/LeadContextProvider.jsx';
import PreLoadContext from '../../hooks/usePreLoadHandler.js';

import HowItWorksSignUpForm from './forms/HowItWorksSignUpForm.jsx';
import LeadContent from './LeadContent/LeadContent.jsx';
import ResponseContext from '../../context/providers/ResponseProvider.jsx';
import { StatusContent } from '../../components/status/StatusContent.jsx';

const HowItWorksLeadsContent = () => {
  const { isLoading } = useProcessingHandler();
  const navigate = useNavigate();
  const { leadInfo } = useContext(LeadContext);
  const { statusContext } = useContext(ResponseContext);

  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.how_it_works_context',
  });

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isLoading.status === true ? (
        <PreLoadContext />
      ) : statusContext.open === true ? (
        <StatusContent />
      ) : leadInfo?.lead_type === 'none' || leadInfo?.lead_type === '' ? (
        <Grid
          container
          direction={{ xs: 'column-reverse', md: 'row', height: '100%' }}
          textAlign={{ xs: 'center', md: 'left' }}
          alignItems="flex-start"
          mt="65px"
          mb={small ? '124px' : '0'}
        >
          <Grid
            item
            container
            className="intu__header-image"
            xs={12}
            md={6}
            sx={{
              marginTop: 'auto',
              bottom: 0,
              padding: '3rem 2rem 0 2rem',
            }}
            direction="column"
            justifyContent="flex-end"
          >
            <img
              src={SignUpPicture}
              alt={t('context.howItWorks.learn_more_context.learn_more_title')}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            direction="row"
            alignContent="flex-start"
            sx={{ padding: '3rem 2rem 2rem 2rem' }}
          >
            <Grid item xs={12}>
              <Typography variant="h1">
                <Trans i18nKey="context.howItWorks.learn_more_context.learn_more_title" />
              </Typography>
              <Typography variant="body">
                <Trans i18nKey="context.howItWorks.learn_more_context.learn_more_desc" />
              </Typography>
              <Divider sx={{ marginBottom: '2rem' }} />
            </Grid>

            {/* Online Meeting */}
            <Grid item xs={12} sx={{ paddingBottom: '2rem' }}>
              <Typography variant="h4">
                <Trans i18nKey="components.hubSpotMeeting.title" />
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => navigate('/meetUsOnline')}
              >
                <Trans i18nKey="buttons.meet_in_person" />
              </Button>
            </Grid>

            {/* HowIt Works form */}
            <Grid item xs={12}>
              <HowItWorksSignUpForm />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <LeadContent />
      )}
    </>
  );
};

export { HowItWorksLeadsContent };
