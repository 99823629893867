import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, useTheme } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import { ReactComponent as DocSVG } from '../../assets/new-product/doc-image.svg';
import IntuIconButton from '../buttons/IntuIconButton';

export const getFileName = (file) =>
  file.substr(file.lastIndexOf('/') + 1, file.length - 1);

const renderPDF = (fileURL) => {
  if (fileURL.indexOf('.pdf') !== -1) {
    return (
      <embed
        src={`${fileURL}#page=1`}
        type="application/pdf"
        width="100%"
        height="100px"
      />
    );
  }
  return null;
};

const ProductFiles = ({
  files,
  handleDeleteFile,
  setIsUploadDocumentDialogOpen,
}) => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="p" fontSize="1.2rem" mb={2}>
        {t('components.productFiles.title')}
      </Typography>
      <Box
        minHeight={400}
        width="100%"
        bgcolor="background.default"
        mb={2}
        p={2}
        boxShadow={theme.shadows[6]}
        borderRadius="5px"
      >
        {Array.isArray(files) && files.length ? (
          <>
            {files.map((file) => {
              return (
                <Box
                  key={file}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <DocSVG width="50px" height="50px" />
                  </Box>
                  <Box display="flex">
                    <Typography
                      variant="p"
                      fontSize="12px"
                      color="primary"
                      mx={1}
                    >
                      {getFileName(file)}
                    </Typography>
                    <IntuIconButton
                      aria-label="delete file"
                      size="small"
                      type="cancel"
                      variant="outlined"
                      tooltipTitle={transButtons('delete')}
                      onClick={() => handleDeleteFile(file)}
                      IconComponent={DeleteIcon}
                    />
                  </Box>
                  {/* {renderPDF(file)} */}
                </Box>
              );
            })}
          </>
        ) : null}
      </Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        startIcon={<FileUploadIcon />}
        onClick={() => setIsUploadDocumentDialogOpen(true)}
        sx={{ textTransform: 'none' }}
      >
        {transButtons('upload_doc')}
      </Button>
    </Box>
  );
};

export default ProductFiles;
