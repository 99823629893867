import { Button, Grid, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobeHero } from '../../../animations/globe/GlobeHero.jsx';
import BrandsInfiniteSliderContainer from '../../../containers/brandsInfiniteSliderContainer/BrandsInfiniteSliderContainer.jsx';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import i18n from '../../../i18n.js';
import { useState } from 'react';
import { VideoPlayer } from '../../../plugins/VideoPlayer.js';

const HomeHeaderContainer = () => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');

  const [isOpen, setIsOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const fName = searchParams.get('fName');
  const lName = searchParams.get('lName');
  const cName = searchParams.get('cName');

  function handleVideoOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <GlobeHero>
      <Grid
        className="hero-container"
        container
        direction="column"
        justifyContent="space-between"
        padding="1rem 0 1rem 0"
        textAlign="center"
      >
        {/* Main Header Content */}
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ flexGrow: 1 }}
        >
          {fName && (
            <Grid item>
              <Typography variant="body">
                {' '}
                {t('components.home.main.header.greeting', { name: fName })}
              </Typography>
            </Grid>
          )}
          <Grid item sx={{ padding: '1rem' }}>
            <Typography variant="h1" sx={{ textTransform: 'uppercase' }}>
              {t('components.home.main.header.title')}
            </Typography>
          </Grid>
          <Grid item sx={{ padding: '1rem' }}>
            <Typography
              variant="h3"
              color="white"
              sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } }}
            >
              {t('components.home.main.header.statement')}
            </Typography>
          </Grid>

          {/* Video */}
          <Grid
            item
            container
            direction="row"
            spacing={{ xs: 2, md: 12 }}
            justifyContent="center"
          >
            <Grid item>
              <Button
                variant="contained"
                startIcon={<PlayCircleOutlineIcon />}
                sx={{ borderRadius: '50px' }}
                onClick={handleVideoOpen}
              >
                {transButtons('view_video')}
              </Button>

              <VideoPlayer
                modal={true}
                open={isOpen}
                playing={true}
                onClose={() => setIsOpen(false)}
                videoSource="https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/mfg_explainer_en.mp4"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Brands Section at Bottom */}
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          sx={{ paddingBottom: '5rem' }}
        >
          <Grid item>
            <Typography variant="h5" color="white">
              {t('components.home.main.header.brands_title', {
                name: cName ? cName : i18n.t('your_company', { ns: 'common' }),
              })}
            </Typography>
          </Grid>
          <Grid
            item
            container
            sx={{
              width: '100vw',
              overflow: 'hidden',
              margin: '0',
              padding: '0',
            }}
          >
            <BrandsInfiniteSliderContainer initialDelay={2} />
          </Grid>
        </Grid>
      </Grid>
    </GlobeHero>
  );
};

export default HomeHeaderContainer;
