import { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_usaHigh from '@amcharts/amcharts5-geodata/usaHigh';
import am5geodata_usaCountiesLow from '@amcharts/amcharts5-geodata/region/usa/usaCountiesLow';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Box, useTheme } from '@mui/material';

function USAMap({
  onRegionSelect,
  selectedRegions = [],
  availableRegions = [],
  mode = 'state',
  height = '300px',
}) {
  const theme = useTheme();
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const root = am5.Root.new('USAMap');
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        projection: am5map.geoAlbersUsa(),
        panX: 'translateX',
        panY: 'translateY',
        maxZoomLevel: 16,
        minZoomLevel: 1,
      }),
    );

    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON:
          mode === 'state' ? am5geodata_usaHigh : am5geodata_usaCountiesLow,
        valueField: 'value',
        calculateAggregates: true,
      }),
    );

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name}',
      interactive: true,
      fill: am5.color(0xaaaaaa),
      strokeWidth: 1,
      stroke: am5.color(0xffffff),
      cursorOverStyle: 'pointer',
      opacity: 1,
    });

    polygonSeries.events.on('datavalidated', function () {
      selectedRegions.forEach((id) => {
        const dataItem = polygonSeries.getDataItemById(id);
        if (dataItem && dataItem.get('mapPolygon')) {
          dataItem
            .get('mapPolygon')
            .set('fill', am5.color(theme.palette.primary.light));
          dataItem.set('dataContext', {
            ...dataItem.dataContext,
            selected: true,
          });
        }
      });
    });

    // Set up click events
    polygonSeries.mapPolygons.template.events.on('click', (e) => {
      if (!onRegionSelect) return;
      const { dataContext } = e.target.dataItem;

      const found = availableRegions.find((r) =>
        dataContext.id.includes(r?.state.toUpperCase()),
      );

      if (!found && availableRegions.length) return;
      // Toggle selected state
      dataContext.selected = !dataContext.selected;

      // Change color based on selection
      e.target.set(
        'fill',
        dataContext.selected
          ? `${theme.palette.primary.light}`
          : am5.color(0xaaaaaa),
      );

      onRegionSelect({
        name: dataContext.name,
        id: dataContext.id,
        selected: dataContext.selected,
      });
    });

    chartRef.current = root;
    return () => {
      root.dispose();
    };
  }, [mode]);

  useLayoutEffect(() => {
    if (!availableRegions.length) return;
    const chart = chartRef.current.container.children.getIndex(0);
    const polygonSeries = chart.series.getIndex(0);

    const availableStateSet = new Set(
      availableRegions.map((item) => `US-${item?.state?.toUpperCase()}`),
    );

    polygonSeries.mapPolygons.each((polygon) => {
      const stateId = polygon.dataItem.get('id');
      polygon.set('opacity', availableStateSet.has(stateId) ? 1 : 0.2);
    });
  }, [availableRegions]);

  return <Box id="USAMap" width="100%" height={height}></Box>;
}

export default USAMap;
