import { useState, Suspense, useEffect } from 'react';
import { Box, Slider, Stack } from '@mui/material';
import { Canvas } from '@react-three/fiber';
import {
  PerspectiveCamera,
  PresentationControls,
  OrbitControls,
  SpotLight,
  Environment,
  Html,
  useProgress,
  Center,
  useGLTF,
  PivotControls,
} from '@react-three/drei';
import ArrowControlsContainer from './ArrowControlsContainer';
import ColorControlsContainer from './ColorControlsContainer';

const CanvasLoader = () => {
  const { progress } = useProgress();
  return (
    <Html
      as="div"
      center
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <span className="canvas-loader"></span>
      <p
        style={{
          fontSize: 14,
          color: '#F1F1F1',
          fontWeight: 800,
          marginTop: 40,
        }}
      >
        {progress.toFixed(2)}%
      </p>
    </Html>
  );
};

const GLTFModel = ({ rotationX, rotationY, image, handleSceneLoaded }) => {
  const { scene } = useGLTF(image);

  useEffect(() => {
    if (scene && scene.visible) {
      handleSceneLoaded(scene);
    }
  }, [scene]);

  return (
    <primitive
      object={scene}
      receiveShadow
      position={[0, 10, -50]}
      rotation={[rotationY / 10, rotationX / 10, 0]}
      height={500}
    />
  );
};

const CanvasContainer = ({ image }) => {
  const [rotationX, setRotationX] = useState(0);
  const [rotationY, setRotationY] = useState(0);
  const [cameraPosition, setCameraPosition] = useState([0, 0, 50]);
  const [meshes, setMeshes] = useState([]);
  const [showingArray, setShowingArray] = useState([]);

  const handleRotationXSliderChange = (e) => {
    setRotationX(e.target.value);
  };

  const handleRotationYSliderChange = (e) => {
    setRotationY(e.target.value);
  };

  const handleSceneLoaded = (scene) => {
    let meshObjects = [];
    scene.traverse((child) => {
      if (child.isMesh && child.name) {
        meshObjects.push(child);
      }
    });
    setMeshes(meshObjects);
    setShowingArray(Array.from({ length: meshObjects.length }, () => false));
  };

  return (
    <Box display="flex" flexDirection="column" height={500} alignItems="center">
      <Canvas shadows style={{ minHeight: '500px' }}>
        {/* <color attach="background" args={['#eee']} /> */}
        <Suspense fallback={<CanvasLoader />}>
          <GLTFModel
            image={image}
            rotationX={rotationX}
            rotationY={rotationY}
            handleSceneLoaded={handleSceneLoaded}
          />
          <OrbitControls maxDistance={200} />
          <ambientLight intensity={10} />
          <PerspectiveCamera position={cameraPosition} makeDefault />
          {/* <PivotControls rotation={[0, 0, rotation]} /> */}
        </Suspense>
      </Canvas>
      <Slider
        defaultValue={0}
        type="range"
        min={0}
        max={63}
        value={rotationX}
        onChange={handleRotationXSliderChange}
        sx={{ width: '50%', bottom: 60 }}
        position="absolute"
      />
      <Stack sx={{ height: 400 }} spacing={1} direction="row">
        <Slider
          defaultValue={0}
          type="range"
          min={0}
          max={63}
          value={rotationY}
          onChange={handleRotationYSliderChange}
          sx={{ bottom: '35vh', right: '-30vw', width: '20%', height: '20vh' }}
          orientation="vertical"
        />
      </Stack>
      {/* <ArrowControlsContainer setCameraPosition={setCameraPosition} /> */}
      <ColorControlsContainer
        meshes={meshes}
        setMeshes={setMeshes}
        showingArray={showingArray}
        setShowingArray={setShowingArray}
      />
    </Box>
  );
};

export default CanvasContainer;
