import React, { useContext } from 'react';
import FormikFieldWrapper from '../../../formik/FormikFieldWrapper';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Skeleton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../../helpers/getNestedValuesIn';
import { CompanyProfileContext } from '../../../../context/providers/CompanyProfileProvider';

const EmployeeRoleField = ({
    fieldTitle, // Title above the field
    fieldDescription, // Field description above the field
    fieldID = 'role', // Defines the ID and Name of the Field
    transition = true, // Whether the field is being shown and faded in
    disabled = false, // Diables the field
    required = false, // Whether the field is required or not
    variant = 'outlined', //MUI variant
    transNS = 'fields', // Namespace for translation Value
    transPrefix = 'EmployeeRoleField', // KeyPrefix which holds label and placeholder
}) => {
    // Translation
    const { t } = useTranslation(transNS, {
        keyPrefix: transPrefix,
    });
    const { values, touched, errors, handleBlur, setValues } =
        useFormikContext();
    const { companyProfile } = useContext(CompanyProfileContext)
    const fieldValue = getNestedValue(values, fieldID);
    const fieldError = getNestedValue(errors, fieldID);
    const fieldTouched = getNestedValue(touched, fieldID);

    const { isLoading } = useContext(ProcessingContext);

    return isLoading.status ? (
        <Skeleton variant="rectangular" width={'100%'} height={60} />
    ) : (
        <FormikFieldWrapper
            fieldTitle={fieldTitle}
            fieldDescription={fieldDescription}
            transition={transition}
        >
            <FormControl fullWidth={true}>
                <InputLabel>{t('label')}</InputLabel>
                <Select
                    labelId="role-label"
                    required={required}
                    disabled={disabled}
                    id={fieldID}
                    name={fieldID}
                    aria-invalid={errors[fieldID] ? true : false}
                    aria-describedby="uidnote"
                    variant={variant}
                    type="text"
                    label={t('label')}
                    notched
                    onChange={(e) => {
                        setValues({
                            ...values,
                            role: e.target.value,
                            employee_role_change: true,
                        });
                    }}
                    onBlur={handleBlur}
                    value={fieldValue}
                    error={fieldError && fieldTouched ? true : false}
                    helperText={fieldError && fieldTouched ? fieldError : null}
                >
                    <MenuItem className="input-light" key={0} value="">
                        {t('options.select')}
                    </MenuItem>
                    <MenuItem className="input-light" key={1} value="associate">
                        {t('options.associate')}
                    </MenuItem>
                    <MenuItem className="input-light" key={2} value="super-user">
                        {t('options.super_user')}
                    </MenuItem>
                    <MenuItem className="input-light" key={3} value="admin">
                        {t('options.admin')}
                    </MenuItem>
                    <MenuItem className="input-light" key={4} value="remove">
                        {t('options.remove')}
                    </MenuItem>
                    {companyProfile.employees.length > 1 && (
                        <MenuItem className="input-light" key={5} value="blocked">
                            {t('options.blocked')}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </FormikFieldWrapper>
    )
}

export default EmployeeRoleField;