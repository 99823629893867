import * as React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import RequireAuth from './components/routes/RequireAuth';
import useUser from './hooks/useUser';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

// Meta Tag Handling
import { Helmet } from 'react-helmet';

// Theming and Styling
import './app.css';

// Pages
import {
  SignUpSuccess,
  Missing,
  Unauthorized,
  Home,
  PrivacyPolicy,
  ContactUs,
  AboutCompany,
  SignUp,
  AdminDashboard,
  UserDashboard,
  UserProfile,
  CompanyProfile,
  AccountResetPassword,
  AccountChangePassword,
  IntuUsers,
  IntuLeads,
  HowItWorksLeads,
  HowItWorksInvestors,
  CompanyCampaigns,
  Blog,
  IntuContacts,
  Post,
  PostCreate,
  UserPosts,
  Subscribers,
  SignIn,
  Products,
  AccountConfirm,
  SignUpComplete,
  CompanySalesTeam,
  CompanyLeads,
  AdminProducts,
  IntuSalesEvents,
  IntuVendorEvents,
  IntuManageEvents,
  NewProductPage,
  MeetUsOnlinePage,
  TermsOfUse,
  CreditsExplainer,
  UserPublicProfile,
  CompanyPublicProfile,
  PublicProductPage,
  SalesHome,
  MfgHome,
  FooterComponent,
} from './pages';

// Translator
import i18n from './i18n';

// Import Available Languages
import CountryData from './data/CountryData.json';
import { useEffect, useContext } from 'react';
import ProfileFormLayoutComponent from './components/layout/ProfileFormLayout';
import GlobalLayout from './components/layout/GlobalLayout';
import CheckAuth from './components/routes/CheckAuth';
import NonAuthLayout from './components/layout/NonAuthLayout';
import AuthLayout from './components/layout/AuthLayout';
import { useAnalyticsPageViewTracker } from './hooks/useAnalyticsTracker';
import { LayoutBodyContext } from './context/providers/LayoutContextProvider';
import FindBrandsPage from './pages/find/FindBrandsPage';
import Chat from './pages/chat/Chat';
import FindSalesRepsPage from './pages/find/FindSalesRepsPage';
import { trackLinkedInConversion } from './helpers/trackLinkedInConversion';

const App = () => {
  const { user } = useUser();

  //Detects User's country and sets displayed language
  useEffect(() => {
    if (!user) {
      return;
    }

    const userCountry = CountryData.find(
      (country) => country.code === user.location.country,
    );

    if (process.env.REACT_APP_ENV === 'development') {
      userCountry?.languageCode
        ? i18n.changeLanguage(userCountry.languageCode)
        : i18n.changeLanguage('en');
    }

    // Track Page Visit
    trackLinkedInConversion(18916850);
  }, [user]);

  // Google Page Tracker
  const location = useLocation();
  const { layoutTitle } = useContext(LayoutBodyContext);
  const { gaPageViewTracker } = useAnalyticsPageViewTracker();

  useEffect(() => {
    document.title = layoutTitle || 'IN2TEC - Distribution 2.0';

    gaPageViewTracker({
      type: 'pageview',
      page: document.location.pathname,
      name: `Page Visit - ${layoutTitle || document.location.pathname}`,
    });

    window.scrollTo(0, 0);
  }, [layoutTitle, location, gaPageViewTracker]);

  return (
    <>
      {/* TO DO -> Make public pages server side rendered and remove HELMET */}
      <Helmet>
        <title>IN2TEC - Distribution 2.0</title>
        <meta
          name="description"
          content="We connect technology brands in process automation, warehouse automation to sales professionals and influencers with the goal of bringing great products and solutions directly in front of your customers."
        />
        <meta name="theme-color" content="#A8C957" />
        <meta
          property="og:title"
          content="B2B Marketplace for sophisticated technology products."
        />
        <meta property="og:image" content="//intutec.io/intu512.png" />
        <meta
          property="og:description"
          content="Build your own network of sales rep's and influencers in in process- and warehouse automation, food & beverage, medical and more..."
        />
        <meta property="og:url" content="//intutec.io" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@IN2TEC" />
        <meta
          name="twitter:title"
          content="Build your own network of sales rep's and influencers in in process- and warehouse automation, food & beverage, medical and more..."
        />
        <meta
          name="twitter:description"
          content="Build your own network of sales rep's and influencers in in process- and warehouse automation, food & beverage, medical and more..."
        />
        <meta name="twitter:image" content="https://intutec.io/intu512.png" />
      </Helmet>
      {/* Nabvbar */}

      {/* Content */}
      <div className="wrapper" id="root">
        {/* Routes */}
        <Routes>
          {/* LinkedIn */}
          <Route
            exact
            path="/linkedin-callback"
            element={<LinkedInCallback />}
          />
          <Route element={<CheckAuth />}>
            {/* CONTENT */}
            <Route path="/" element={<GlobalLayout />}>
              {/* < ------ Check for user authentication in order to show the correct nav items------> */}
              {/* < ------ NON-AUTH (PUBLIC) LAYOUTS ------> */}
              <Route path="/" element={<NonAuthLayout />}>
                {/* < ------ PAGES ------> */}
                {/* Home */}
                <Route path="/" element={<Home />} />
                <Route path="/saleshome" element={<SalesHome />} />
                <Route path="/mfghome" element={<MfgHome />} />

                {/* Privacy & Tos */}
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="terms-of-use" element={<TermsOfUse />} />
                <Route path="products" element={<Products />} />
                <Route path="credits" element={<CreditsExplainer />} />

                {/* Events */}
                <Route path="events/sales" element={<IntuSalesEvents />} />
                <Route path="events/vendors" element={<IntuVendorEvents />} />

                {/* Meet us Online */}
                <Route path="meetUsOnline" element={<MeetUsOnlinePage />} />

                {/* About the Company */}
                <Route path="company" element={<AboutCompany />} />

                {/* Blog */}
                <Route path="blog" element={<Blog />} />
                <Route path="blog/:id" element={<Post />} />

                {/* Not authorized*/}
                <Route path="unauthorized" element={<Unauthorized />} />

                {/* Catch Not Found */}
                <Route path="*" element={<Missing />} />

                {/* < ------ FORMS ------> */}
                {/* How it works */}
                <Route path="how-it-works" element={<HowItWorksLeads />} />
                <Route path="investor" element={<HowItWorksInvestors />} />

                {/* sign in */}
                <Route path="account/signin" element={<SignIn />} />

                {/* Password Reset */}
                <Route
                  path="account/reset-password"
                  element={<AccountResetPassword />}
                />

                {/* Password change */}
                <Route
                  path="account/change-password"
                  element={<AccountChangePassword />}
                />

                {/* SignUp */}
                <Route path="account/signup" element={<SignUp />} />

                {/* Contact Us */}
                <Route path="contact" element={<ContactUs />} />

                {/* SignUp Confirm */}
                <Route path="account/confirm" element={<AccountConfirm />} />

                {/* subscribers */}
                <Route
                  path="subscribers/signup"
                  element={<Subscribers mode="signup-" source="direct" />}
                />
                <Route
                  path="subscribers/confirm"
                  element={<Subscribers mode="confirm" />}
                />
                <Route
                  path="subscribers/verify"
                  element={<Subscribers mode="verify" />}
                />
                <Route
                  path="subscribers/profile"
                  element={<Subscribers mode="profile" />}
                />

                <Route path="product/:id" element={<PublicProductPage />} />
              </Route>
              {/* < ------ AUTH (PROTECTED) LAYOUTS------> */}
              <Route path="/" element={<AuthLayout />}>
                {/* <----- Protected Routes for Admins ----->*/}

                <Route element={<RequireAuth allowedRoles={['admin']} />}>
                  <Route
                    path="app/admin/dashboard"
                    element={<AdminDashboard />}
                  />
                  <Route
                    path="app/admin/manage-users"
                    element={<IntuUsers />}
                  />
                  <Route
                    path="app/admin/manage-products"
                    element={<AdminProducts />}
                  />
                  <Route
                    path="app/admin/manage-leads"
                    element={<IntuLeads />}
                  />
                  <Route
                    path="app/admin/manage-contacts"
                    element={<IntuContacts />}
                  />
                  <Route
                    path="app/admin/manage-events"
                    element={<IntuManageEvents />}
                  />
                  <Route path="app/admin/posts" element={<UserPosts />} />
                </Route>

                {/* <----- Protected Routes for Users ----->*/}
                <Route
                  element={<RequireAuth allowedRoles={['user', 'admin']} />}
                >
                  <Route
                    path="app/user/dashboard"
                    element={<UserDashboard />}
                  />
                  <Route path="app/user/posts" element={<UserPosts />} />
                  <Route path="account-completed" element={<SignUpSuccess />} />

                  <Route
                    path="app/company/campaigns"
                    element={<CompanyCampaigns />}
                  />
                  <Route
                    path="app/company/salesteam"
                    element={<CompanySalesTeam />}
                  />
                  <Route
                    path="app/company/products"
                    element={<AdminProducts />}
                  />
                  <Route path="app/company/leads" element={<CompanyLeads />} />

                  <Route path="/" element={<ProfileFormLayoutComponent />}>
                    <Route path="app/user/profile" element={<UserProfile />} />
                    <Route
                      path="app/company/profile"
                      element={<CompanyProfile />}
                    />
                    <Route
                      path="app/account/complete"
                      element={<SignUpComplete />}
                    />
                  </Route>

                  {/* Blog */}
                  <Route path="app/blog/create" element={<PostCreate />} />
                  <Route path="app/blog/edit/:id" element={<Post />} />

                  {/* Products */}
                  <Route
                    path="app/manage-products/list"
                    mode="list"
                    element={<AdminProducts />}
                  />

                  <Route
                    path="app/new-product/:id"
                    element={<NewProductPage />}
                  />
                  <Route
                    path="app/edit-product/:id"
                    element={<NewProductPage isEdit />}
                  />

                  {/* Public Profiles */}
                  <Route
                    path="app/user-profile/:id"
                    element={<UserPublicProfile />}
                  />
                  <Route
                    path="app/company-profile/:id"
                    element={<CompanyPublicProfile />}
                  />

                  {/* Find Brands */}
                  <Route path="app/find/brands" element={<FindBrandsPage />} />

                  {/* Find Sales Reps */}
                  <Route
                    path="app/find/salesreps"
                    element={<FindSalesRepsPage />}
                  />

                  {/* Chat Page */}
                  <Route path="app/chat" element={<Chat />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default App;
