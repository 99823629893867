import React from 'react';
import { Grid, Typography, Container } from '@mui/material';
import { FiCard, FiCardContent, FiCardMedia } from '../mui/FullImageCards';

import HeavyMachinery from '../../assets/industries/heavy_machinery.png';
import WarehouseAutomation from '../../assets/industries/warehouse_automation.png';
import MedicalPharmaceutical from '../../assets/industries/medical_pharmaceutical.png';
import FoodBeverage from '../../assets/industries/food_beverage.png';

import Automotive from '../../assets/industries/automotive.png';
import Aviation from '../../assets/industries/aviation.png';
import Maritime from '../../assets/industries/maritime.png';
import { useTranslation } from 'react-i18next';

const IndustriesOverviewComponent = (props) => {
  const { t } = useTranslation('common');

  return (
    <Container maxWidth="auto" disableGutters {...props}>
      <Grid container spacing={0} direction={{ xs: 'column', md: 'row' }}>
        <Grid item xs={12} md={6}>
          <FiCard sx={{ width: '100%', height: '288px', borderRadius: '0' }}>
            <FiCardMedia
              media="picture"
              alt={t('process_automation')}
              image="https://in2tec.nyc3.cdn.digitaloceanspaces.com/industries/processAutomation.jpeg"
              title={t('process_automation')}
              sx={{ width: '100%', height: '288px' }}
            />
            <FiCardContent sx={{ width: '100%', height: '288px' }}>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                color="white"
              >
                {t('process_automation')}
              </Typography>
            </FiCardContent>
          </FiCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <FiCard sx={{ width: '100%', height: '288px', borderRadius: '0' }}>
            <FiCardMedia
              media="picture"
              alt={t('machinery')}
              image="https://in2tec.nyc3.cdn.digitaloceanspaces.com/industries/machinery.jpeg"
              title={t('machinery')}
              sx={{ width: '100%', height: '288px' }}
            />
            <FiCardContent sx={{ width: '100%', height: '288px' }}>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                color="white"
              >
                {t('machinery')}
              </Typography>
            </FiCardContent>
          </FiCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <FiCard sx={{ width: '100%', height: '288px', borderRadius: '0' }}>
            <FiCardMedia
              media="picture"
              alt={t('food_beverage')}
              image={FoodBeverage}
              title={t('food_beverage')}
              sx={{ width: '100%', height: '288px' }}
            />
            <FiCardContent sx={{ width: '100%', height: '288px' }}>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                color="white"
              >
                {t('food_beverage')}
              </Typography>
            </FiCardContent>
          </FiCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <FiCard sx={{ width: '100%', height: '288px', borderRadius: '0' }}>
            <FiCardMedia
              media="picture"
              alt={t('medical_pharmaceutical')}
              image={MedicalPharmaceutical}
              title={t('medical_pharmaceutical')}
              sx={{ width: '100%', height: '288px' }}
            />
            <FiCardContent sx={{ width: '100%', height: '288px' }}>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                color="white"
              >
                {t('medical_pharmaceutical')}
              </Typography>
            </FiCardContent>
          </FiCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <FiCard sx={{ width: '100%', height: '288px', borderRadius: '0' }}>
            {/* <FiCardActionArea> */}
            <FiCardMedia
              media="picture"
              alt={t('warehouse_automation')}
              image={WarehouseAutomation}
              title={t('warehouse_automation')}
              sx={{ width: '100%', height: '288px' }}
            />
            <FiCardContent sx={{ width: '100%', height: '288px' }}>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                color="white"
              >
                {t('warehouse_automation')}
              </Typography>
            </FiCardContent>
          </FiCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <FiCard sx={{ width: '100%', height: '288px', borderRadius: '0' }}>
            <FiCardMedia
              media="picture"
              alt={t('heavy_equipment')}
              image={HeavyMachinery}
              title={t('heavyequipmenty')}
              sx={{ width: '100%', height: '288px' }}
            />
            <FiCardContent sx={{ width: '100%', height: '288px' }}>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                color="white"
              >
                {t('heavy_equipment')}
              </Typography>
            </FiCardContent>
          </FiCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export { IndustriesOverviewComponent };
