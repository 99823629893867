import React, { createContext, useContext, useState } from 'react';
import { Grid, Typography, Chip, Box } from '@mui/material';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';
import AuthContext from '../AuthProvider';
import { PublicProfileContext } from './PublicProfileProvider';
import { searchProductGroup } from '../../../routes/productsRoutes';
import UserContext from '../UserInfoProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IntuThemeContext from '../IntuThemeProvider';
import { searchRelationships } from '../../../routes/relationshipsRoutes';
import useUser from '../../../hooks/useUser';

export const CompanyPublicProfileContext = createContext();

export const CompanyPublicProfileProvider = ({ children }) => {
  const { handleGetBrands, handleGetApplications, handleGetProductTypes } =
    useContext(PublicProfileContext);
  const { user } = useUser();
  const [publicCompanyInfo, setPublicCompanyInfo] = useState({});
  const [companyOpportunites, setCompanyOpportunities] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [companyOrderItems, setCompanyOrderItems] = useState([]);
  const [companyProducts, setCompanyProducts] = useState([]);

  const { auth } = useContext(AuthContext);
  const { getConversations } = useContext(UserContext);
  const { mobileView } = useContext(IntuThemeContext);
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.PublicProfile',
  });

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  // SetUp Axios
  const axios = useAxiosPrivate();

  async function handleGetAssociates(associatesById) {
    // Guard Clause
    if (!auth.auth_info || associatesById?.length === 0) {
      return;
    }
    const url = '/api/users/associates';
    const payload = {
      key: '_id',
      values: associatesById,
    };
    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });

      const { data, status } = response;
      const info = data.data;

      if (status === 200) {
        const associateInfo = info.map((associate) => {
          let image = '';
          if (associate?.profile_picture?.mimetype) {
            image = `data:${associate.profile_picture.mimetype};base64,${associate.profile_picture.buffer.toString('base64')}`;
          }
          return {
            _id: associate._id,
            applications: associate.applications,
            first_name: associate.first_name,
            last_name: associate.last_name,
            address: associate.address,
            profile_picture: image,
          };
        });
        return associateInfo;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      handleErrorResponse(error);
    }
  }

  async function handleSearchRelationships(id) {
    const payload = {
      key: 'manufacturer',
      value: [id],
    };
    try {
      const response = await searchRelationships(payload);
      const { data, status } = response;
      if (status === 'success') {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      handleErrorResponse(error);
    }
  }

  async function getProductGroup(productGroupId) {
    if (!productGroupId) {
      return;
    }

    const payload = {
      key: '_id',
      values: [productGroupId],
    };

    try {
      const { data, status } = await searchProductGroup(payload);

      if (status === 'success') {
        return data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      handleErrorResponse(error);
    }
  }

  // Handle Get Company Info
  async function getCompanyPublicProfile(id) {
    // Guard Clause
    if (!id && !auth.user_info && !auth.auth_info) {
      return;
    }

    const payload = {
      _id: id,
    };

    const url = `/api/companies/company/public/`;
    try {
      const { data, status } = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });

      const info = data.data;
      if (status === 200) {
        // Format Image
        let image = '';
        if (info?.profile_picture?.mimetype) {
          image = `data:${info.profile_picture.mimetype};base64,${info.profile_picture.buffer.toString('base64')}`;
        }
        const date = new Date(info.created.date).toLocaleDateString();
        const companyData = {
          _id: info._id,
          name: info?.name,
          email: info?.email,
          address: info?.address,
          phone: info?.phone,
          type: info?.type,
          brands:
            info?.brands?.length > 0 ? await handleGetBrands(info.brands) : [],
          website: info?.website,
          colors: info?.colors,
          applications:
            (info?.applications.length > 0 &&
              (await handleGetApplications(info.applications))) ||
            [],
          product_types:
            (info?.product_types.length > 0 &&
              (await handleGetProductTypes(info.product_types))) ||
            [],
          intro: info?.intro,
          tow: info?.tow,
          tos: info?.tos,
          commission_schedule: info?.commission_schedule,
          profile_picture: info?.profile_picture,
          profile_picture_view_url: image,
          associates:
            info?.associates?.length > 0
              ? await handleGetAssociates(info.associates)
              : [],
          created: date,
        };
        setPublicCompanyInfo(companyData);
        return companyData;
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    }
  }

  async function applyToRepresent(newApplication, id) {
    const url = `/api/chat/apply-to-represent`;
    const associate_accepted = {
      accepted: true,
      ip: user.location.ip,
      date: new Date(),
      unixTimeStamp: Math.floor(Date.now() / 1000),
      agent: navigator.userAgent,
    };
    const payload = {
      ...newApplication,
      id,
      chat_title: `Representation Request ${auth.user_info.full_name}`,
      associate_accepted,
    };

    try {
      const { status } = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      if (status === 200) {
        getConversations();
      }
    } catch (error) {
      console.error('error', error);
    }
  }

  async function getAssociateIndustries(associates) {
    if (!associates) {
      return;
    }
    try {
      const newAssociateData = await Promise.all(
        associates?.map(async (associate) => {
          const industry = await handleGetApplications(associate.applications);
          return {
            ...associate,
            industry,
          };
        }),
      );
      return newAssociateData;
    } catch (error) {
      console.error(error);
      handleErrorResponse(error);
    }
  }

  const associateColumns = [
    {
      field: 'profile_picture',
      headerName: 'Profile Picture',
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <img
              src={row.profile_picture}
              alt=""
              style={{
                objectFit: 'contain',
                height: '100%',
                width: '100%',
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 1,
      editable: false,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 1,
      editable: false,
    },
    {
      field: 'applications',
      headerName: 'Applications',
      sortable: false,
      flex: 4,
      renderCell: ({ row }) => {
        return (
          <Grid container alignItems="center">
            {row.industry.map((industry) => {
              return (
                <Grid item key={industry.application_id} mr={0.5} mt={0.5}>
                  <Chip
                    label={industry.application_name}
                    key={industry.application_id}
                  />
                </Grid>
              );
            })}
          </Grid>
        );
      },
    },
    {
      field: 'region',
      headerName: t('region'),
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Typography>
              {row.address.city}, {row.address.state}
            </Typography>
          </Grid>
        );
      },
    },
  ];

  const productColumns = [
    {
      field: 'product_name',
      headerName: t('product_name'),
      flex: 3,
      editable: false,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Grid container alignItems="center">
            <Grid item sx={{ display: mobileView ? 'none' : 'flex' }}>
              <img src={row.product_image} alt="" />
            </Grid>
            <Grid item ml={2}>
              <Typography>{row.product_name}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'mpn',
      headerName: 'MPN',
      flex: 2,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>{row.mpn}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'product_family',
      headerName: t('product_family'),
      flex: 2,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>{row.product_types.product_family}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'product_group',
      headerName: t('product_group'),
      sortable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>{row.product_types.product_group}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'product_applications',
      headerName: t('applications'),
      sortable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Chip label={row.product_applications.application_name} />
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'commission',
      headerName: t('commission'),
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>{row.commission + '%'}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'list_price',
      headerName: t('list_price'),
      sortable: false,
      flex: 1,
    },
  ];

  // Grab ID for row for data grid
  function getRowId(row) {
    return row._id;
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <CompanyPublicProfileContext.Provider
      value={{
        publicCompanyInfo,
        setPublicCompanyInfo,
        getCompanyPublicProfile,
        handleGetAssociates,
        getProductGroup,
        applyToRepresent,
        getAssociateIndustries,
        companyOpportunites,
        setCompanyOpportunities,
        associateColumns,
        getRowId,
        handleClose,
        isOpen,
        setIsOpen,
        companyOrderItems,
        setCompanyOrderItems,
        handleSearchRelationships,
        companyProducts,
        setCompanyProducts,
        productColumns,
      }}
    >
      {children}
    </CompanyPublicProfileContext.Provider>
  );
};
