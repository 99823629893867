import { useContext, useRef } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import {
  Typography,
  Checkbox,
  Link,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { useFormikContext } from 'formik';
import useUser from '../../../hooks/useUser';
import { TermsOfServiceContext } from '../../../context/providers/TermsOfServicecontextProvider';

const TermsOfServiceAgreement = ({ fieldTitle, transition, required }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { user } = useUser();
  const { setDialogOpen, setDialogType, setDialogTitle } = useContext(
    TermsOfServiceContext,
  );
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignUpComplete.TermsOfServiceForm',
  });
  const { t: transTypes } = useTranslation('types');
  const tosRef = useRef();
  return (
    <>
      <FormikFieldWrapper fieldTitle={fieldTitle} transition={transition}>
        <Typography level="body-sm">
          <Trans
            i18nKey="context.account.SignUpComplete.TermsOfServiceForm.tos.description"
            values={{
              accountType: transTypes(values?.account_type),
            }}
            components={[
              <Link
                className="link"
                component="button"
                onClick={() => {
                  setDialogOpen(true);
                  setDialogType('tos');
                  setDialogTitle(t('tos.title'));
                }}
              />,
            ]}
          />
        </Typography>
      </FormikFieldWrapper>
      <FormikFieldWrapper required={required}>
        <FormGroup>
          <FormControlLabel
            checked={values.tos_accepted?.accepted}
            control={
              <Checkbox
                name="tos_accepted"
                checked={values.tos_accepted?.accepted}
                required={required}
                inputProps={{
                  'aria-label': 'controlled',
                }}
                ref={tosRef}
                onChange={(e) => {
                  const consent = e.target.checked;
                  setFieldValue('tos_accepted', {
                    accepted: consent,
                    ip: consent ? user.location.ip : '',
                    date: consent ? new Date() : '',
                    unixTimeStamp: consent ? Math.floor(Date.now() / 1000) : '',
                    agent: consent ? navigator.userAgent : '',
                  });
                  setTimeout(() => {
                    setFieldTouched('tos_accepted', true);
                  }, 300);
                }}
              />
            }
            label={<Typography component="span">{t('tos.label')}</Typography>}
            // Ensuring the label and asterisk stay inline
            sx={{
              alignItems: 'center',
              '& .MuiFormControlLabel-label': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          />
        </FormGroup>
      </FormikFieldWrapper>
    </>
  );
};

export { TermsOfServiceAgreement };
