import React from 'react';
import './home.css';

import { LeadProvider } from '../../context/providers/leads/LeadContextProvider.jsx';
import { SalesHomeContent } from '../../components/home/Sales/SalesHomeContent.jsx';

function SalesHome() {
  return (
    <LeadProvider>
      <SalesHomeContent />
    </LeadProvider>
  );
}

export default SalesHome;
