import { motion } from 'framer-motion';
import { Grid, Button } from '@mui/material';
import { useTheme } from '@mui/styles';

export const ButtonHoverGradiant = ({ label, onClick, ...props }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ margin: '3vh 0' }}
    >
      <motion.div
        variants={containerVariants}
        initial="initial"
        whileHover="hover"
        exit="initial"
      >
        <Button variant="contained" onClick={onClick} {...props}>
          {label.split('').map((letter, i) => {
            return (
              <motion.div
                key={letter + '-' + i}
                variants={buttonVariants}
                custom={theme.intuHoverButtonGradiant}
              >
                {letter === ' ' ? '\u00A0' : letter}
              </motion.div>
            );
          })}
        </Button>
      </motion.div>
    </Grid>
  );
};

const containerVariants = {
  initial: {
    opacity: 1,
  },
  hover: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
    scale: 1.2,
  },
};

const buttonVariants = {
  initial: {
    opacity: 1,
    color: '#333333',
    scale: 1,
    y: 0,
  },
  hover: (colors) => ({
    color: colors,
    y: ['0', '-0.2vh', '0'],
    scale: [1, 1.2, 1],
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  }),
};
