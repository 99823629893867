import { Fragment, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Typography,
  Box,
  Autocomplete,
  ListSubheader,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import USAMap from '../../../components/maps/USAMap';
import CommissionBox from '../../../components/commissions/ComissionBox';

const ApplyDialog = ({ isOpen, handleConfirm, handleClose, companyInfo }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [selectedTerritories, setSelectedTerritories] = useState({
    type: 'state',
    territories: [],
  });
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedApplications, setSelectedApplications] = useState([]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleConfirmClick = () => {
    const newApplication = {
      message,
      applications: selectedApplications.map((i) => i.application_id),
      product_types: selectedProductTypes.map((t) => t.type_id),
      territories: selectedTerritories,
    };

    handleConfirm(newApplication);
    handleClose();
  };

  const handleCloseClick = () => {
    handleClose();
  };

  const onTerritorySelect = (territory) => {
    setSelectedTerritories((prevState) => {
      if (territory.selected) {
        return {
          ...prevState,
          territories: [...prevState.territories, territory.id],
        };
      } else {
        return {
          ...prevState,
          territories: prevState.territories.filter((s) => s !== territory.id),
        };
      }
    });
  };

  const handleProductTypeChange = (e, newValue, reason, details) => {
    const value = newValue[newValue.length - 1];
    if (reason === 'clear') {
      setSelectedProductTypes(() => {
        return [];
      });
      return;
    }

    if (reason === 'removeOption') {
      setSelectedProductTypes((prevState) => {
        return prevState.filter((type) => type === value);
      });
      return;
    }

    setSelectedProductTypes((prevState) => [...prevState, value]);
  };

  const handleApplicationChange = (e, newValue, reason, details) => {
    const value = newValue[newValue.length - 1];
    if (reason === 'clear') {
      setSelectedApplications(() => {
        return [];
      });
      return;
    }

    if (reason === 'removeOption') {
      setSelectedApplications((prevState) => {
        return prevState.filter((type) => type === value);
      });
      return;
    }

    setSelectedApplications((prevState) => [...prevState, value]);
  };

  const sortOptions = (a, b) => {
    const groupComparison = a.group_name?.localeCompare(b.group_name);
    if (groupComparison !== 0) {
      return groupComparison;
    }
    return a.type_name.localeCompare(b.type_name);
  };

  const handleModeChange = (event) => {
    setSelectedTerritories((prevState) => {
      return {
        ...prevState,
        type: event.target.checked ? 'county' : 'state',
      };
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          height: '90%',
          maxHeight: '1000px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography>Apply to Represent</Typography>
        <Typography variant="body2">
          Apply today! Selected partners receive a Free IN2TEC sticker and
          exclusive offers & have a direct influence on SqaureIT and its new
          products. Lastly, join one of the most positive growing communities
          around the world. Join the 10,000+ strong sales rep team today and
          fill out this application ASAP. Expect to hear back in 1-2 days.
        </Typography>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <Box display="flex" gap={4} mb={4}>
          <Box display="flex" flexDirection="column" flex={1}>
            <Typography variant="body2" minWidth={180} mb={2}>
              Select the areas you want to represent:
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedTerritories.type === 'county'}
                    onChange={handleModeChange}
                    name="territoryModeSwitch"
                  />
                }
                label={
                  selectedTerritories.type === 'county' ? 'County' : 'State'
                }
              />
            </Box>
            <USAMap
              onRegionSelect={onTerritorySelect}
              mode={selectedTerritories.type}
            />
          </Box>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <Autocomplete
              multiple
              value={selectedApplications}
              label={'Select the applications you want to work in'}
              getOptionLabel={(option) => option.application_name}
              options={companyInfo.applications.sort(sortOptions)}
              renderOption={(props, option) => (
                <li {...props} key={props['data-option-index']}>
                  {option.application_name}
                </li>
              )}
              onChange={handleApplicationChange}
              groupBy={(option) => option.industry_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Select the applications you want to work in'}
                />
              )}
              renderGroup={({ group, children }) => (
                <Fragment key={group}>
                  <ListSubheader className="custom-group-header">
                    {group}
                  </ListSubheader>
                  {children}
                </Fragment>
              )}
            />
            <Autocomplete
              multiple
              value={selectedProductTypes}
              label={'Select the product types you want to work with'}
              getOptionLabel={(option) => option.type_name}
              options={companyInfo.product_types.sort(sortOptions)}
              renderOption={(props, option) => (
                <li {...props} key={props['data-option-index']}>
                  {option.type_name}
                </li>
              )}
              onChange={handleProductTypeChange}
              groupBy={(option) => option.group_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Select the product types you want to work with'}
                />
              )}
              renderGroup={({ group, children }) => (
                <Fragment key={group}>
                  <ListSubheader className="custom-group-header">
                    {group}
                  </ListSubheader>
                  {children}
                </Fragment>
              )}
            />
          </Box>
        </Box>
        <CommissionBox
          commissions={companyInfo.commission_schedule.commission}
          commissionType={companyInfo.commission_schedule.type}
          viewMode="view"
        />
        <FormControl fullWidth>
          <TextField
            type="text"
            multiline
            rows={12}
            label="Brief explanation on why you should be picked."
            variant="outlined"
            size="small"
            name="message"
            value={message}
            onChange={handleInputChange}
            sx={{ mt: 4, mb: 2 }}
          />
        </FormControl>
        <Typography variant="body2">
          By clicking send you agree to honor the terms and services of a sales
          rep.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button
          onClick={handleCloseClick}
          color="secondary"
          variant="contained"
        >
          {t('buttons.close')}
        </Button>
        <Button
          onClick={handleConfirmClick}
          color="primary"
          type="submit"
          disabled={
            !message.length ||
            !selectedApplications.length ||
            !selectedProductTypes.length ||
            !selectedTerritories.territories.length
          }
          variant="contained"
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyDialog;
