import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ENTranslation from './locales/en/translation.json';
import ENCommon from './locales/en/common.json';
import ENButtons from './locales/en/buttons.json';
import ENTypes from './locales/en/types.json';
import ENValidations from './locales/en/validation.json';
import ENFields from './locales/en/fields.json';
import ENdialogs from './locales/en/dialogs.json';
import ENmessages from './locales/en/messages.json';
import ENpageEditor from './locales/en/pageEditor.json';
import DETranslation from './locales/de/translation.json';
import DECommon from './locales/de/common.json';
import DEButtons from './locales/de/buttons.json';
import DETypes from './locales/de/types.json';
import DEValidations from './locales/de/validation.json';
import DEFields from './locales/de/fields.json';
import DEdialogs from './locales/de/dialogs.json';
import DEmessages from './locales/de/messages.json';
import DEpageEditor from './locales/de/pageEditor.json';
import ITTranslation from './locales/it/translation.json';
import ITCommon from './locales/it/common.json';
import ITButtons from './locales/it/buttons.json';
import ITTypes from './locales/it/types.json';
import ITValidations from './locales/it/validation.json';
import ITFields from './locales/it/fields.json';
import ITdialogs from './locales/it/dialogs.json';
import ITmessages from './locales/it/messages.json';
import ITpageEditor from './locales/it/pageEditor.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: ENTranslation,
    common: ENCommon,
    buttons: ENButtons,
    types: ENTypes,
    fields: ENFields,
    validation: ENValidations,
    dialogs: ENdialogs,
    messages: ENmessages,
    pageEditor: ENpageEditor,
  },
  de: {
    translation: DETranslation,
    common: DECommon,
    buttons: DEButtons,
    types: DETypes,
    fields: DEFields,
    validation: DEValidations,
    dialogs: DEdialogs,
    messages: DEmessages,
    pageEditor: DEpageEditor,
  },
  it: {
    translation: ITTranslation,
    common: ITCommon,
    buttons: ITButtons,
    types: ITTypes,
    fields: ITFields,
    validation: ITValidations,
    dialogs: ITdialogs,
    messages: ITmessages,
    pageEditor: ITpageEditor,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        // Ex) i18n.format(t(someKey),{ns: someNameSpace} 'uppercase')
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize')
          return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
    },
    ns: ['translation', 'validation', 'buttons', 'fields', 'common'],
    defaultNS: 'translation',
    react: {
      useSuspense: false,
      bindI18n: 'languageChanged',
    },
  });

export default i18n;
