import { useEffect, useState, useMemo, Fragment, forwardRef } from 'react';
import {
  Autocomplete,
  InputLabel,
  ListSubheader,
  TextField,
} from '@mui/material';
import {
  addSingleApplication,
  getApplications,
} from '../../routes/applicationsRoutes';
import { NewOption } from './NewAttributeAutocomplete';
import NewApplicationDialog from './NewApplicationDialog';
import { useFormikContext } from 'formik';

const NewApplicationAutocomplete = ({
  product,
  handleNewApplication,
  handleDeleteApplication,
}) => {
  const [options, setOptions] = useState([]);
  const [isNewApplicationDialogOpen, setIsNewApplicationDialogOpen] =
    useState(false);

  const formik = useFormikContext();

  useEffect(() => {
    getApplicationsOptions();
  }, []);

  const getApplicationsOptions = async () => {
    const { data, statusCode } = await getApplications(true);

    if (statusCode === 200) {
      const options = data.map((option) => {
        return {
          label: option.application_name,
          value: option.application_id,
          industry_name: option.industry_name,
        };
      });
      setOptions(options);
    }
  };

  const handleConfirm = async ({ industry, newApplication }) => {
    setIsNewApplicationDialogOpen(false);
    handleNewApplication(newApplication);
    setOptions([
      ...options,
      {
        label: newApplication,
        value: industry.industry_id,
      },
    ]);

    await addSingleApplication({
      industry: industry.industry_id,
      newApplication,
    });
  };

  const handleAutocompleteChange = (event, newValue, reason, details) => {
    if (reason === 'clear') {
      handleDeleteApplication([]);
      return;
    }

    if (reason === 'removeOption') {
      const deletedValue = details.option;
      handleDeleteApplication(deletedValue);
      return;
    }

    handleNewApplication(newValue[newValue.length - 1].label);
  };

  const selectedProductApplications = useMemo(() => {
    return options.filter((option) =>
      product.product_applications.includes(option.label),
    );
  }, [product.product_applications, options]);

  const OptionsList = forwardRef((props, ref) => {
    return (
      <ul {...props} ref={ref}>
        {props.children}
        <li>
          <NewOption
            handleClick={() => setIsNewApplicationDialogOpen(true)}
            label="Application"
          />
        </li>
      </ul>
    );
  });

  return (
    <>
      <InputLabel
        id={'new-<product-application-label'}
        sx={{ fontSize: '1.2rem' }}
      >
        Areas of Application
      </InputLabel>
      <Autocomplete
        multiple
        disablePortal
        id="new-product-application-field"
        value={selectedProductApplications}
        options={options}
        selectOnFocus
        handleHomeEndKeys
        name="product_applications"
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search the Area(s) of Application or enter a new one and hit enter"
            name="product_applications"
          />
        )}
        noOptionsText={
          <NewOption
            handleClick={() => setIsNewApplicationDialogOpen(true)}
            label="Application"
          />
        }
        ListboxComponent={OptionsList}
        groupBy={(option) => option.industry_name}
        renderGroup={({ group, children, key }) => (
          <Fragment key={key}>
            <ListSubheader className="custom-group-header">
              {group}
            </ListSubheader>
            {children}
          </Fragment>
        )}
        sx={{ mb: 2 }}
      />
      <NewApplicationDialog
        mode="New"
        isOpen={isNewApplicationDialogOpen}
        handleConfirm={handleConfirm}
        handleClose={() => setIsNewApplicationDialogOpen(false)}
      />
    </>
  );
};
export default NewApplicationAutocomplete;
