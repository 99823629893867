import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog, DialogContent } from '@mui/material';
import ReactPlayer from 'react-player';

const VideoPlayerEmbedded = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="80%"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

VideoPlayerEmbedded.propTypes = {
  embedId: PropTypes.string.isRequired,
};

const VideoPlayer = ({
  videoSource,
  blur = 0,
  controls = true,
  muted = false,
  playing = false,
  loop = false,
  modal = false,
  open = false,
  onClose,
}) => {
  const aspectRatioPadding = '56.25%';

  const videoContent = (
    <Grid
      container
      // className="hero-video"
      style={{
        width: '100%',
      }}
    >
      <ReactPlayer
        className="react-player"
        url={videoSource}
        width="100%"
        height="100%"
        controls={controls}
        muted={muted}
        playing={playing}
        loop={loop}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          filter: `blur(${blur}px)`,
          WebkitFilter: `blur(${blur}px)`,
        }}
      />
    </Grid>
  );

  if (modal) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent
          style={{
            padding: 0,
            position: 'relative',
            height: aspectRatioPadding,
            paddingTop: aspectRatioPadding,
          }}
        >
          {videoContent}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Grid
      container
      className="hero-video"
      style={{
        position: 'relative',
        paddingTop: aspectRatioPadding,
      }}
    >
      {videoContent}
    </Grid>
  );
};

export { VideoPlayerEmbedded, VideoPlayer };
