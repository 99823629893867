import { Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ScrollingText1 = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.home.SalesHome.ScrollingText1',
  });
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography variant="h2">{t('title')}</Typography>
      <Grid item container sx={{ width: '75%' }} justifyContent="center">
        <List sx={{ listStyleType: 'disc' }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem1')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem2')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem3')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem4')} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

const ScrollingText2 = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.home.SalesHome.ScrollingText2',
  });
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography variant="h2" align="center">
        {t('title')}
      </Typography>
      <Grid item container sx={{ width: '75%' }} justifyContent="center">
        <List sx={{ listStyleType: 'disc' }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem1')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem2')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem3')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem4')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem5')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem6')} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

const ScrollingText3 = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.home.SalesHome.ScrollingText3',
  });
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ marginTop: '10vh' }}
    >
      <Typography variant="h2">{t('title')}</Typography>
      <Grid item container sx={{ width: '75%' }} justifyContent="center">
        <List sx={{ listStyleType: 'disc' }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem1')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem2')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem3')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem4')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem5')} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export const ScrollingText = [
  <ScrollingText1 />,
  <ScrollingText2 />,
  <ScrollingText3 />,
];
