import { Avatar, Skeleton, Tooltip } from '@mui/material';
import { useContext, useRef } from 'react';
import { useAxiosPrivateData } from '../../../hooks/axios/useAxiosPrivate';
import ResponseContext from '../../../context/providers/ResponseProvider';
import { useTranslation } from 'react-i18next';
import { UserProfileContext } from '../../../context/providers/UserProfileProvider';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../../context/providers/ProcessingProvider';

const UserProfilePicture = ({ src = '', initials = '', setReinitialize }) => {
  const axiosData = useAxiosPrivateData();

  const { values } = useFormikContext();
  const { setErrorDialog } = useContext(ResponseContext);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const { userProfile, setUserProfile } = useContext(UserProfileContext);
  const { isLoading, setIsLoading } = useContext(ProcessingContext);

  const { t } = useTranslation();
  const errRef = useRef(null);

  async function handleProfilePicture(e) {
    setReinitialize(false)
    // get selected file from input
    const [file] = e.target.files;
    if (!file) return;

    const formData = new FormData();
    formData.append('user_id', userProfile._id);
    formData.append('profile_picture', file);

    if (file) {
      //check if filesize is less than 1mb
      if (file.size > 1000000) {
        let fileSize = Math.round(file.size / 1000);
        setErrorDialog({
          open: true,
          title: t('validation.file.file_size_title'),
          message: t('validation.file.file_size_msg', {
            fileSize: fileSize,
            maxFileSize: '1MB',
          }),
        });
        return;
      } else {
        setIsLoading({
          status: true,
          type: 'spinner',
          text: t('components.account.UserProfilePicture.actions.uploading'),
        });

        try {
          const url = '/api/users/account/update/profilePicture';

          const request = await axiosData.put(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          const response = request?.data;

          if (request?.status === 201) {
            const data = response.data;
            const image = `data:${data.profile_picture.mimetype};base64,${data.profile_picture.buffer.toString('base64')}`;
            setUserProfile({
              ...userProfile,
              profile_picture: file,
              profile_picture_view_url: image,
            });
          }

          handleRegularResponse({
            open: true,
            status: response.status,
            message: response.message,
          });
        } catch (err) {
          handleErrorResponse(err);
          errRef.current?.focus();
        }
      }
    }
  }

  return (
    <>
      {isLoading.status ? (
        <Skeleton
          animation="wave"
          variant="circular"
          width={150}
          height={150}
        />
      ) : (
        <label htmlFor="profilePhoto">
          <input
            hidden
            accept="image/*"
            type="file"
            id="profilePhoto"
            onChange={(e) => handleProfilePicture(e, values)}
          />
          <Tooltip title={t('components.account.UserProfilePicture.tooltip')}>
            <Avatar src={src} sx={{ width: 150, height: 150 }}>
              {initials}
            </Avatar>
          </Tooltip>
        </label>
      )}
    </>
  );
};

export default UserProfilePicture;
