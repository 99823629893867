import { Fragment, useContext, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Autocomplete,
  ListSubheader,
  FormControlLabel,
  Switch,
  Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import USAMap from '../maps/USAMap';
import useAuth from '../../hooks/useAuth';
import { ProductTypeContext } from '../../context/providers/ProductTypeContextProvider';
import { ApplicationsContext } from '../../context/providers/ApplicationsContextProvider';
import CommissionBox from '../commissions/ComissionBox';

const MotionBox = motion.create(Box);

const ChatDocumentDialog = ({
  isOpen,
  handleConfirm,
  handleDecline,
  handleConfirmCounter,
  handleClose,
  document,
  title,
}) => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { productOptions } = useContext(ProductTypeContext);
  const { applicationOptions } = useContext(ApplicationsContext);

  const [viewMode, setViewMode] = useState('view');
  const [selectedTerritories, setSelectedTerritories] = useState({
    type: 'state',
    territories: [],
  });
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedApplications, setSelectedApplications] = useState([]);

  const userIsSender = useMemo(() => {
    if (!document) return null;
    if (auth.user_info.account_type === 'manufacturer') {
      return document.sender_id === auth.user_info.company;
    } else {
      return document.sender_id === auth.user_info._id;
    }
  }, [document, auth]);

  const handleConfirmClick = () => {
    handleConfirm(document._id);
    handleClose();
  };

  const handleDeclineClick = () => {
    handleDecline(document._id);
    handleClose();
  };

  const handleSendCounterClick = () => {
    const counterOffer = {
      _id: document._id,
      applications: selectedApplications.map((i) => i.application_id),
      product_types: selectedProductTypes.map((t) => t.type_id),
      territories: selectedTerritories,
    };
    handleConfirmCounter(counterOffer);
    handleClose();
    setViewMode('view');
  };

  const handleCloseClick = () => {
    handleClose();
  };

  const handleCounterClick = () => {
    setViewMode('edit');
    setSelectedProductTypes(
      document.product_types.map((type) => {
        const found = productOptions.find(
          (option) => option.type_id === type._id,
        );
        return found;
      }),
    );
    setSelectedApplications(
      document.applications.map((application) => {
        const found = applicationOptions.find(
          (option) => option.application_id === application._id,
        );
        return found;
      }),
    );
    setSelectedTerritories(document.territories);
  };

  const onTerritorySelect = (territory) => {
    setSelectedTerritories((prevState) => {
      if (territory.selected) {
        return {
          ...prevState,
          territories: [...prevState.territories, territory.id],
        };
      } else {
        return {
          ...prevState,
          territories: prevState.territories.filter((s) => s !== territory.id),
        };
      }
    });
  };

  const handleProductTypeChange = (e, newValue, reason, details) => {
    const value = newValue[newValue.length - 1];
    if (reason === 'clear') {
      setSelectedProductTypes(() => {
        return [];
      });
      return;
    }

    if (reason === 'removeOption') {
      setSelectedProductTypes((prevState) => {
        return prevState.filter((type) => type === value);
      });
      return;
    }

    setSelectedProductTypes((prevState) => [...prevState, value]);
  };

  const handleApplicationChange = (e, newValue, reason, details) => {
    const value = newValue[newValue.length - 1];
    if (reason === 'clear') {
      setSelectedApplications(() => {
        return [];
      });
      return;
    }

    if (reason === 'removeOption') {
      setSelectedApplications((prevState) => {
        return prevState.filter((type) => type === value);
      });
      return;
    }

    setSelectedApplications((prevState) => [...prevState, value]);
  };

  const sortOptions = (a, b) => {
    const groupComparison = a.group_name.localeCompare(b.group_name);
    if (groupComparison !== 0) {
      return groupComparison;
    }
    return a.type_name.localeCompare(b.type_name);
  };

  const handleModeChange = (event) => {
    setSelectedTerritories((prevState) => {
      return {
        ...prevState,
        type: event.target.checked ? 'county' : 'state',
      };
    });
  };

  const checkIfArrayContainsObject = (selected, existing, key) => {
    let contains = false;
    selected.forEach((selectedObject) => {
      const foundInExisting = existing.find(
        (existingObject) => existingObject._id === selectedObject[key],
      );

      if (foundInExisting) {
        contains = true;
        return;
      } else {
        contains = false;
        return;
      }
    });
    return contains;
  };

  const checkIfTerritoriesIsEqual = (selected, existing) => {
    return selected.every((t) => existing.includes(t));
  };

  const isCounterDisabled = useMemo(() => {
    if (
      checkIfArrayContainsObject(
        selectedApplications,
        document.applications,
        'application_id',
      ) &&
      checkIfArrayContainsObject(
        selectedProductTypes,
        document.product_types,
        'type_id',
      ) &&
      checkIfTerritoriesIsEqual(
        selectedTerritories.territories,
        document.territories.territories,
      )
    ) {
      return true;
    }
    return false;
  }, [
    selectedApplications,
    selectedProductTypes,
    selectedTerritories,
    document,
  ]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          height: '90%',
          maxHeight: '1000px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography>{title}</Typography>
      </DialogTitle>
      <AnimatePresence mode="wait">
        {viewMode === 'view' ? (
          <MotionBox
            key="view"
            initial={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'tween', duration: 0.2 }}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <DialogContent style={{ paddingTop: '40px' }}>
              <USAMap
                selectedRegions={document?.territories?.territories}
                mode={document?.territories?.type}
                height="440px"
              />
              <Box mt={4}>
                {!userIsSender && auth.user_info.account_type === 'salesrep' ? (
                  <Typography>
                    The applications they want you to work in:
                  </Typography>
                ) : (
                  <Typography>The applications they have worked in:</Typography>
                )}
                {document?.applications?.map((i) => {
                  return (
                    <Chip key={i._id} label={i.name_en} variant="outlined" />
                  );
                })}
              </Box>
              <Box mb={4}>
                {!userIsSender && auth.user_info.account_type === 'salesrep' ? (
                  <Typography>
                    The product types they want you to work with:
                  </Typography>
                ) : (
                  <Typography>
                    The product types they have experience with:
                  </Typography>
                )}
                {document?.product_types?.map((t) => {
                  return (
                    <Chip key={t._id} label={t.name_en} variant="outlined" />
                  );
                })}
              </Box>
              {document.commission_schedule ? (
                <CommissionBox
                  commissions={document.commission_schedule.commission}
                  commissionType={document.commission_schedule.type}
                  viewMode="view"
                />
              ) : null}
            </DialogContent>
            <DialogActions
              style={{
                paddingBottom: '16px',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                onClick={handleCloseClick}
                color="secondary"
                variant="contained"
              >
                {t('buttons.close')}
              </Button>
              {userIsSender && document.status === 'countered' ? (
                <>
                  <Button
                    onClick={handleDeclineClick}
                    color="error"
                    type="submit"
                    variant="contained"
                    sx={{ ml: 4 }}
                  >
                    Decline
                  </Button>
                  <Button
                    onClick={handleConfirmClick}
                    color="primary"
                    type="submit"
                    variant="contained"
                    sx={{ ml: 4 }}
                  >
                    Accept
                  </Button>
                </>
              ) : null}
              {!userIsSender && document.status === 'pending' ? (
                <Box>
                  <Button
                    onClick={handleDeclineClick}
                    color="error"
                    type="submit"
                    variant="contained"
                  >
                    Decline
                  </Button>
                  <Button
                    onClick={handleCounterClick}
                    color="warning"
                    type="submit"
                    variant="contained"
                    sx={{ ml: 4 }}
                  >
                    Counter
                  </Button>
                  <Button
                    onClick={handleConfirmClick}
                    color="primary"
                    type="submit"
                    variant="contained"
                    sx={{ ml: 4 }}
                  >
                    Accept
                  </Button>
                </Box>
              ) : null}
            </DialogActions>
          </MotionBox>
        ) : (
          <MotionBox
            key="edit"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.2 }}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <DialogContent style={{ paddingTop: '40px' }}>
              <Box display="flex" flexDirection="column" gap={4}>
                <Box display="flex" flexDirection="column" flex={1}>
                  <Typography variant="body2" minWidth={180} mb={2}>
                    Select the areas you want them to represent:
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={selectedTerritories.type === 'county'}
                          onChange={handleModeChange}
                          name="territoryModeSwitch"
                        />
                      }
                      label={
                        selectedTerritories.type === 'county'
                          ? 'County'
                          : 'State'
                      }
                    />
                  </Box>
                  <USAMap
                    onRegionSelect={onTerritorySelect}
                    mode={selectedTerritories.type}
                    selectedRegions={selectedTerritories.territories}
                    height="440px"
                  />
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-evenly"
                >
                  <Autocomplete
                    multiple
                    value={selectedApplications}
                    label={'Select the applications you want them work in'}
                    getOptionLabel={(option) => option.application_name}
                    options={applicationOptions}
                    renderOption={(props, option) => (
                      <li {...props} key={props['data-option-index']}>
                        {option.application_name}
                      </li>
                    )}
                    onChange={handleApplicationChange}
                    groupBy={(option) => option.industry_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          'Select the applications you want them to work in'
                        }
                      />
                    )}
                    renderGroup={({ group, children }) => (
                      <Fragment key={group}>
                        <ListSubheader className="custom-group-header">
                          {group}
                        </ListSubheader>
                        {children}
                      </Fragment>
                    )}
                    sx={{ my: 2 }}
                  />
                  <Autocomplete
                    multiple
                    value={selectedProductTypes}
                    label={'Select the product types you work with'}
                    getOptionLabel={(option) => option.type_name}
                    options={productOptions.sort(sortOptions)}
                    renderOption={(props, option) => (
                      <li {...props} key={props['data-option-index']}>
                        {option.type_name}
                      </li>
                    )}
                    onChange={handleProductTypeChange}
                    groupBy={(option) => option.group_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          'Select the product types you want them to work with'
                        }
                      />
                    )}
                    renderGroup={({ group, children }) => (
                      <Fragment key={group}>
                        <ListSubheader className="custom-group-header">
                          {group}
                        </ListSubheader>
                        {children}
                      </Fragment>
                    )}
                    sx={{ my: 2 }}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                paddingBottom: '16px',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                onClick={() => setViewMode('view')}
                color="secondary"
                variant="contained"
              >
                {t('buttons.cancel', { type: 'counter' })}
              </Button>
              <Button
                onClick={handleSendCounterClick}
                color="primary"
                type="submit"
                variant="contained"
                disabled={isCounterDisabled}
              >
                Send Counter Offer
              </Button>
            </DialogActions>
          </MotionBox>
        )}
      </AnimatePresence>
    </Dialog>
  );
};

export default ChatDocumentDialog;
