import { LeadProvider } from '../../context/providers/leads/LeadContextProvider.jsx';
import { ManufacturerHomeContent } from '../../components/home/Manufacturers/ManufacturerHomeContent.jsx';
import './home.css';

function ManufacturerHome() {
  return (
    <LeadProvider>
      <ManufacturerHomeContent />
    </LeadProvider>
  );
}

export default ManufacturerHome;
