import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';

function AuthLayout() {
  return (
    <>
      <Container maxWidth="xxl" component="main" disableGutters>
        <Outlet />
      </Container>
    </>
  );
}

export default AuthLayout;
