function getAspectRatio(image) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;

        img.onload = function () {
            const w = img.naturalWidth;
            const h = img.naturalHeight;
            let aspectRatio = w > h ? w / h : h / w;
            resolve(aspectRatio);
        };

        img.onerror = function () {
            reject(new Error('Failed to load image'));
        };
    });
}

export async function getObjectFit(image) {
    try {
        const ratio = await getAspectRatio(image);
        return ratio > 2 ? 'contain' : 'cover';
    } catch (error) {
        console.error('Error calculating aspect ratio:', error);
        return 'cover'; // Fallback to 'cover' if there's an error
    }
}