import React from 'react';
import { EventsList } from '../../components/events/ManageEvents';
import IntuPage from '../../components/layout/IntuPage';

function IntuManageEvents() {
  return (
    <IntuPage>
      <EventsList
        formTitle="Events"
        formText="You can manage and/or add events from here."
      />
    </IntuPage>
  );
}

export default IntuManageEvents;
