import axios from '../hooks/axios/useAxios';
import { axiosPrivate } from '../hooks/axios/axios';
import i18n from '../i18n.js';

// Get Applications from the database
async function getApplications(showAll = false) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/applications/list';

  try {
    const params = {
      showall: showAll,
    };

    const response = await axios.get(url, {
      signal,
      params,
      headers: { 'Content-Type': 'application/json' },
    });

    const { data, status } = response;

    if (status) {
      return data;
    }
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

// Search applications in database
async function searchApplications(payload) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/applications/search';

  try {
    const response = await axios.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    const { status, data } = response;
    if (status) {
      return data;
    }
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

// Add applications to database
async function addApplications(applicationList) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/applications/add';

  let req_fields = [];
  !applicationList &&
    req_fields.push(
      i18n.t('types.new', { type: i18n.t('types.applications') }),
    );
  !Array.isArray(applicationList) &&
    req_fields.push(
      i18n.t('validation.req_data_type', {
        dataType: i18n.t('types.data.array'),
      }),
    );

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const payload = {
      data: applicationList,
    };

    const response = await axiosPrivate.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    const { data, status } = response;

    if (status) {
      return data;
    }
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

async function addSingleApplication({ industry, newApplication }) {
  const payload = {
    industry,
    newApplication,
  };

  try {
    const response = await axiosPrivate.post(
      '/api/applications/add-single',
      JSON.stringify(payload),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
  } catch (error) {
    console.error('error', error);
  }
}

export {
  getApplications,
  searchApplications,
  addApplications,
  addSingleApplication,
};
