import MUIRichTextEditor from 'mui-rte';
import { makeStyles } from '@mui/styles';

const intuGreen = '#A8C957';
const intuLightGrey = '#00000061';

const useStyles = makeStyles(() => ({
  editorContainer: {
    border: `1px solid ${intuGreen}`,
    borderRadius: '0.375rem',
    padding: '0.5em 1em',
  },
  editorContainerDisabled: {
    border: `1px solid ${intuLightGrey}`,
    borderRadius: '0.375rem',
    padding: '0.5em 1em',
  },
  editor: {
    minHeight: '4em',
    marginTop: '0.5em',
    maxHeight: "20em",
    overflow: 'auto'
  },
  toolbar: {
    borderBottom: `1px solid ${intuLightGrey}`,
  },
}));

export const IntuRichTextEditor = ({
  field,
  editorState,
  setEditorState,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <MUIRichTextEditor
      {...field}
      {...otherProps}
      toolbarButtonSize="small"
      controls={otherProps.controls || []}
      readOnly={otherProps?.disabled}
      toolbar={!otherProps?.disabled}
      classes={{
        root: otherProps?.disabled
          ? classes.editorContainerDisabled
          : classes.editorContainer,
        editor: classes.editor,
        toolbar: classes.toolbar,
      }}
    />
  );
};
