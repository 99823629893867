import * as am5 from '@amcharts/amcharts5';
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import ReactDOMServer from 'react-dom/server';

function chartDataValidator(series, root, placeholderCount = 3) {
    const modal = createNoDataModal(root);

    series.events.on("datavalidated", function (ev) {
        var series = ev.target;
        if (ev.target.data.length < 1) {
            // Generate placeholder data
            var categoryField = series.get("categoryField");
            var valueField = series.get("valueField");
            var placeholder = [];
            for (let i = 0; i < 3; i++) {
                var item = {};
                item[categoryField] = "";
                item[valueField] = 1;
                placeholder.push(item)
            }
            series.data.setAll(placeholder);

            modal.open();
        }
        else {
            modal.close();
        }
    });
}

function createNoDataModal(root) {
    const content = ReactDOMServer.renderToString(<ModalContent />);

    const modal = am5.Modal.new(root, {
        content
    })
    // Style modal content
    const modalContent = modal.getPrivate("content")
    modalContent.style.backgroundColor = "transparent";
    modalContent.style.padding = "20px";
    modalContent.style.borderRadius = "16px";
    modalContent.style.boxShadow = "0px 0px 36px 0px #A8C957";

    // Style modal curtain
    const modalCurtain = modal.getPrivate("curtain");
    modalCurtain.style.borderRadius = "16px"

    // Style modal wrapper
    const modalWrapper = modal.getPrivate("wrapper");
    modalWrapper.style.borderRadius = "16px";

    // Change Modal container z-index to render behind other modals
    const modalContainer = document.querySelector('.am5-modal');
    modalContainer.style.zIndex = '10'

    return modal;
}

const ModalContent = () => {
    const { t } = useTranslation('messages')
    return (
        <Box>
            <Typography variant='h4'>
                {t('PublicProfile.chart_no_data')}
            </Typography>
        </Box>
    )

}



export default chartDataValidator;