import { Avatar, Divider, Grid, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

import ourMission from '../../assets/aboutCompany/robot.png';
import ourStory from '../../assets/aboutCompany/revolution.png';
import ourMotivation from '../../assets/fulfill.png';

const AboutUsComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.general.aboutCompany',
  });

  return (
    <Grid sx={{ padding: '0 2rem 0 2rem' }}>
      {/* Our Mission */}
      <Grid item container xs={12} direction={{ xs: 'column' }} mb="10rem">
        <Grid item xs={12} sx={{ marginBottom: '5rem' }}>
          <Typography variant="h1">
            {/* Our Mission */}
            {t('section1_header')}
          </Typography>
          <Divider sx={{ width: '5rem' }} />
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={4}
          direction={{ xs: 'column', lg: 'row' }}
        >
          <Grid item xs={12} md={4} className="intu__header-image">
            <Grid item>
              <Avatar
                alt="Our Mission"
                src={ourMission}
                sx={{
                  width: 350,
                  height: 350,
                  border: '2px solid #a8c957',
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4">{t('section1_h4')}</Typography>
            <Typography variant="body1">
              <Trans t={t}>{t('section1_body_1')}</Trans>
            </Typography>{' '}
            <br />
            <Typography variant="body1">{t('section1_body_2')}</Typography>{' '}
            <br />
            <Typography variant="body1">{t('section1_body_3')}</Typography>{' '}
            <br />
            <Typography variant="h5">{t('section1_h5')}</Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Our Story */}
      <Grid item container xs={12} direction={{ xs: 'column' }} mb="10rem">
        <Grid item xs={12} sx={{ marginBottom: '5rem' }}>
          <Typography variant="h1">{t('section2_header')}</Typography>
          <Divider sx={{ width: '5rem' }} />
        </Grid>

        <Grid item xs={12} container direction={{ xs: 'column', lg: 'row' }}>
          <Grid item xs={12} md={8}>
            <Typography variant="body1">{t('section2_body_1')}</Typography>
            <br />
            <Typography variant="body1">{t('section2_body_2')}</Typography>
            <br />
            <Typography variant="h5">{t('section2_h5')}</Typography>
          </Grid>
          <Grid item xs={12} md={4} className="intu__header-image">
            <Grid item className="">
              <Avatar
                alt="Our Mission"
                srcSet={ourStory}
                sx={{
                  width: 350,
                  height: 350,
                  border: '2px solid #a8c957',
                }}
              />
              {/* <img src={ourStory}></img> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* The Motion */}
      <Grid item container xs={12} direction={{ xs: 'column' }} mb="10rem">
        <Grid item xs={12} sx={{ marginBottom: '5rem' }}>
          <Typography variant="h1">{t('section3_header')}</Typography>
          <Divider sx={{ width: '5rem' }} />
        </Grid>
        <Grid item xs={12} container direction={{ xs: 'column', lg: 'row' }}>
          <Grid item xs={12} md={4} className="intu__header-image">
            <Grid item className="">
              <Avatar
                alt="Our Mission"
                src={ourMotivation}
                sx={{
                  width: 350,
                  height: 350,
                  border: '2px solid #a8c957',
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} mt={4}>
            <Typography variant="body1">{t('section3_body_1')}</Typography>
            <br />
            <Typography variant="body1">
              {/* {t('section3_body_2')} */}
              <Trans t={t}>{t('section3_body_2')}</Trans>
            </Typography>
            <br />
            <Typography variant="h5">{t('section3_h5')}</Typography>
            <br />
          </Grid>
        </Grid>
      </Grid>

      {/* What makes us different */}
      <Grid
        item
        container
        xs={12}
        mt={4}
        direction={{ xs: 'column' }}
        sx={{ marginBottom: '10rem' }}
      >
        <Grid item xs={12} sx={{ marginBottom: '5rem' }}>
          <Typography variant="h1">{t('section4_header')}</Typography>
          <Divider sx={{ width: '5rem' }} />
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction={{ xs: 'column', lg: 'row' }}
          spacing={4}
        >
          <Grid item xs={12} md={8}>
            <Typography variant="body1">{t('section4_body_1')}</Typography>
            <br />
            <Typography variant="body1">{t('section4_body_2')}</Typography>
            <br />
            <Typography variant="h5">{t('section4_h5')}</Typography>
            <Grid item>
              <Avatar
                variant="rounded"
                alt="Our Technology"
                src={
                  'https://in2tec.nyc3.cdn.digitaloceanspaces.com/images/SalesDashboardDesktop.png'
                }
                sx={{ width: '80%', height: '100%', margin: '0 auto' }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h4">{t('section5_h4_1')}</Typography>
              <Typography variant="body1">{t('section5_body_1')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">{t('section5_h4_2')}</Typography>
              <Typography variant="body1">{t('section5_body_2')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">{t('section5_h4_3')}</Typography>
              <Typography variant="body1">{t('section5_body_3')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">{t('section5_h4_4')}</Typography>
              <Typography variant="body1">{t('section5_body_4')}</Typography>
              <ul className="intu__form-bullets">
                <li>{t('section5_bullet_1')}</li>
                <li>{t('section5_bullet_2')}</li>
                <li>{t('section5_bullet_3')}</li>
                <li>{t('section5_bullet_4')}</li>
                <li>{t('section5_bullet_5')}</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUsComponent;
