import { Outlet } from 'react-router-dom';
import { Container, useTheme } from '@mui/material';
import {
  LoadingSpinner,
  useProcessingHandler,
} from '../../hooks/useProcessingHandler';
import StatusBarComponent from '../status/StatusBar';
import ErrorDialog from '../status/ErrorDialog';

function GlobalLayout() {
  const theme = useTheme();
  const { isLoading } = useProcessingHandler();

  const navBarHeight = parseInt(
    theme.components.MuiAppBar.styleOverrides.root.height,
    10,
  );

  const marginTop = `${navBarHeight}px`;

  return (
    <Container
      maxWidth="auto"
      disableGutters
      sx={{
        margin: `${marginTop} 0 0 0`,
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Outlet />
      <LoadingSpinner isLoading={isLoading} />
      <StatusBarComponent />
      <ErrorDialog />
    </Container>
  );
}

export default GlobalLayout;
