import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { RiveAnimationComponent } from '../../../components/animations/RiveAnimationComponent.jsx';
import useIntuTheme from '../../../hooks/useIntuTheme.js';
import { useTranslation } from 'react-i18next';
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';
import { useContext } from 'react';
import { SimpleZoomInOutcomponent } from '../../motion/SimpleZoomInOut.jsx';

export const WhatMakesUsDifferent = ({ title, description }) => {
  const theme = useTheme();
  const { leadInfo } = useContext(LeadContext);

  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });

  const { t: transCommon } = useTranslation('common');
  const { mode, mobile } = useIntuTheme();
  const medium = useMediaQuery(theme.breakpoints.down('md'));
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      className="intu__divider"
      justifyContent="center"
      alignItems="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ padding: '5rem 0 0 0' }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h2"
          color="primary"
          align="center"
          sx={{
            color: mode === 'dark' ? 'white' : 'black',
            fontWeight: '600',
          }}
        >
          {title}
        </Typography>
      </Grid>

      <Grid item xs={12} textAlign="center">
        <Typography variant="body" align="center">
          {description}
        </Typography>
      </Grid>

      {/* Current */}
      <SimpleZoomInOutcomponent
        srcArray={[
          {
            type: 'animation',
            stateMachines: 'State Machine 1',
            src:
              mode === 'dark'
                ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/current_tree_dark_mode.riv'
                : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/current_tree_light_mode.riv',
            title: t('what_makes_us_diff_chart1.title', {
              company: leadInfo.company
                ? `${leadInfo.company}'s`
                : transCommon('your'),
            }),
            description: t('what_makes_us_diff_chart1.description', {
              company: leadInfo.company
                ? `${leadInfo.company}'s`
                : transCommon('your'),
            }),
            progress: [0.5, 0.8, 1],
            zoom: [0, 0.5, 1],
            stickyval: mobile ? 0.8 : 0.5,
            unstickyval: 0.9,
            titleMT: '10%',
            titleMB: 0,
            width: small ? '350px' : medium ? '500px' : '800px',
            height: small ? '350px' : medium ? '550px' : '900px',
            customLabels: {
              Manufacturer: leadInfo.company
                ? leadInfo.company
                : transCommon('manufacturer'),
              ProductReferals: transCommon('product_referals'),
              ActiveMarketing: transCommon('active_marketing'),
              ByIndustry: transCommon('by_industry'),
              ByIndustryProfessionals: transCommon(
                'by_industry_professsionals',
                {
                  ns: 'common',
                },
              ),
              SalesEngineers: transCommon('sales_engineers'),
              Customers: transCommon('customers'),
              ByProductType: transCommon('by_product_type'),
              ByState: transCommon('by_state'),
              LeadsAndCustomerData: transCommon('leads_customer_data', {
                ns: 'common',
              }),
            },
          },
        ]}
      />

      {/* How we operate your brand */}
      <SimpleZoomInOutcomponent
        srcArray={[
          {
            type: 'animation',
            stateMachines: 'State Machine 1',
            src:
              mode === 'dark'
                ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/in2tec_flow_dark_mode.riv'
                : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/in2tec_flow_light_mode.riv',
            title: t('what_makes_us_diff_chart2.title', {
              company: leadInfo.company
                ? `${leadInfo.company}'s`
                : transCommon('your'),
            }),
            description: t('what_makes_us_diff_chart2.description', {
              company: leadInfo.company
                ? `${leadInfo.company}'s`
                : transCommon('your'),
            }),
            progress: [0.5, 0.8, 1],
            zoom: [0, 0.5, 1],
            stickyval: 0.6,
            unstickyval: 1,
            titleMT: '10%',
            titleMB: 0,
            width: small ? '350px' : medium ? '500px' : '800px',
            height: small ? '350px' : medium ? '500px' : '900px',
            customLabels: {
              Manufacturer: leadInfo.company
                ? leadInfo.company
                : transCommon('manufacturer'),
              ProductReferals: transCommon('product_referals'),
              ActiveMarketing: transCommon('active_marketing'),
              ByIndustry: transCommon('by_industry'),
              ByIndustryProfessionals: transCommon(
                'by_industry_professsionals',
                {
                  ns: 'common',
                },
              ),
              SalesEngineers: transCommon('sales_engineers'),
              Customers: transCommon('customers'),
              ByProductType: transCommon('by_product_type'),
              ByState: transCommon('by_state'),
              LeadsAndCustomerData: transCommon('leads_customer_data', {
                ns: 'common',
              }),
            },
          },
        ]}
      />
    </Grid>
  );
};
