import React, { useState } from 'react';
import { Grid, Chip, Button, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ProfileChips = ({ itemArray, type }) => {
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation('buttons')

    const toggleExpand = () => setExpanded(!expanded);

    return (
        <>
            <Grid item container spacing={1}>
                {itemArray?.slice(0, 10).map((item, index) => (
                    <Grid item key={index}>
                        <Chip label={type === 'application' ? item.application_name : item.type_name} />
                    </Grid>
                ))}
            </Grid>
            <Collapse in={expanded}>
                <Grid item container spacing={1} mt={0.1}>
                    {itemArray?.slice(10).map((item, index) => (
                        <Grid item key={index + 10}>
                            <Chip label={type === 'application' ? item.application_name : item.type_name} />
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
            {itemArray?.length > 10 && (
                <Grid container mb={4}>
                    <Button onClick={toggleExpand}>
                        {expanded ? t('show_less') : t('show_more', { num: itemArray?.length - 10 })}
                    </Button>
                </Grid>
            )}
        </>
    );
};