import React, { useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import { getObjectFit } from '../../../helpers/getImageAspectRatio';

const IntuAvatar = styled(Avatar)(({ objectFit }) => ({
    '& .MuiAvatar-img': {
        objectFit: objectFit
    }
}));

export const StyledAvatar = ({ children, src, ...props }) => {
    const [objectFit, setObjectFit] = useState('cover');

    const handleImageLoad = useCallback(async () => {
        if (src && getObjectFit) {
            try {
                const result = await getObjectFit(src);
                setObjectFit(result);
            } catch (error) {
                console.error('Error calculating object-fit:', error);
                // Fallback to 'cover' in case of error
                setObjectFit('cover');
            }
        }
    }, [src, getObjectFit]);

    return (
        <IntuAvatar
            objectFit={objectFit}
            src={src}
            onLoad={handleImageLoad}
            {...props}
        >
            {children}
        </IntuAvatar>
    )
}

