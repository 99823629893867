import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Typography,
  Grid,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';
import useAuth from '../../../../hooks/useAuth';
import { useContext, useRef } from 'react';
import { CompanyEmployeesListContext } from './EmployeesList';
import ResponseContext from '../../../../context/providers/ResponseProvider';
import { createInitialEmployeeDetailsValues } from './InitialValues';
import { CompanyProfileContext } from '../../../../context/providers/CompanyProfileProvider';
import { searchUsers } from '../../../../routes/usersRoutes';
import {
  associateUserToCompany,
  unAssociateUserFromCompany,
} from '../../../../routes/employeeRoutes';
import { SearchUserField } from '../../SearchUserField';
import IndividualFirstNameField from '../../individual/IndividualFirstNameField';
import EmailField from '../../fields/email/EmailField';
import EmployeeRoleField from './EmployeeRoleField';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { searchForExisitingEmployee } from '../../../../routes/companies';

export const EmployeeDetailsDialog = () => {
  const { employeeDialog, setEmployeeDialog } = useContext(
    CompanyEmployeesListContext,
  );

  const {
    employeeDetails,
    companyProfile,
    setCompanyProfile,
    getCompanyProfile,
  } = useContext(CompanyProfileContext);

  const { auth, setAuth } = useAuth();

  const { setErrorDialog } = useContext(ResponseContext);

  const { t } = useTranslation('dialogs', {
    keyPrefix: 'CompanyEmployeeDialog',
  });
  const { t: transMessages } = useTranslation('messages');

  const errRef = useRef();
  const formRef = useRef();

  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();

  const axios = useAxiosPrivate();

  const initialValues = createInitialEmployeeDetailsValues(employeeDetails);

  const handleEmployeeUpdate = async ({
    values,
    helpers, //formik functions
    action, // Type of Change
  }) => {
    // Set Form in Submitting State

    helpers.isSubmitting = true;

    let message;

    const currentEmployees = [...companyProfile.employees];
    let newEmployeesList = [];
    let blockedUsers = companyProfile.blocked_users
      ? companyProfile.blocked_users
      : [];

    if (action === 'addUser') {
      message = t('form.company.dialog.added_user_msg', {
        role: values.role,
        company: values.company,
      });
      // Search for User By Email
      const searchPayload = {
        key: 'email',
        values: values.email,
      };
      const searchRes = await searchUsers(searchPayload);

      const { data: searchData, statusCode: searchStatusCode } = searchRes;

      if (searchStatusCode !== 200) {
        setErrorDialog({
          open: true,
          title: transMessages('global.error'),
          message: transMessages('CompanyEmployeeDialog.userNotFound'),
        });
      }

      // Search companies if user is current emplopyee
      if (searchStatusCode === 200) {
        const exisitingEmployeeCheck = await searchForExisitingEmployee(searchData[0]._id)
        if (exisitingEmployeeCheck) {
          setErrorDialog({
            open: true,
            title: transMessages('global.error'),
            message: transMessages('CompanyEmployeeDialog.existing_employee'),
          });
          return;
        }
      }

      // Update Values
      let newEmployee = {
        _id: searchData[0]._id,
        role: values.role,
      };

      newEmployeesList = [...companyProfile.employees];

      // Update above user's company
      const updatePayload = {
        company_id: companyProfile._id,
        employees: [newEmployee],
      };

      const updateCompanyAssociation =
        await associateUserToCompany(updatePayload);

      if (updateCompanyAssociation.statusCode !== 200) {
        handleRegularResponse({
          open: true,
          status: updateCompanyAssociation.status,
          message: updateCompanyAssociation.message,
        });
      }

      if (updateCompanyAssociation.statusCode === 200) {
        newEmployeesList.push(newEmployee);
        setCompanyProfile({ ...companyProfile, newEmployeesList });
      }
    } else if (action === 'updateUser') {
      currentEmployees.forEach((employee) => {
        if (employee._id === values._id) {
          employee.role = values.role;
        }
      });

      // check if there is more than one admin
      let admin_count = 0;
      for (let i = 0; i < currentEmployees.length; i++) {
        if (currentEmployees[i].role === 'admin') admin_count++;
      }

      // Guard Clause Exit when there is only one admin
      if (admin_count === 0) {
        setCompanyProfile({ ...companyProfile, currentEmployees });
        setErrorDialog({
          open: true,
          title: transMessages('global.error'),
          message: transMessages('CompanyEmployeeDialog.employeeCount'),
        });
        return;
      }
      else if (values.role === 'remove') {
        newEmployeesList = currentEmployees
          .filter((employee) => employee._id !== values._id)
          .map((employee) => {
            return {
              _id: employee._id,
              role: employee.role,
            };
          });
        const payload = {
          company_id: companyProfile._id,
          employees: [values._id],
        };
        const updateCompanyAssociation =
          await unAssociateUserFromCompany(payload);

        if (updateCompanyAssociation.statusCode !== 200) {
          handleRegularResponse({
            open: true,
            status: updateCompanyAssociation.status,
            message: updateCompanyAssociation.message,
          });
        }
        if (updateCompanyAssociation.statusCode === 200) {
          newEmployeesList = [...currentEmployees].filter(
            (employee) => employee.role !== 'remove',
          );
        }
        message = t('form.company.dialog.removed_user_msg', {
          role: values.employee_role,
          company: values.name,
        });
      }
      // If employee is blocked, remove user from company, add to companys blocked list and remove company from user's profile
      else if (values.role === 'blocked') {
        blockedUsers.push(values._id);
        const payload = {
          company_id: companyProfile._id,
          employees: [values._id],
        };
        const updateCompanyAssociation =
          await unAssociateUserFromCompany(payload);

        if (updateCompanyAssociation.statusCode !== 200) {
          handleRegularResponse({
            open: true,
            status: updateCompanyAssociation.status,
            message: updateCompanyAssociation.message,
          });
        }
        newEmployeesList = [...currentEmployees]
      }
      else {
        // Remove user from blocked list
        blockedUsers = blockedUsers.filter((userID) => userID !== values._id)
        newEmployeesList = [...currentEmployees];
        setCompanyProfile({ ...companyProfile, newEmployeesList });
        message = t('form.company.dialog.updated_user_msg', {
          company: values.name,
          role: values.role,
        });
      }
    }

    // Update Company Employees
    const url = '/api/companies/company/update';
    const payload = {
      _id: companyProfile._id,
      employees: newEmployeesList,
      blocked_users: blockedUsers,
      notification: {
        send: true,
        message: message,
        recipients: companyProfile.admins,
      },
    };

    try {
      const response = await axios.put(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const { data, status } = response;

      if (data && status === 200) {
        const newCompanyInfo = {
          ...data.data,
        };

        // Updete Auth
        setAuth({
          ...auth,
          company_info: newCompanyInfo,
        });

        const profile = await getCompanyProfile(data.data._id);

        setCompanyProfile({
          ...profile,
        });

        // Updtae Change Name Field
        helpers.setValues({
          ...values,
          id: '',
          name: '',
          email: '',
          role: '',
          employee_change_type: '',
        });

        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
    } finally {
      helpers.isSubmitting = false;
      setEmployeeDialog(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const {
          values,
          errors,
          touched,
        } = formik;
        return (
          <Dialog
            maxWidth="sm"
            fullWidth
            open={employeeDialog ? employeeDialog.open : false}
          >
            <DialogTitle className="intu__form-title">
              {t(`title.${employeeDialog?.action}`)}
            </DialogTitle>
            <DialogContent className="intu__form-content">
              <Form>
                <Grid container>
                  <Grid item container>
                    <Grid item>
                      <Typography>
                        {t(`description.${employeeDialog?.action}`)}
                      </Typography>
                    </Grid>
                    {/* {employeeDialog?.action === 'edit' && */}
                    <Grid item className="intu__form-bullets">
                      <Trans
                        t={t}
                        i18nKey={'description.content'}
                        components={[<li />, <li />, <li />, <li />, <ul />]}
                      >
                        <ul>
                          <li>Associates have a verified relation to your company.</li>
                          <li>
                            Super Users can see and edit company campaigns,
                            products and reports
                          </li>
                          <li>
                            Admins can change company data, add, remove or
                            edit employees
                          </li>
                          <li>
                            Blocked users are former employees which might still
                            associate themselves with you, but havent changed
                            their personal profile, including their relationship
                            to your company.
                          </li>
                        </ul>
                      </Trans>
                    </Grid>
                    {/* } */}
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    {employeeDialog.action === 'add' &&
                      <SearchUserField />
                    }
                  </Grid>
                  {/* Employee Name */}
                  <Grid item xs={12} mt={2}>
                    <IndividualFirstNameField
                      fieldID='name'
                      disabled
                    />
                  </Grid>
                  {/* Employee Email */}
                  <Grid item xs={12} mt={2}>
                    <EmailField
                      disabled
                      fieldID='email'
                    />
                  </Grid>
                  {/* Employee Role */}
                  <Grid item xs={12} mt={2}>
                    <EmployeeRoleField />
                  </Grid>
                  {errors?.role && touched?.role ? (
                    <FormHelperText error>{errors.role}</FormHelperText>
                  ) : null}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              {/* Cancel */}
              <IntuIconButton
                type='cancel'
                onClick={() => {
                  setEmployeeDialog(false);
                }}
                tooltipTitle='cancel'
              />

              {/* Update Button */}
              {employeeDialog.action === 'edit' &&
                <IntuIconButton
                  type='edit'
                  onClick={() =>
                    handleEmployeeUpdate({
                      values: values,
                      helpers: formik,
                      action: 'updateUser',
                    })
                  }
                  disabled={
                    values?.employee_role_change !== true ||
                      errors?.employee_role
                      ? true
                      : false
                  }
                  tooltipTitle='update'
                  tooltipType='employee'
                />
              }

              {/* Add Button */}
              {employeeDialog.action === 'add' &&
                <IntuIconButton
                  type='add'
                  onClick={() =>
                    handleEmployeeUpdate({
                      values: values,
                      helpers: formik,
                      action: 'addUser',
                    })
                  }
                  disabled={
                    values?.employee_role_change !== true ||
                      errors?.employee_role
                      ? true
                      : false
                  }
                />
              }
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};
