import { AdminDashboard } from '../../components/admin/AdminDashboard';
import IntuPage from '../../components/layout/IntuPage';
import { useTranslation } from 'react-i18next';

const AdminDashboardPage = () => {
  // Translator
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('context.admin.dashboardContext.title')}
      pageDescription={t('context.admin.dashboardContext.text')}
      hCentered={true}
      extraMT
    >
      <AdminDashboard />
    </IntuPage>
  );
};

export default AdminDashboardPage;
