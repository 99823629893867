import { axiosPrivate } from '../hooks/axios/axios';
import { t } from 'i18next';

async function getRelationshipList() {
    const url = 'api/relationships/list';

    try {
        const response = await axiosPrivate.get(url, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        const { data, status } = response;

        if (status === 200) {
            return {
                status: data.status,
                message: data.message,
                data: data.data,
            };
        } else {
            return {
                status: data.status,
                message: data.message,
            };
        }
    } catch (error) {
        console.error(error);
        return error
    }
}

async function searchRelationships(payload) {
    const req_fields = [];

    !payload && req_fields.push(t('routes.req_fields.payload'));

    if (req_fields.length > 0) {
        throw new Error(
            t('routes.responses.req_fields', { requiredFields: req_fields }),
        );
    }

    const formattedPayload = {
        data: [
            payload
        ]
    }

    const url = '/api/relationships/search';

    try {
        const response = await axiosPrivate.post(url, JSON.stringify(formattedPayload), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        const { data, status } = response;

        if (status === 200) {
            return {
                status: data.status,
                message: data.message,
                data: data.data,
            };
        } else {
            return {
                status: data.status,
                message: data.message,
            };
        }
    } catch (error) {
        console.error('error', error);
        return error;
    }
}

async function updateRelationships(payload) {
    const req_fields = [];
    const url = 'api/relationships/update';

    !payload && req_fields.push(t('routes.req_fields.payload'));

    if (req_fields.length > 0) {
        throw new Error(
            t('routes.responses.req_fields', { requiredFields: req_fields }),
        );
    }

    const formattedPayload = {
        data: [
            payload
        ]
    }

    try {
        const response = await axiosPrivate.put(url, JSON.stringify(formattedPayload), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        const { data, status } = response;

        if (status === 200) {
            return {
                status: data.status,
                message: data.message,
                data: data.data,
            };
        } else {
            return {
                status: data.status,
                message: data.message,
            };
        }
    } catch (error) {
        console.error(error);
        return error
    }
}

async function addRelationships(payload) {
    const req_fields = [];

    !payload && req_fields.push(t('routes.req_fields.payload'));

    if (req_fields.length > 0) {
        throw new Error(
            t('routes.responses.req_fields', { requiredFields: req_fields }),
        );
    }

    const formattedPayload = {
        data: [
            payload
        ]
    }

    const url = '/api/relationships/add';

    try {
        const response = await axiosPrivate.post(url, JSON.stringify(formattedPayload), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        const { data, status } = response;

        if (status === 200) {
            return {
                status: data.status,
                message: data.message,
                data: data.data,
            };
        } else {
            return {
                status: data.status,
                message: data.message,
            };
        }
    } catch (error) {
        console.error('error', error);
        return error;
    }
}

async function deleteRelationship(orderId) {
    const req_fields = [];

    !orderId && req_fields.push(t('routes.req_fields.values'));

    if (req_fields.length > 0) {
        throw new Error(
            t('routes.responses.req_fields', { requiredFields: req_fields }),
        );
    }

    const payload = {
        data: [{
            _id: orderId
        }]
    }

    const url = '/api/relationships/delete';

    try {
        const response = await axiosPrivate.delete(url, JSON.stringify(payload), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        const { data, status } = response;

        if (status === 200) {
            return {
                status: data.status,
                message: data.message,
                data: data.data,
            };
        } else {
            return {
                status: data.status,
                message: data.message,
            };
        }
    } catch (error) {
        console.error('error', error);
        return error;
    }
}

export {
    getRelationshipList,
    searchRelationships,
    addRelationships,
    updateRelationships,
    deleteRelationship
}