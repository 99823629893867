import { useContext } from 'react';
import {
  Fade,
  Typography,
  Grid,
  Stack,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Skeleton
} from '@mui/material';
import { useFormikContext } from 'formik';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider.jsx';
import { useTranslation } from 'react-i18next';
import { ColorPickerField } from '../../colors/ColorPickerField.jsx';
import ProcessingContext from '../../../context/providers/ProcessingProvider.jsx';

const CompanyThemeColors = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  transition = true, // Transition of the Field (true = fade in, false = fadeout)
  disabled = false, // Whether the field is disabled
  required = false, // Whether the field is required
}) => {
  const { transitionInterval, setUserTheme } =
    useContext(IntuThemeContext);
  // Translation
  const { t: transFields } = useTranslation('fields', {
    keyPrefix: 'account.company.CompanyThemeColors',
  });

  const { values, setFieldValue, setFieldTouched } =
    useFormikContext();
  const { isLoading } = useContext(ProcessingContext);
  return (
    <Grid container alignItems="center" direction={{ xs: 'column', lg: 'row' }}>
      <Grid item xs={12} lg={12} sx={{ width: '100%' }}>
        {fieldTitle && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
              {fieldTitle}
            </Typography>
          </Fade>
        )}
        {fieldDescription && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography
              className="form-note"
              textAlign={{ xs: 'center', md: 'left' }}
            >
              {fieldDescription}
            </Typography>
          </Fade>
        )}

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={4}
          sx={{ marginTop: '0.5rem' }}
        >
          {/* Set Theme Active */}
          {isLoading.status ? (
            <Skeleton variant="rectangular" width={'100%'} height={60} />
          ) : (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.theme?.active}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setFieldValue('theme.active', checked);
                      setUserTheme({
                        ...values.theme,
                        active: checked,
                      });
                      setTimeout(() => {
                        setFieldTouched('theme.active', true);
                      }, 300);
                    }}
                    name="active"
                    color="primary"
                  />
                }
                label={transFields('active.label')}
              />
            </FormGroup>
          )}
          {/* Primary Color */}
          <ColorPickerField
            defaultColor={values?.theme?.primary}
            fieldID="theme.primary"
            fieldName="theme.primary"
            transPrefix="account.company.CompanyThemeColors.primary"
            disabled={disabled}
            required={required}
            transition={transition}
            onChange={(color) => {
              setFieldValue('theme.primary', color);
              setFieldTouched('theme.primary', true, true);
              setUserTheme({
                ...values.theme,
                primary: color,
              });
            }}
          />

          {/* Secondary Color */}
          <ColorPickerField
            defaultColor={values?.theme?.secondary}
            fieldID="theme.secondary"
            fieldName="theme.secondary"
            transPrefix="account.company.CompanyThemeColors.secondary"
            disabled={disabled}
            required={required}
            transition={transition}
            onChange={(color) => {
              setFieldValue('theme.secondary', color);
              setFieldTouched('theme.secondary', true, true);
              setUserTheme({
                ...values.theme,
                secondary: color,
              });
            }}
          />

          {/* Contrast Color */}
          <ColorPickerField
            defaultColor={values?.theme?.contrastColor}
            fieldID="theme.contrastColor"
            fieldName="theme.contrastColor"
            transPrefix="account.company.CompanyThemeColors.contrastColor"
            disabled={disabled}
            required={required}
            transition={transition}
            onChange={(color) => {
              setFieldValue('theme.contrastColor', color);
              setFieldTouched('theme.contrastColor', true, true);
              setUserTheme({
                ...values.theme,
                contrastColor: color,
              });
            }}
          />

          {/* Text Color */}
          <ColorPickerField
            defaultColor={values?.theme?.textColor}
            fieldID="theme.textColor"
            fieldName="theme.textColor"
            transPrefix="account.company.CompanyThemeColors.textColor"
            disabled={disabled}
            required={required}
            transition={transition}
            onChange={(color) => {
              setFieldValue('theme.textColor', color);
              setFieldTouched('theme.textColor', true, true);
              setUserTheme({
                ...values.theme,
                textColor: color,
              });
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CompanyThemeColors;
