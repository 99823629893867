import { useMemo, useEffect, useState } from 'react';
import { Box, Button, Paper, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import PlaceHolderImage from '../../assets/core/intuPlaceHolder.png';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import StarsIcon from '@mui/icons-material/Stars';
import { ReactComponent as Image3DSVG } from '../../assets/new-product/3d-image.svg';
import CanvasContainer from './CanvasContainer';
import UploadImageDialog from './UploadImageDialog';

export const CarouselItem = ({ image }) => {
  if (image.endsWith('.stp')) return null;
  if (image.endsWith('.gltf')) {
    return <CanvasContainer image={image} />;
  }
  return (
    <Paper
      elevation={0}
      square
      sx={{
        background: grey[200],
        display: 'flex',
        justifyContent: 'center',
        height: '40vh',
      }}
    >
      <Box
        width="80%"
        height="80%"
        mt={6}
        sx={{ objectFit: 'contain' }}
        component="img"
        src={image}
      />
    </Paper>
  );
};

const ProductImageHeader = ({
  productImages,
  handleNewImage,
  handleDeleteImage,
  handleSetDefaultImage,
  isSaving,
  isUploadImageDialogOpen,
  setIsUploadImageDialogOpen,
}) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [prevLength, setPrevLength] = useState(0);
  const isShort = useMediaQuery('(max-height:950px)');

  const filteredProductImages = useMemo(() => {
    if (!productImages.length) return null;
    return productImages.filter(
      (image) => !image.endsWith('.stp') && !image.endsWith('.STEP'),
    );
  }, [productImages]);

  useEffect(() => {
    if (!filteredProductImages) return;
    if (prevLength === filteredProductImages.length) {
      setActiveIndex(0);
    } else {
      setActiveIndex(filteredProductImages.length - 1);
    }
    setPrevLength(filteredProductImages.length);
  }, [filteredProductImages]);

  const images = useMemo(() => {
    if (!filteredProductImages) return [PlaceHolderImage];
    return filteredProductImages;
  }, [filteredProductImages]);

  const imageIndicators = useMemo(() => {
    if (!filteredProductImages) return null;
    return filteredProductImages.map((image) => {
      if (image.endsWith('.gltf')) {
        return <Image3DSVG width="50px" height="50px" />;
      }
      return (
        <img
          key={image}
          alt={image}
          src={image}
          style={{ width: '50px', height: '50px', margin: '0 4px' }}
        />
      );
    });
  }, [filteredProductImages]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      position="relative"
    >
      <Carousel
        style={{ flex: 1 }}
        index={activeIndex}
        animation="slide"
        navButtonsAlwaysVisible={images.length > 1}
        autoPlay={false}
        swipe={false}
        IndicatorIcon={imageIndicators}
        indicatorContainerProps={{
          style: {
            background: productImages.length > 0 ? 'white' : 'initial',
            margin: isShort ? '180px auto 0' : '80px auto 0',
            width: '40%',
            borderRadius: '5px',
            padding: '10px 0',
          },
        }}
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosIcon />}
        navButtonsProps={{
          style: {
            backgroundColor: 'transparent',
            color: 'black',
            display: productImages.length > 1 ? 'initial' : 'none',
          },
        }}
        width={{ xs: '60vw', md: '100vw' }}
        height="40vh"
        onChange={(now) => setActiveIndex(now)}
      >
        {images.map((image, i) => {
          return <CarouselItem key={i} image={image} />;
        })}
      </Carousel>
      <Box alignSelf="flex-end" mt={2} px={4}>
        {productImages.length !== 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            position="absolute"
            top={50}
            right={20}
            zIndex={100}
          >
            <Button
              sx={{ mb: 2, textTransform: 'none' }}
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              disabled={!images.length}
              onClick={() => handleDeleteImage(images[activeIndex])}
            >
              {t('buttons.delete')}
            </Button>
            <Button
              sx={{ textTransform: 'none' }}
              variant="contained"
              startIcon={<StarsIcon />}
              disabled={!images.length || activeIndex === 0}
              onClick={() => handleSetDefaultImage(images[activeIndex])}
            >
              Make Default
            </Button>
          </Box>
        ) : null}
        <Button
          component="span"
          variant="contained"
          startIcon={<FileUploadIcon />}
          disabled={productImages.length > 10}
          sx={{ textTransform: 'none', ml: 4 }}
          onClick={() => setIsUploadImageDialogOpen(true)}
        >
          {t('buttons.upload_img')}
        </Button>
      </Box>
      <UploadImageDialog
        isOpen={isUploadImageDialogOpen}
        handleClose={() => setIsUploadImageDialogOpen(false)}
        handleConfirm={handleNewImage}
        isSaving={isSaving}
      />
    </Box>
  );
};

export default ProductImageHeader;
