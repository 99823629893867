import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ReactPlayer from 'react-player';
import useWindowDimensions from '../hooks/useWindowDimension';

const BackgroundVideo = ({ videoSource, children, blur, stretch }) => {
  const [calcWidth, setCalcWidth] = useState(0);
  const { width, height } = useWindowDimensions();

  const viewportHeight = height;
  const aspectRatio = 16 / 9;

  useEffect(() => {
    if (width < viewportHeight * aspectRatio) {
      setCalcWidth(viewportHeight * aspectRatio);
    } else {
      setCalcWidth(width);
    }
  }, []);

  return (
    <Grid container>
      <ReactPlayer
        className="react-player"
        url={videoSource}
        width={stretch ? calcWidth : '100vw'}
        height={stretch ? (calcWidth / 16) * 9 : '100vh'}
        controls={false}
        muted={true}
        playing={true}
        loop={true}
        style={
          stretch
            ? {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                objectFit: 'cover',
                zIndex: -20,
                opacity: 1,
              }
            : {
                filter: `blur(${blur}px)`,
                WebkitFilter: `blur(${blur}px)`,
              }
        }
      />
      {children}
    </Grid>
  );
};

const Video = ({
  videoSource,
  controls = true,
  muted,
  playing,
  loop,
  blur,
  width,
  height,
}) => {
  if (!videoSource) {
    return;
  }

  return (
    <ReactPlayer
      className="react-player"
      url={videoSource}
      width={width ? width : '100%'}
      height={height ? height : '100%'}
      controls={controls === 'undefined' || controls === true ? true : false}
      muted={playing ? true : muted ? muted : false} // Mute when playing is true
      playing={playing ? playing : false}
      loop={loop ? loop : true}
      style={{ filter: `blur(${blur}px)`, WebkitFilter: `blur(${blur}px)` }}
    />
  );
};

export { BackgroundVideo, Video };
