import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n.js';
import { RiveAnimationComponent } from '../../../components/animations/RiveAnimationComponent.jsx';
import { ButtonHoverGradiant } from '../../../components/buttons/ButtonHoverGradiant.jsx';
import { useNavigate } from 'react-router-dom';

export const FindSalesRepsContent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.home.MfgHome.OpportunitiesContent',
  });
  const params = new URLSearchParams(window.location.search);
  const cName = params.get('cName') || '';
  const fName = params.get('fName') || '';

  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      mt={{ xs: 25, md: 50 }}
    >
      <Grid item sx={{ marginBottom: '5vh' }}>
        <Typography variant="h4" align="center">
          {t('header')}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction={{ xs: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="space-around"
      >
        {/* Column 1 */}
        <Grid
          xs={3.5}
          container
          item
          alignItems="center"
          direction="column"
          sx={{ marginTop: { xs: '10vh' }, minHeight: '28rem' }}
        >
          <Grid item>
            <Typography variant="h3" align="center">
              {t('title1')}
            </Typography>
          </Grid>
          <Grid item>
            <RiveAnimationComponent
              src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/search_icon.riv"
              stateMachines="search"
            />
          </Grid>
          <Grid item>
            <Typography align="center">
              {t('description1', { company: cName ? `${cName}'s` : 'your' })}
            </Typography>
          </Grid>
        </Grid>

        {/* Column 2 */}
        <Grid
          xs={3.5}
          container
          item
          alignItems="center"
          direction="column"
          sx={{ marginTop: { xs: '10vh' }, minHeight: '28rem' }}
        >
          <Grid item>
            <Typography variant="h3" align="center">
              {t('title2')}
            </Typography>
          </Grid>
          <Grid item>
            <RiveAnimationComponent
              src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/experience.riv"
              stateMachines="State Machine 1"
            />
          </Grid>
          <Grid item>
            <Typography align="center">
              {t('description2', { name: fName ? ` ${fName}` : '' })}
            </Typography>
          </Grid>
        </Grid>

        {/* Column 3 */}
        <Grid
          xs={3.5}
          container
          item
          alignItems="center"
          direction="column"
          sx={{ marginTop: { xs: '10vh' }, minHeight: '28rem' }}
        >
          <Grid item>
            <Typography variant="h3" align="center">
              {t('title3')}
            </Typography>
          </Grid>
          <Grid item>
            <RiveAnimationComponent
              src="https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/robot.riv"
              stateMachines="State Machine 1"
            />
          </Grid>
          <Grid item>
            <Typography align="center">{t('description3')}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item mt={20}>
        {/* Call to Action */}
        <ButtonHoverGradiant
          label={i18n.t('join_now', { ns: 'buttons' })}
          onClick={() => navigate('/account/signup')}
        />
      </Grid>
    </Grid>
  );
};
