import IntuPage from '../../components/layout/IntuPage';
import CustomerMapProvider from '../../context/providers/maps/CustomerMapProvider';
import { CompanyPublicProfileContent } from '../../context/company/profile/CompanyPublicProfileContent';
import { CompanyPublicProfileProvider } from '../../context/providers/profile/CompanyPublicProfileProvider';
import { PublicProfileProvider } from '../../context/providers/profile/PublicProfileProvider';

function CompanyPublicProfile() {
    return (
        <PublicProfileProvider>
            <CompanyPublicProfileProvider>
                <CustomerMapProvider>
                    <IntuPage>
                        <CompanyPublicProfileContent />
                    </IntuPage>
                </CustomerMapProvider>
            </CompanyPublicProfileProvider>
        </PublicProfileProvider>
    );
}

export default CompanyPublicProfile;
