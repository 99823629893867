import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Chip,
  Typography,
  IconButton,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import { useAxiosPrivateData } from '../../hooks/axios/useAxiosPrivate';
import NewAttributeDialog from './NewAttributeDialog';
import { convertFileToBase64 } from '../../helpers/convertFileToBase64';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { getFileName } from './ProductFiles';
import ResponseContext from '../../context/providers/ResponseProvider';

const isObject = (value) => {
  return Object.prototype.toString.call(value) === '[object Object]';
};

const formatKey = (key) => {
  return key
    .split('_')
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');
};

const formatDataLabel = ({ attribute, value }) => {
  return `${formatKey(attribute)} : ${value}`;
};

const findFileInFiles = (files, file) =>
  files.find((f) => getFileName(f) === file);

const UploadDocumentDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  productId,
  setFile,
  file,
  files,
  productAttributeOptions,
}) => {
  const { t: transDialog } = useTranslation('dialogs');
  const { t: transButtons } = useTranslation('buttons');
  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();
  const { setStatusMsg } = useContext(ResponseContext);

  const [previewURL, setPreviewURL] = useState(null);
  const [isExtractionLoading, setIsExtractionLoading] = useState(false);
  const [extractedData, setExtractedData] = useState(null);
  const [selectedExtractedData, setSelectedExtractedData] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [previewChips, setPreviewChips] = useState([]);

  const handleFileChange = async (e) => {
    const [file] = e.target.files;
    if (!file) return null;

    // Check if product has max 10 documents or if this file already exists on product
    if (files.length === 10 || findFileInFiles(files, file.name)) {
      setStatusMsg({
        open: true,
        status: 'error',
        message:
          files.length === 10
            ? 'You can only have 10 files'
            : 'You can not upload duplicate files',
      });
      return null;
    }

    if (file.type === 'application/pdf') {
      try {
        setPreviewURL(URL.createObjectURL(file));
        setFile(file);

        const base64File = await convertFileToBase64(file);

        const requestData = {
          file: base64File,
          file_type: 'pdf',
          file_name: file.name,
          folder: `products/${productId}/documents`,
        };

        const { data, status } = await axiosData.post(
          '/api/aws/content/add',
          JSON.stringify(requestData),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        if (status === 200) {
          setFileUrl(data.data);
        }
      } catch (error) {
        console.error('error', error);
      } finally {
        e.target.value = null;
      }
    }
  };

  const closeDialog = () => {
    setPreviewURL(null);
    setExtractedData(null);
    setSelectedExtractedData(null);
    handleClose();
  };

  const handleItemAdd = (key, value) => {
    const { [key]: itemToRemove, ...data } = extractedData;
    setExtractedData({ ...data });
    setSelectedExtractedData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleItemRemove = (key, value) => {
    const { [key]: itemToRemove, ...data } = selectedExtractedData;
    setSelectedExtractedData({ ...data });
    setExtractedData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleItemEdit = (key, value) => {
    setEditingItem({
      attributeKey: key,
      attributeValue: value,
    });
  };

  const handleEditConfirm = (newAttributeType) => {
    setSelectedExtractedData({
      ...selectedExtractedData,
      [editingItem.attributeKey]: newAttributeType,
    });
    setEditingItem(null);
  };

  const onConfirm = () => {
    setFile(null);
    setPreviewURL(null);
    setExtractedData(null);
    setSelectedExtractedData(null);

    if (selectedExtractedData) {
      const formattedExtractedData = Object.keys(selectedExtractedData).map(
        (key) => selectedExtractedData[key],
      );
      handleConfirm(fileUrl, formattedExtractedData);
    } else {
      handleConfirm(fileUrl, null);
    }
  };

  const handleExtractData = async () => {
    setIsExtractionLoading(true);
    try {
      const payload = {
        url: fileUrl,
        fileName: file.name,
        productId,
      };

      const response = await axios.post(
        '/api/chatgpt/get-data-extraction',
        payload,
        {
          responseType: 'stream',
          adapter: 'fetch',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

    if (response.status === 200) {
      const reader = response.data.getReader();
      const decoder = new TextDecoder();

      let result = ''
      let previewChip = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        
        result += chunk;
        previewChip += chunk;
        
        if (chunk.includes('}')) {
          setPreviewChips((prevState) => {
            let cleaned = previewChip.replace(/[\[\]\s]|,(?=[^,]*$)/g, '');
            cleaned = cleaned.trim();
            try {
              const newChip = JSON.parse(cleaned)
              previewChip = '';  
              return [...prevState, newChip];
            } catch (error) {
              console.warn('unable to json parse', error)
              previewChip = '';  
              return [...prevState]
            }
        });
        }
      }
      setExtractedData(JSON.parse(result))
    }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsExtractionLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      maxWidth={file ? 'md' : 'sm'}
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          minHeight:
            extractedData && Object.keys(extractedData).length
              ? '80vh'
              : 'initial',
        },
      }}
    >
      <DialogTitle>{transDialog('UploadDocumentDialog.title')}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <Box height={40}>
          {isExtractionLoading && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography mr={2}>
                {transDialog('UploadDocumentDialog.loading')}
              </Typography>
              <CircularProgress />
            </Box>
          )}
        </Box>
        <input
          hidden
          accept="application/pdf,.stp,.STEP"
          id="file-upload"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="file-upload">
          <Button
            component="span"
            variant="contained"
            startIcon={<FileUploadIcon />}
            disabled={isExtractionLoading}
            sx={{ textTransform: 'none' }}
          >
            {transButtons('upload_doc')}
          </Button>
        </label>
        <Button
          variant="contained"
          sx={{ textTransform: 'none', ml: 2 }}
          startIcon={<DocumentScannerIcon />}
          disabled={isExtractionLoading || !file}
          onClick={handleExtractData}
        >
          {transDialog('UploadDocumentDialog.button')}
        </Button>
        <Box display="flex" gap={2} mt={4}>
          {previewURL ? (
            <Box minWidth={400}>
              <embed
                src={`${previewURL}#page=1`}
                type="application/pdf"
                width="100%"
                height="500px"
              />
            </Box>
          ) : null}
          <Box
            width="50%"
            maxHeight={500}
            display="flex"
            flexDirection="column"
          >
            {
              isExtractionLoading && previewChips.length ? (
                <Box mt={2}>
                  {previewChips.map((pc) => {
                    return (
                      <Chip
                        key={pc.value + Math.random()}
                        label={`${pc.attribute} ${pc.value} ${pc.attribute_type}`}
                        variant="outlined"
                        sx={{
                          mb: 1,
                          background: (theme) => theme.palette.secondary.main,
                        }}
                        onDelete={() => {}}
                        deleteIcon={
                          <Box>
                            <IconButton
                              size="small"
                              color="warning"
                              disabled
                            >
                              <EditIcon
                                sx={{
                                  color: 'warning.main',
                                }}
                              />
                            </IconButton>
                            <IconButton
                              size="small"
                              disabled
                            >
                              <AddIcon
                                sx={{
                                  color: 'primary.main',
                                }}
                              />
                            </IconButton>
                          </Box>
                        }
                      />
                    );
                  })}
                </Box>
              ) : null
            }
            {extractedData && (
              <>
                <Typography variant="p">
                  {transDialog('UploadDocumentDialog.extracted')}
                </Typography>
                <Box mt={2}>
                  {Object.keys(extractedData).length &&
                    Object.keys(extractedData).map((dataKey) => {
                      return (
                        <Chip
                          key={dataKey}
                          label={formatDataLabel(extractedData[dataKey])}
                          variant="outlined"
                          sx={{
                            mb: 1,
                            background: (theme) => theme.palette.secondary.main,
                          }}
                          onDelete={() => {}}
                          deleteIcon={
                            <Box>
                              <IconButton
                                size="small"
                                color="warning"
                                onClick={() =>
                                  handleItemEdit(
                                    dataKey,
                                    extractedData[dataKey],
                                  )
                                }
                              >
                                <EditIcon
                                  sx={{
                                    color: 'warning.main',
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleItemAdd(dataKey, extractedData[dataKey])
                                }
                              >
                                <AddIcon
                                  sx={{
                                    color: 'primary.main',
                                  }}
                                />
                              </IconButton>
                            </Box>
                          }
                        />
                      );
                    })}
                </Box>
              </>
            )}
            {selectedExtractedData && (
              <>
                <Typography variant="p" mt={2}>
                  {transDialog('UploadDocumentDialog.chose')}
                </Typography>
                <Box mt={2}>
                  {Object.keys(selectedExtractedData).length ? (
                    <>
                      {Object.keys(selectedExtractedData).map((dataKey) => {
                        return (
                          <Chip
                            key={dataKey}
                            label={formatDataLabel(
                              selectedExtractedData[dataKey],
                            )}
                            variant="outlined"
                            sx={{
                              mb: 1,
                              '& .MuiChip-deleteIcon': {
                                color: 'error.light',
                                '&:hover': {
                                  color: 'error.dark',
                                },
                              },
                            }}
                            onDelete={() =>
                              handleItemRemove(
                                dataKey,
                                selectedExtractedData[dataKey],
                              )
                            }
                          />
                        );
                      })}
                    </>
                  ) : null}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          paddingBottom: '16px',
          justifyContent: 'space-evenly',
        }}
      >
        <Button
          onClick={closeDialog}
          color="secondary"
          variant="contained"
          disabled={isExtractionLoading}
          sx={{ textTransform: 'none' }}
        >
          {transButtons('close')}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          type="submit"
          variant="contained"
          disabled={
            isExtractionLoading ||
            !file ||
            (selectedExtractedData &&
              Object.keys(selectedExtractedData).length === 0) ||
            !fileUrl
          }
          sx={{ textTransform: 'none' }}
        >
          {transButtons('confirm')}
        </Button>
      </DialogActions>
      {!!editingItem ? (
        <NewAttributeDialog
          isOpen={!!editingItem}
          handleConfirm={handleEditConfirm}
          handleClose={() => setEditingItem(null)}
          selectedAttribute={editingItem}
          productAttributeOptions={productAttributeOptions}
        />
      ) : null}
    </Dialog>
  );
};

export default UploadDocumentDialog;
