import dayjs from 'dayjs';

const getRelativeTime = (timestamp) => {
  const now = dayjs();
  const then = dayjs(timestamp);
  const diffInSeconds = now.diff(then, 'second');
  const diffInMinutes = now.diff(then, 'minute');
  const diffInHours = now.diff(then, 'hour');
  const diffInDays = now.diff(then, 'day');

  if (diffInSeconds < 60) {
    return 'just now';
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  } else {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  }
};

export default getRelativeTime;
