import * as React from 'react';
import { useState, useEffect, useRef, useContext } from 'react';

// Import context
import useAuth from '../../../hooks/useAuth.js';

// MUI Compoents
import { Typography, Skeleton, Box, Stack, Grid, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { classes } from '../../../settings/theme.js';

// Icons
import PaidIcon from '@mui/icons-material/Paid';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RotateRightIcon from '@mui/icons-material/RotateRight';

// Response & Loading Handlers
import { useProcessingHandler } from '../../../hooks/useProcessingHandler.js';
import HeatMap from '../../../components/maps/HeatMap.jsx';

// Import Language support
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { LeadDetailsForm } from '../forms/LeadsDetailsForm.jsx';
import { MapLocationByAddressContext } from '../../../context/providers/maps/MapLocationByAddressProvider.jsx';
import { ManageLeadContext } from '../../../context/providers/leads/ManageLeadContextProvider.jsx';

import { blankLeadForm } from '../../../context/providers/leads/blankLeadForm.js';
import useIntuTheme from '../../../hooks/useIntuTheme.js';
import UserContext from '../../../context/providers/UserInfoProvider.jsx';

const SkeletonList = () => {
  return (
    <Box sx={{ width: '100%' }}>
      {/* Header Skeleton */}
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <Skeleton variant="rectangular" width="25%" height={40} />
        <Skeleton variant="rectangular" width="25%" height={40} />
        <Skeleton variant="rectangular" width="25%" height={40} />
        <Skeleton variant="rectangular" width="25%" height={40} />
      </Stack>

      {/* Row Skeletons */}
      {[...Array(20)].map((_, index) => (
        <Stack
          key={index}
          direction="row"
          spacing={1}
          sx={{ mb: 1 }}
          justifyContent="space-between"
        >
          <Skeleton variant="text" width="20%" height={40} />
          <Skeleton variant="text" width="20%" height={40} />
          <Skeleton variant="rectangular" width="10%" height={40} />
        </Stack>
      ))}
    </Box>
  );
};
const IntuLeadsList = () => {
  // State & Context
  const { auth } = useAuth();
  const { lng } = useContext(UserContext);

  const effectRun = useRef(false);

  // Response & Process Handling
  const { isLoading, setIsLoading } = useProcessingHandler();

  const {
    leads,
    getLeads,
    getLeadDetails,
    setLeadDetailsDialog,
    leadInfo,
    setLeadInfo,
  } = useContext(ManageLeadContext);

  const { mobileView } = useIntuTheme();

  const { setMapAddress } = useContext(MapLocationByAddressContext);

  // Translator
  const { t } = useTranslation();
  const { t: transTypes } = useTranslation('types');
  const { t: transButtons } = useTranslation('buttons');

  const [activityLocations, setActivityLocations] = useState([]);

  // Lead actvivity list
  const dateComparator = (v1, v2) => {
    const date1 = new Date(v1);
    const date2 = new Date(v2);

    if (date1.getFullYear() < date2.getFullYear()) {
      return -1;
    } else if (date1.getFullYear() > date2.getFullYear()) {
      return 1;
    } else {
      // Years are equal, compare months
      if (date1.getMonth() < date2.getMonth()) {
        return -1;
      } else if (date1.getMonth() > date2.getMonth()) {
        return 1;
      } else {
        // Months are equal, compare days
        if (date1.getDate() < date2.getDate()) {
          return -1;
        } else if (date1.getDate() > date2.getDate()) {
          return 1;
        } else {
          // Dates are equal
          return 0;
        }
      }
    }
  };

  const leadActivityColumns = [
    {
      field: 'first_name',
      headerName: transTypes('first_name'),
      editable: true,
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: transTypes('last_name'),
      editable: false,
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'company',
      headerName: transTypes('company'),
      editable: false,
    },
    {
      field: 'lead_type_label',
      headerName: transTypes('lead_type'),
      editable: false,
    },
    {
      field: 'status_label',
      headerName: transTypes('status'),
      flex: 0.3,
      editable: false,
    },
    {
      field: 'associate_label',
      headerName: transTypes('associate'),
      flex: 0.3,
      editable: false,
    },
    {
      field: 'last_activity',
      headerName: transTypes('last_activity'),
      flex: 0.3,
      editable: false,
      type: 'date',
      valueGetter: (params) => {
        const dates = params.row?.activity.map((e) => new Date(e.date));
        const validDates = dates.filter((date) => !isNaN(date.getTime())); // Filter out invalid dates
        const maxDate = validDates.length > 0 ? Math.max(...validDates) : NaN; // Find maximum date only if there are valid dates
        return isNaN(maxDate) ? '' : new Date(maxDate);
      },
      sortComparator: dateComparator,
    },
    {
      field: 'edit',
      headerName: transButtons('edit'),
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          let leadInfo = params.row;

          const id = leadInfo._id;

          const leadContent = await getLeadDetails(id);

          console.log('Lead Details: ', getLeadDetails);

          setMapAddress({
            street: leadContent?.address?.line1,
            state: leadContent?.address?.state,
            postcode: leadContent?.address?.postcode,
            country: leadContent?.address?.country?.code,
          });

          setTimeout(() => {
            if (leadContent.lead_type === 'investor') {
              setLeadDetailsDialog({
                open: true,
                lead_type: 'investor',
                title: t('context.admin.intutecLeads.edit_pitch'),
                text: '',
                primaryButtonText: transButtons('update', {
                  type: transTypes('pitch'),
                }),
                secondaryButtonText: transButtons('close'),
                dialogAction: 'update',
              });
            } else {
              setLeadDetailsDialog({
                open: true,
                lead_type: 'lead',
                title: t('context.admin.intutecLeads.edit_lead'),
                text: '',
                primaryButtonText: transButtons('update', {
                  type: transTypes('lead'),
                }),
                secondaryButtonText: transButtons('close'),
                dialogAction: 'update',
              });
            }
          }, 100);
        };

        return (
          <Button variant="contained" color="secondary" onClick={onClick}>
            {transButtons('edit')}
          </Button>
        );
      },
    },
  ];

  // Pre-Load Info
  useEffect(() => {
    let isMounted = true;
    setIsLoading({ status: true, type: 'skeleton', text: '' });

    // Preload form options and Values
    async function preLoadformInfo() {
      await getLeads();
    }

    // Check if useEffect has run the first time
    if (!effectRun.current) {
      preLoadformInfo();
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language]);

  async function getLocations(leads) {
    const locations = [];
    for (let i = 0; i < leads?.length; i++) {
      const activities = leads[i]?.activity;
      if (activities?.length > 0) {
        activities.map((activity) => {
          if (activity?.location?.longitude && activity?.location?.latitude) {
            locations.push({
              name:
                leads[i]?.company ||
                leads[i].first_name + ' ' + leads[i].last_name,
              content: `${activity?.action} on ${activity?.date}`,
              coordinates: {
                lat: activity.location.latitude,
                lng: activity.location.longitude,
              },
            });
          }
        });
      }
    }
    if (locations.length > 0) {
      setActivityLocations(locations);
    }

    return locations;
  }

  useEffect(() => {
    const handleMapLocation = async () => {
      await getLocations(leads);
    };

    if (leads.length > 0) {
      handleMapLocation();
    }
  }, [leads]);

  return (
    <Grid
      container
      className="content"
      sx={{ marginBottom: '8rem' }}
      spacing={5}
    >
      <Grid item xs={12}>
        <HeatMap
          initialZoom={2}
          locations={activityLocations}
          projection="mercator"
        />
      </Grid>
      ,{/* Lead List */}
      <Grid item className="content" xs={12}>
        {/* CTA */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Grid
            container
            item
            xs={4}
            justifyContent="center"
            alignItems="center"
            sx={{ paddingRight: '20px' }}
          >
            <Stack
              spacing={1}
              direction={{ xs: 'column', sm: 'row' }}
              style={classes.root}
              sx={{ paddingBottom: '50px' }}
            >
              {/* Add regular Lead Button */}
              <Button
                variant="contained"
                type="submit"
                endIcon={
                  <RocketLaunchIcon
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                  />
                }
                onClick={() => {
                  setLeadDetailsDialog({
                    open: true,
                    lead_type: 'lead',
                    title: t('context.admin.dialog.new_lead_title'),
                    text: t('context.admin.dialog.new_lead_text'),
                    primaryButtonText: transButtons('add', {
                      type: transTypes('lead'),
                    }),
                    secondaryButtonText: transButtons('close'),
                    dialogAction: 'add',
                  });

                  setLeadInfo({
                    ...blankLeadForm,
                    ...leadInfo,
                    origin: 'Associate',
                    associate: {
                      _id: auth?.user_info?._id,
                      name: auth?.user_info?.full_name,
                      email: auth?.user_info?.email,
                    },
                    status: 'created',
                  });
                }}
                sx={{ minWidth: 150, maxHeight: 40 }}
              >
                {transButtons('add', { type: transTypes('lead') })}
              </Button>

              {/* Add Investor Pitch Button */}
              <Button
                variant="contained"
                type="submit"
                endIcon={
                  <PaidIcon
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                  />
                }
                onClick={() => {
                  setLeadDetailsDialog({
                    open: true,
                    lead_type: 'investor',
                    title: t('context.admin.dialog.new_pitch_title'),
                    text: t('context.admin.dialog.new_pitch_text'),
                    primaryButtonText: transButtons('add', {
                      type: transTypes('pitch'),
                    }),
                    secondaryButtonText: transButtons('close'),
                    dialogAction: 'add',
                  });

                  setLeadInfo({
                    ...blankLeadForm,
                    ...leadInfo,
                    origin: 'Associate',
                    associate: {
                      _id: auth?.user_info?._id,
                      name: auth?.user_info?.full_name,
                      email: auth?.user_info?.email,
                    },
                  });
                }}
                sx={{ minWidth: 150, maxHeight: 40 }}
              >
                {transButtons('add', { type: transTypes('pitch') })}
              </Button>

              <Button
                variant="contained"
                type="submit"
                color="info"
                endIcon={
                  <RotateRightIcon
                    sx={{ color: (theme) => theme.palette.info.contrastText }}
                  />
                }
                onClick={() => {
                  getLeads();
                }}
                sx={{ minWidth: 150, maxHeight: 40 }}
              >
                {transButtons('refresh')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        {/* Leads List*/}

        {isLoading?.status === true && isLoading?.type === 'skeleton' ? (
          <SkeletonList />
        ) : leads?.length === 0 ? (
          <Grid item className="title content-centered" xs={12}>
            <Typography variant="h4">{t('form.message.no_leads')}</Typography>
          </Grid>
        ) : (
          <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
              isCellEditable={() => false}
              getRowId={(row) => String(row._id)}
              rows={leads}
              minWidth="100%"
              columns={leadActivityColumns}
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                company: !mobileView,
                status_label: !mobileView,
                last_activity: !mobileView,
                lead_type_label: !mobileView,
                origin_label: !mobileView,
                associate_label: !mobileView,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
                sorting: {
                  sortModel: [{ field: 'last_activity', sort: 'desc' }],
                },
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    first_name: !mobileView,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection={!mobileView}
              disableRowSelectionOnClick
            />
          </Box>
        )}

        {/* Lead Form */}
        <LeadDetailsForm />
      </Grid>
    </Grid>
  );
};

export { IntuLeadsList };
